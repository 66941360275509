<h4 mat-dialog-container>
  <mat-toolbar color="primary" role="toolbar" id="custom-toolbar">
    <span> Save Query</span>
    <span class="flex-auto"></span>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-list-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</h4>

<mat-dialog-content>
  <app-spinner [state]="spinnerState"></app-spinner>
  <div class="px-14">
    <mat-form-field class="w-full" appearance="fill">
      <mat-label>Enter Query Name</mat-label>
      <input matInput placeholder="Template Name" [(ngModel)]="template" />
    </mat-form-field>
  </div>
  <div class="grid grid-cols-3 gap-3">
    <div class="col-start-2">
      <button
        class="btn-theme-primary text-center mt-2"
        (click)="onSubmit()"
        type="submit "
      >
        Save Query
      </button>
    </div>
  </div>
</mat-dialog-content>
