<mat-stepper
  [@.disabled]="true"
  class="!bg-ghost-white"
  labelPosition="bottom"
  (selectionChange)="selectionChange($event)"
  #stepper
>
  <mat-step label="Select Data Fields" state="step1" class="!text-black">
    <app-tree-structure></app-tree-structure>
  </mat-step>

  <mat-step label="Enter Parameters" state="step2">
    <app-file-import></app-file-import>
  </mat-step>

  <mat-step label="Preview Report" state="step3">
    <app-preview-report></app-preview-report>
  </mat-step>

  <ng-template matStepperIcon="step1">
    <img
      class="step-icon"
      src="../../../assets/Group_225.svg"
      alt="firstLogo"
    />
  </ng-template>

  <ng-template matStepperIcon="step2">
    <img
      class="step-icon"
      src="../../../assets/Group_223.svg"
      alt="secondLogo"
    />
  </ng-template>

  <ng-template matStepperIcon="step3">
    <img
      class="step-icon"
      src="../../../assets/Group_224.svg"
      alt="thirdLogo"
    />
  </ng-template>
</mat-stepper>
