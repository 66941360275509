import { FixedIncomeService } from './../services/fixed-income.service';

const fixedIncomeService = new FixedIncomeService();
const uniqueFixedIncomeFields = fixedIncomeService.getUniqueFixedIncomeFields();

export const DescriptiveDataFields: any = {
  EquityFundamental: `
  ISIN
  CUSIP
  FISN
  IssuerName
  IssueDescription
  IssuerDomicile
  DTCEligibleFlag
  IssueCountry
  SIC
  CFICode
  SecurityType
  SecurityDescription
  IssueType
  LatestParValue
  ParValueCurrency
  StatusFlag
  PrimaryExchangeCode
  SEDOL
  SedolCurrency
  Defunct
  SedolRegisteredCountry
  Structure
  ExchangeCountry
  ExchangeCode
  MIC
  Micseg
  LocalCode
  ListStatus
  LotSize
  SharesOutstanding
  YearHigh
  YearHighDate
  YearLow
  YearLowDate
  MarketCapital
  LatestEffectiveDate
  DividendRate
  ExDate
  RecordDate
  DeclareDate
  PayDate
  IndicatedAnnualDividend
  DividendType
  DividendFrequency
  EarningsPerShare
  Section13fSecurity
  OptionsIndicator
  EquityCUSIPRelatedtoOption
  Action
  RunDate
  QtrandYearofRun
  UnderlyingISIN
  IssuerDescription
  FundCategory
  FundFootnote
  InstrumentName
      `,

  FixedIncomeFundamental: uniqueFixedIncomeFields,

  FuturesFundamental: `
  LocalCode
        ProductDescription
        Ticker
        Exchange
        ProductGroup
        ContractStatus
        ProductType
        FlexibleContractFlag
        UnitOfMeasureCode
        UnitOfMeasureCurrency
        UnitOfMeasureQuantity
        ContractValueFactor
        PriceQuoteUOM
        PriceQuoteCurrency
        PriceQuoteQuantity
        PriceQuoteMethod
        SettlementMethod
        MinPriceFluctuation
        SettlementDate
        FirstTradeDate
        LastTradeDate
        FirstDeliveryDate
        LastDeliveryDate
        FirstInventoryDate
        LastInventoryDate
        FirstIntentDate
        LastIntentDate
        PositionRemovalDate
        FractionalDenominator
        FractionalNumerator
        FractionalPricePrecision
        isTradable
        MarketID
        MarketSegmentID
        PriceLowerBound
        PriceUpperBound
        MinPriceFluctuationRegular
        IsNormalTradeEligible
        IsBlockTradeEligible
        IsEFPEligible
        IsEFREligible
        IsEFSEligible
        IsPNTEligible
        IsTransferEligible
        InstrumentName
  `,
  MutualFundFundamental: `
  LocalCode
        IssueCountry
        IssueType
        inceptiondate
        ISIN
        CUSIP
        SEDOL
        IssueDescription
        shortname
        shareclasstype
        IssuerName
        Currency
        companyname
        email
        phone
        secondfundbenchmarkname
        primaryfundbenchmarkname
        ProspectusObjectiveID
        ProspectusObjectiveName
        prospectusobjective
        shareclassannualreportdate
        netexpenseratio
        grossexpenseratio
        TER
        transactionfee
        managementfeepercent
        otherfee
        minimuminitialinvestment
        closedToAllInvestors
        closedToAllInvestorsDate
        closedToNewInvestorsDate
        opentoallinvestorsretail
        opentoallinvestorsinstitutional
        moneymarketinstitutionalretail
        moneymarketfundcategory
        investmentstyle
        GlobalCategoryID
        GlobalCategoryName
        AssetClassExposure
        dividendcurrency
        FundRating 
        trailingdivyield12mo
        DividendFrequency
        dividendpaydate
        RecordDate
        ExDate
        DeclareDate
        dividendamount
        totalassets
        netassets
        netassetsdate
        debttocapital
        debttoequity
        MarketCapital
        netmargin
        numberofbondholdingnet
        numberofstockholdingnet
        pricetobook
        pricetocashflow
        pricetoearnings
        pricetorevenue
        returnonassets
        returnonequity
        totalnumberholdings
        avgcoupon
        avgcreditquality
        avgduration
        avgmaturity
        avgprice
        yieldtomaturity
        IssuerDomicile
        SecurityType
        FISN
        DTCEligibleFlag
        CFICode
        IndicatedAnnualDividend
        InstrumentName
  `,
  OptionFundamental: `
      UnderlyingISIN
      UnderlyingCUSIP
      UnderlyingSEDOL
      LocalCode
      equitySymbol
      optionRoot
      deliveredEquity
      deliveredValue
      stockPercent
      effectiveDate
      componentID
      totalComponents
      expiryDate
      StrikePrice
      putCall
      openInterest
      IssuerName
      InstrumentName
    `,
  InternationalFundFundamental: `
        ISIN
        CUSIP
        SEDOL
        LocalCode
        FISN
        CFICode
        DTCEligibleFlag
        IssuerName
        IssuerDescription
        IssuerDomicile
        IssueType
        IssueDescription
        Structure
        ShareClassLifecycle
        Currency
        ListStatus
        FundDomicileAlpha2
        FundGroupName
        CompanyContactAddress
        LegalForm
        TypeOfEUDirective
        UCITSVersion
        FiscalYearEnd
        FullShareClassName
        ShareClassCurrency
        ShareClassDistributionPolicy
        FrequencyOfDistributionDeclaration
        InvestmentObjective
        Benchmark
        SRRI
        OngoingCharges
        FundCode
        ExternalGroupFundID
        Valor
        FundAdministratorName
        CustodianBankName
        UKReportingStatusValidFrom
        MinimalSubscriptionCategory
        MinimalInitialSubscriptionInShares
        MinimalInitialSubscriptionInAmount
        MinimalSubsequentSubscriptionCategory
        MinimalSubsequentSubscriptionInShares
        MinimalSubsequentSubscriptionInAmount
        FundLaunchDate
        ShareClassLaunchDate
        OngoingChargesDate
        ManagementFeeAppliedReferenceDate
        LegalFundNameIncludingUmbrella
        WKN
        ManagementFeeApplied
        PerformanceFeeApplied
        IsTrailerFeeClean
        IsPassiveFund
        HasPerformanceFee
        HasUKReportingStatus
        IsRDRCompliant
        IsEUDirectiveRelevant
        InstrumentName
    `,
  ETFFundFundamental: `
        LocalCode
        IssueCountry
        IssuerDomicile
        SecurityType
        FISN
        DTCEligibleFlag
        IssueType
        inceptiondate
        ISIN
        CUSIP
        SEDOL
        IssueDescription
        IssuerName
        Currency
        DividendRate
        ExDate
        RecordDate
        DeclareDate
        PayDate
        IndicatedAnnualDividend
        DividendType
        DividendFrequency
        secondfundbenchmarkname
        primaryfundbenchmarkname
        ProspectusObjectiveName
        prospectusobjective
        netexpenseratio
        grossexpenseratio
        TER
        investmentstyle
        GlobalCategoryName
        AssetClassExposure
        totalassets
        netassets
        netassetsdate
        debttocapital
        debttoequity
        netmargin
        pricetobook
        pricetocashflow
        pricetoearnings
        pricetorevenue
        returnonassets
        returnonequity
        totalnumberholdings
        InstrumentName
        SecurityDescription
        IssuerDescription
        StatusFlag
        PrimaryExchangeCode
        SedolCurrency
        Defunct
        SedolRegisteredCountry
        Structure
        ExchangeCountry
        ExchangeCode
        MIC
        Micseg
        ListStatus
        LotSize
        SharesOutstanding
        YearHigh
        YearLow
        YearHighDate
        YearLowDate
        Section13fSecurity
        OptionsIndicator
    `,
};
