<p class="body-text-two blue-highlight mt-4">Select Date Range</p>
<div class="grid grid-cols-2 gap-4 mt-4 mb-2">
  <div>
    <p class="ml-2 text-sm">Start</p>
    <mat-form-field appearance="outline" class="w-full">
      <input matInput placeholder="MM/DD/YYYY" [matDatepicker]="picker1" (dateChange)="changeStartDate($event)"
        (click)="picker1.open()" [formControl]="startDate" />
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
  </div>
  <div>
    <p class="ml-2 text-sm">End</p>
    <div>
      <div *ngIf="setMax; else notMaxBlock">
        <mat-form-field appearance="outline" class="w-full">
          <input matInput placeholder="MM/DD/YYYY" [matDatepicker]="picker2" (dateChange)="changeEndDate($event)"
            (click)="picker2.open()" [formControl]="endDate" [min]="startDate.value" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
      <ng-template #notMaxBlock>
        <mat-form-field appearance="outline" class="w-full">
          <input matInput placeholder="MM/DD/YYYY" [matDatepicker]="picker2" (dateChange)="changeEndDate($event)"
            (click)="picker2.open()" [formControl]="endDate" [min]="startDate.value"
            [max]="availableDays ? maxDate:null" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </ng-template>
    </div>
  </div>
</div>