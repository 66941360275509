import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QdodService } from '../../common/services/qdod.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-save-template',
  templateUrl: './save-template.component.html',
  styleUrls: ['./save-template.component.css'],
})
export class SaveTemplateComponent {
  constructor(
    public dialogRef: MatDialogRef<SaveTemplateComponent>,
    public qdodService: QdodService,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
  template: any = 'query_name';
  spinnerState = false;
  closeDialog() {
    this.dialogRef.close();
  }

  fixedEncodeURIComponent(str: string) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  onSubmit() {
    const restrictedCharacters = ['/', '"', "'"];
    const resChar = restrictedCharacters
      .filter((char) => this.template.includes(char))
      .join('   ');

    if (restrictedCharacters.some((char) => this.template.includes(char))) {
      this.toastr.error(`The filename cannot include ${resChar}`);
      return;
    }
    this.spinnerState = true;
    this.qdodService
      .SaveTemplateApi(
        this.data.dataElement.job_id,
        this.data.dataElement.request_report_key,
      )
      .subscribe((download_obj: any) => {
        if (download_obj.url) {
          this.qdodService
            .saveCustomTemplate(
              download_obj.url,
              this.fixedEncodeURIComponent(this.template),
            )
            .subscribe((saveObj: any) => {
              if (saveObj.body) {
                this.spinnerState = false;
              }
              if (saveObj.body.statusCode === 200) {
                this.toastr.success('Template saved successfully');
              } else if (saveObj.body.statusCode === 400) {
                this.toastr.error(saveObj.body.response);
              }
            });
        } else {
          this.toastr.error(`The template cannot be saved`);
        }
      });
    this.closeDialog();
  }
}
