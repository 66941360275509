import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomDataService {
  constructor() {}

  PredefinedGroups = [
    {
      name: 'Pricing - Equities',
      value: 'PricingEquities',
      key: 'pre-defined',
      content: {
        priceData: [
          'requestedIdentifier',
          'MIC',
          'LocalCode',
          'ISIN',
          'Currency',
          'PriceDate',
          'Open',
          'High',
          'Low',
          'Close',
          'Mid',
          'Ask',
          'Last',
          'Bid',
          'Bidsize',
          'Asksize',
          'TradedVolume',
          'SecurityID',
          'MktCloseDate',
          'VolFlag',
          'IssuerName',
          'SecurityType',
          'SecurityTypeDescription',
          'SEDOL',
          'CUSIP',
          'PrimaryExchange',
          'Exchange',
          'TradedValue',
          'TotalTrades',
          'CloseType',
        ],
        dividends: [],
        equityDescriptions: [],
        corporateActions: [],
        debtDesciptions: [],
      },
      template: 'Pricing%20-%20Equities',
    },
    {
      name: 'Multi-Asset Price File',
      value: 'Multi-AssetPriceFile',
      key: 'pre-defined',
      content: {
        priceData: [
          'requestedIdentifier',
          'LocalCode',
          'ISIN',
          'PriceDate',
          'Close',
          'MktCloseDate',
          'SecurityType',
          'SEDOL',
          'CUSIP',
          'PrimaryExchange',
          'Exchange',
          'CloseType',
        ],
        dividends: [],
        equityDescriptions: [],
        corporateActions: [],
        debtDesciptions: [],
      },
      template: 'Multi-Asset%20Price%20File',
    },
    {
      name: 'Auto Asset Template',
      value: 'AutoAssetTemplate',
      key: 'pre-defined',
      content: {
        priceData: [
          'requestedIdentifier',
          'PriceDate',
          'Mid',
          'Bid',
          'Ask',
          'YieldOfMaturity',
          'CloseDate',
          'Close',
          'High',
          'Low',
          'Open',
          'TradedValue',
          'TradedVolume',
          'IssuerName',
          'LocalCode',
        ],
        corporateActions: [
          'AnnouncementDate',
          'EffectiveDate',
          'RecordDate',
          'PaymentType',
          'PaymentCurrency',
        ],
        bondScheduleAndRedemption: [],
        bondRedemptions: ['bondRedemptions'],
        callSchedules: ['callSchedules'],
        paydownHistory: ['paydownHistory'],
        FixedIncome: [
          'requestedIdentifier',
          '__typename',
          'identifierType',
          'CUSIP',
          'ISIN',
          'SEDOL',
          'CFICode',
          'FISN',
          'TypeCode',
          'IssueStatus',
          'IssueCountry',
          'CouponFrequency',
          'IssuerName',
          'DepositoryType',
          'CouponRate',
          'ProductType',
          'IssueType',
          'IssueSubType',
          'IssueName',
          'IssueDate',
          'LegalMaturityDate',
          'isOriginalIssueDiscount',
          'IssueYield',
          'PriceAtIssue',
          'isTaxCall',
          'Classification',
          'ClassificationSubType',
          'InterestAccrualDate',
          'Tier',
          'CollateralType',
          'PaymentCategory',
          'PaymentCategorySubType',
          'CouponDeviationType',
          'FirstCouponDate',
          'PenultimateCouponDate',
          'Currency',
          'IncomeCalculationType_DayCount',
          'IsFixedRate',
          'BloombergUniqueId',
          'CallDate',
          'CallPrice',
          'CouponRateChangeDate',
          'SettleDays',
          'MinimumDenomination',
          'isPrivatePlacement',
          'NextCallPrice',
          'NextPayDate',
          'NextCallDate',
          'DTCEligibleFlag',
          'isTIPs',
          'AmortizationFrequency',
          'PoolFactor',
          'MoodysRating',
          'MoodysRatingEffectiveDate',
          'MoodysRatingType',
          'SNPRating',
          'SNPRatingEffectiveDate',
          'SNPRatingType',
          'FitchRating',
          'FitchRatingEffectiveDate',
          'FitchRatingType',
          'BondType',
          'isPreRefunded',
          'LastCouponDate',
          'isBankQualified',
          'StateCode',
          'TaxStatus',
          'StateTaxApplicable',
          'SecurityCode',
          'SourceOfRepayment',
          'BondMnemonic',
          'Dealer',
          'FirstPayDate',
          'StatedFinalMaturityDate',
          'PaymentDelay',
        ],
        tipsInformation: [
          'requestedIdentifier',
          'CUSIP',
          'IndexDate',
          'RefCPI',
          'IndexRatio',
        ],
        OptionFundamental: [
          '__typename',
          'requestedIdentifier',
          'expiryDate',
          'StrikePrice',
          'putCall',
          'openInterest',
        ],
        Dividend: ['Dividend'],
        Consolidation: ['Consolidation'],
        SubDivision: ['SubDivision'],
        industryClassification: [],
        gics: [
          'requestedIdentifier',
          'GICSIndustry',
          'GICSIndustryCode',
          'GICSSubIndustry',
          'GICSSubIndustryCode',
          'GICSGroup',
          'GICSGroupCode',
          'GICSSector',
          'GICSSectorCode',
        ],
        EquityFundamental: [
          '__typename',
          'requestedIdentifier',
          'CUSIP',
          'LocalCode',
          'ISIN',
          'SEDOL',
          'SecurityType',
          'ExchangeCountry',
          'IssueCountry',
          'ParValueCurrency',
          'MIC',
          'FISN',
          'CFICode',
          'IssueType',
          'IssuerDescription',
          'FundCategory',
          'FundFootnote',
          'PrimaryExchangeCode',
          'IssuerName',
          'SharesOutstanding',
          'DTCEligibleFlag',
          'SecurityDescription',
          'EarningsPerShare',
          'MarketCapital',
          'OptionsIndicator',
          'RunDate',
          'QtrandYearofRun',
        ],
        fundamentalDescription: [],
      },
      template: 'Auto%20Asset%20Template',
    },
  ];
}
