<div class="bg-white p-6 m-2">
  <div class="flex border-b mb-5">
    <div class="h-[75px] w-[75px] border-0 text-primary">
      <mat-icon
        mat-info-icon
        class="h-[100px] w-[100px]"
        style="font-size: 70px"
        >info</mat-icon
      >
    </div>
    <div class="ml-5 mb-5">
      <h1 class="mb-3 font-semibold text-2xl text-primary">Submit Report</h1>
      <p class="font-light">
        By clicking Save and Submit Report you acknowledge on behalf of your
        institution that a per security charge will be billed for the # of
        identifiers listed in the parameter setting. Please contact your manager
        or administrator if you have any questions.
      </p>
      <p class="italic text-sm font-light pt-2 pb-2">
        Note: If your institution is a Universal Data customer for the asset
        class being downloaded then no per security charges are billed.
      </p>
    </div>
  </div>

  <div mat-dialog-actions class="place-content-end">
    <button type="button" [mat-dialog-close] class="mr-4 btn-theme-secondary">
      Cancel
    </button>
    <button type="submit" [mat-dialog-close]="true" class="btn-theme-primary">
      Save and Submit Report
    </button>
  </div>
</div>
