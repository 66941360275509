import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { InstrumentLists } from '../interfaces/instrument-lists';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class InstrumentsCrudService {
  private serviceUrl = environment.instrumentListsUrl
    ? environment.instrumentListsUrl
    : null;

  constructor(private http: HttpClient, public toastr: ToastrService) {}

  addInstrumentList(instrument: any): Observable<InstrumentLists> {
    const body = {
      listName: instrument.listName,
      identifierList: instrument.identifierList,
    };
    return this.http
      .post<InstrumentLists>(`${this.serviceUrl}/addlist`, body)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  getInstrumentsList() {
    return this.http
      .get(`${this.serviceUrl}/readlists`)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  getInstrumentsListDetails(listId: string) {
    return this.http
      .get(`${this.serviceUrl}/readlist?listId=${listId}`)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  updateInstrumentList(param: any) {
    const body = {
      listId: param.listId,
      identifierList: param.identifierList,
    };
    return this.http
      .post<InstrumentLists>(`${this.serviceUrl}/updatelist`, body)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  updateInstrumentListName(param: any) {
    const body = {
      listId: param.listId,
      listName: param.listName,
    };
    return this.http
      .post<InstrumentLists>(`${this.serviceUrl}/updatelistname`, body)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  deleteInstrumentLists(InstrumentLists: any) {
    const options = {
      body: {
        listIds: InstrumentLists,
      },
    };
    return this.http
      .delete<InstrumentLists>(`${this.serviceUrl}/deletelist`, options)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  errorHandler(error: HttpErrorResponse) {
    if (error.status === 401) {
      this.toastr.error(
        'Failed to fetch response your session has been expired please Login again',
        'Error',
        { timeOut: 10000 }
      );
    } else if (error.status >= 500 && error.status < 600) {
      this.toastr.error('Please try again later', 'Internal Server Error', {
        timeOut: 10000,
      });
    } else {
      this.toastr.error('Something went wrong', 'Error', { timeOut: 10000 });
    }
    // return an observable with a user-facing error message
    return throwError(() => 'Something went wrong, please try again later.');
  }
}
