<div class="body-text-two blue-highlight">Query Type:</div>
<mat-radio-group
  [ngModelOptions]="{ standalone: true }"
  color="primary"
  [(ngModel)]="radioSelected"
  (change)="onRadioChange($event)"
>
  <mat-radio-button
    class="h-[24px]"
    style="display: block"
    *ngFor="let obj of queryTypeChoices"
    [value]="obj.value"
  >
    {{ obj.key }}
  </mat-radio-button>
</mat-radio-group>

<div *ngIf="radioSelected === 'singleDate'">
  <div class="grid grid-cols-2">
    <app-single-date
      [childField]="'singleDate'"
      (messageToParent)="parentFunction($event)"
    ></app-single-date>
  </div>
</div>

<div *ngIf="radioSelected === 'dateRange'">
  <app-date-range
    [childField]="'dateRange'"
    (messageToParent)="parentFunction($event)"
  ></app-date-range>
</div>

<div>
  <div *ngIf="radioSelected === 'corporateDividends'">
    <div *ngIf="receivedFullUniverseState; else elseBlock">
    <app-date-range [availableDays]="'week'"
    [childField]="'dateRange'"
    (messageToParent)="parentFunction($event)"
  ></app-date-range>
    </div>

    <ng-template #elseBlock>
      <app-date-range
        [setMax]="true"
        [childField]="'corporateDividends'"
        (messageToParent)="parentFunction($event)"
      ></app-date-range>
    </ng-template>
    <app-corporate-actions-dividends></app-corporate-actions-dividends>
  </div>
</div>

