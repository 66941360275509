<div class="!mt-4 px-8">
  <a routerLink="/" class="btn-theme-primary" id="create-new-button">Back</a>
  <div class="grid grid-cols-2 pb-4 !mt-4">
    <div>
      <mat-form-field appearance="outline" class="!w-full !text-sm">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" #input />
      </mat-form-field>
    </div>

    <div>
      <article class="text-right">
        <div class="action-btns">
          <button
            [disabled]="addListToggle"
            class="mr-2 btn-theme-primary"
            (click)="addRow()"
          >
            Add List
          </button>
          <button
            [disabled]="
              dataSource.data.length === 0 ||
              this.selection.selected.length === 0
            "
            class="btn-theme-danger"
            (click)="removeSelectedRows()"
          >
            Delete Lists
          </button>
        </div>
      </article>
    </div>
  </div>

  <div class="h-[80vh] overflow-y-auto">
    <app-spinner [state]="spinnerStatus"></app-spinner>
    <div class="panel panel-primary">
      <div *ngIf="dataSource.data.length === 0" class="panel-heading">
        Identifier List
      </div>
      <div class="panel-body">
        <div>
          <ng-container *ngIf="dataSource.data.length > 0">
            <table
              mat-table
              [dataSource]="dataSource"
              class="w-full"
              aria-describedby="IdentifierTable"
            >
              <ng-container
                [matColumnDef]="col.key"
                *ngFor="let col of columnsSchema"
              >
                <div [ngSwitch]="col.type">
                  <ng-container *ngSwitchCase="'isSelected'">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="!bg-light-gray !text-blue-active w-3/12 !p-3 sticky top-0 z-50"
                    >
                      <mat-checkbox
                        (change)="$event ? toggleAllRows() : null"
                        color="primary"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="
                          selection.hasValue() && !isAllSelected()
                        "
                        [aria-label]="checkboxLabel()"
                      >
                      </mat-checkbox>
                    </th>
                  </ng-container>
                  <span *ngSwitchDefault>
                    <th
                      mat-header-cell
                      class="sticky top-0 z-50 header-three !bg-light-gray !text-blue-active w-3/12 !p-3"
                      [ngClass]="{
                        '!text-right !pr-14': col.type === 'isEdit'
                      }"
                      *matHeaderCellDef
                    >
                      {{ col.label }}
                    </th>
                  </span>
                </div>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  class="w-3/12 !p-3"
                >
                  <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
                    <ng-container *ngSwitchCase="'isSelected'">
                      <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="checkboxToggle($event, element)"
                        color="primary"
                        [checked]="selection.isSelected(element)"
                        [aria-label]="checkboxLabel(element)"
                      >
                      </mat-checkbox>
                    </ng-container>
                    <div class="text-right" *ngSwitchCase="'isEdit'">
                      <button mat-button (click)="handleEdit(element)">
                        Edit
                      </button>
                      <button mat-button (click)="removeRow(element.listId)">
                        Delete
                      </button>
                    </div>

                    <span *ngSwitchDefault>
                      <a [routerLink]="['/list', element.listId]">
                        {{ element[col.key] }}
                      </a>
                    </span>
                  </div>
                  <div [ngSwitch]="col.type" *ngIf="element.isEdit">
                    <div *ngSwitchCase="'isSelected'"></div>
                    <div class="text-right" *ngSwitchCase="'isEdit'">
                      <button mat-button (click)="handleCancel(element)">
                        cancel
                      </button>
                      <button
                        mat-button
                        (click)="editRow(element, i)"
                        [disabled]="disableSubmit(element.key)"
                      >
                        Done
                      </button>
                    </div>
                    <mat-form-field
                      class="!w-full edit-field !text-sm"
                      *ngSwitchDefault
                    >
                      <input
                        #identifierRefs
                        [id]="element.listId"
                        (keyup.enter)="onEnterClick(element, i)"
                        matInput
                        [required]="col.required"
                        [pattern]="col.pattern"
                        [type]="col.type"
                        [formControl]="myControls[i]"
                        [(ngModel)]="element[col.key]"
                        (change)="inputHandler($event, element.listId, col.key)"
                      />
                      <mat-error *ngIf="myControls[i]?.invalid"
                        >List name is required</mat-error
                      >
                    </mat-form-field>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </ng-container>
          <table
            *ngIf="dataSource.data.length === 0"
            class="w-full bg-white"
            aria-describedby="identifiersTable"
            aria-hidden="true"
          >
            <tbody>
              <tr class="text-center mt-2">
                <td class="w-3/12 !p-3">NO DATA AVAILABLE</td>
              </tr>
            </tbody>
          </table>
          <mat-paginator
            [pageSizeOptions]="[10, 25, 100]"
            aria-label="Select page of Identifier List"
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
