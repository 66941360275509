import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FixedIncomeService {
  constructor() {}

  SecuritizedBondFundamental = `
    ISIN
    CUSIP
    IssuerName
    IssueCountry
    CFICode
    SEDOL
    identifierType
    FIGI
    Currency
    FISN
    DTCEligibleFlag
    UnderlyingISIN
    IssuerDomicile
    IssueName
    IssueType
    LegalMaturityDate
    IssueDate
    SettleDate
    InterestAccrualDate
    CouponType
    CouponRate
    CouponFrequency
    IncomeCalculationType_DayCount
    MoodysRating
    MoodysRatingEffectiveDate
    MoodysRatingType
    MoodysRatingOutlook
    MoodysRatingDirectionOfChange
    SNPRating
    SNPRatingEffectiveDate
    SNPRatingType
    SNPRatingOutlook
    SNPRatingDirectionOfChange
    FitchRating
    FitchRatingEffectiveDate
    FitchRatingType
    FitchRatingOutlook
    FitchRatingDirectionOfChange
    Description
    CollateralType
    StatedFinalMaturityDate
    Factor
    FirstPayDate
    PaymentDate
    NextPayDate
    NextResetDate
    PaymentDelay
    FloaterFormula
    FloaterCap
    FloaterFloor
    CurrentBalance
    CurrentNotionalBalance
    CurrentPariPassuBalance
    CurrentSeniorBalance
    HasNotionalBalance
    OriginalNotionalBalance
    OriginalPariPassuBalance
    OriginalSeniorBalance
    Calendar
    Dealer
    Trustee
    CurrentCollateral
    OriginalCollateral
    OriginalBalance
    OriginalPrincipalAmount
    NetCoupon
    WAC
    WAM
    NumberOfLoans
    Tranche
    WALA
    AccrualEndDate
    AccrualStartDate
    Category
    PoolFactor
    PriceDate
    ProductType
    TypeCode
    BondType
    BondMnemonic
    PreviousFactor
    PreviousPaymentDate
    CINS
    CountryOfRisk
    StateCode
    InstrumentName
        `;

  MunicipalBondFundamental = `
          CouponSpread
          ISIN
          CUSIP
          IssuerName
          IssueCountry
          CFICode
          SEDOL
          identifierType
          FIGI
          BloombergUniqueId
          FISN
          DepositoryType
          UnderlyingISIN
          IssuerDomicile
          IssueName
          InstrumentId
          AmountOutstanding
          MinimumDenomination
          DenominationIncrease
          PaymentCategory
          Callable
          Puttable
          HasSink
          Jurisdiction
          UseofProceeds
          IsFixedRate
          LegalMaturityDate
          IssueDate
          SettleDate
          InterestAccrualDate
          CouponType
          CouponRate
          CouponFrequency
          IncomeCalculationType_DayCount
          MoodysRating
          MoodysRatingEffectiveDate
          MoodysRatingType
          SNPRating
          SNPRatingEffectiveDate
          SNPRatingType
          FitchRating
          FitchRatingEffectiveDate
          FitchRatingType
          IssueType
          IssueStatus
          IssueSubType
          isBankQualified
          isAlternativeMinimumTax
          Classification
          ClassificationSubType
          SecurityCode
          SectorLevel1
          SectorLevel2
          SectorLevel3
          SectorLevel4
          SectorLevel5
          Currency
          IssueYield
          PriceAtIssue
          SettleDays
          PaymentCategorySubType
          ReferenceRate
          Multiplier
          SourceOfRepayment
          FirstCouponDate
          PenultimateCouponDate
          LastCouponDate
          TaxStatus
          StateTaxApplicable
          StateCode
          isPrivatePlacement
          isPreRefunded
          isOriginalIssueDiscount
          DTCEligibleFlag
          CallDate
          CallPrice
          OriginalBalance
          PriceDate
          ProductType
          TypeCode
          BondType
          NextPayDate
          NextCallDate
          NextCallPrice
          OutstandingFactor
          PayingAgent
          PreRefundedAmount
          PreRefundedChangeType
          PreRefundedEffectiveDate
          PreRefundedOutstandingFactor
          PreRefundedPrice
          PreRefundedType
          PreRefundedRelationEffectiveDate
          PreRefundedInstrumentRelationType
          PreRefundedIdentifier
          PreRefundedIdentifierType
          CouponRateChangeDate
          CountryOfRisk
          CouponDeterminationDate
          NextResetDate
          CouponResetDate
          LastDayOfMonthPaymentIndicator
          OrganizationCountryCode
          BondStatus
          OriginalPrincipalAmount
          AccrualStartDate
          DefaultIndicator
          InstrumentName
        `;
  CorporateBondFundamental = `
          ISIN
          CUSIP
          IssuerName
          IssueCountry
          CFICode
          SEDOL
          identifierType
          FIGI
          BloombergUniqueId
          FISN
          DepositoryType
          UnderlyingISIN
          IssuerDomicile
          IssueName
          InstrumentId
          IsFactorable
          AmountOutstanding
          MinimumDenomination
          DenominationIncrease
          MarketRegion
          PaymentCategory
          Callable
          Puttable
          HasSink
          CouponSpread
          ConvertibleFlag
          IsFixedRate
          isTaxCall
          isPerpetual
          LegalMaturityDate
          IssueDate
          SettleDate
          InterestAccrualDate
          CouponType
          CouponRate
          CouponFrequency
          IncomeCalculationType_DayCount
          MoodysRating
          MoodysRatingEffectiveDate
          MoodysRatingType
          SNPRating
          SNPRatingEffectiveDate
          SNPRatingType
          FitchRating
          FitchRatingEffectiveDate
          FitchRatingType
          IssueType
          IssueStatus
          IssueSubType
          Classification
          ClassificationSubType
          CollateralType
          SectorLevel1
          SectorLevel2
          SectorLevel3
          SectorLevel4
          SectorLevel5
          Tier
          Currency
          IssueYield
          PriceAtIssue
          SettleDays
          PayCalendarValue
          PaymentCategorySubType
          ReferenceRate
          Multiplier
          CouponDeviationType
          FirstCouponDate
          PenultimateCouponDate
          isTIPs
          isPrivatePlacement
          isOriginalIssueDiscount
          DTCEligibleFlag
          CallDate
          CallPrice
          AmortizationFrequency
          OriginalBalance
          PoolFactor
          PriceDate
          ProductType
          TypeCode
          Is144a
          NextCallDate
          NextCallPrice
          NextPayDate
          OutstandingFactor
          PayingAgent
          CouponRateChangeDate
          CINS
          CountryOfRisk
          IssuerLei
          IssuerTicker
          NextResetDate
          CouponResetDate
          DefaultIndicator
          LastDayOfMonthPaymentIndicator
          OrganizationCountryCode
          CommonCode
          BondStatus
          OriginalPrincipalAmount
          AccrualStartDate
          InstrumentName
        `;

  getUniqueFixedIncomeFields() {
    const combinedString =
      this.SecuritizedBondFundamental +
      this.MunicipalBondFundamental +
      this.CorporateBondFundamental;

    // Split the combined string into an array of values
    const valuesArray = combinedString
      .split('\n')
      .map((value) => value.trim())
      .filter((value) => value !== '');

    // Remove duplicates by creating an empty object to track unique values
    const uniqueValuesObject: any = {};

    // Iterate over the valuesArray and add each value as a property to the uniqueValuesObject
    valuesArray.forEach((value) => {
      uniqueValuesObject[value] = true;
    });

    // Create the final string by joining the keys (unique values) of the uniqueValuesObject
    const finalString = Object.keys(uniqueValuesObject).join('\n');

    // Create the updated string by moving the expected keys of the finalString
    const updatedString = this.moveElementAfter(
      'CouponSpread',
      'CouponFrequency',
      finalString,
    );

    return updatedString;
  }

  moveElementAfter(
    elementToMove: string,
    targetElement: string,
    inputString: string,
  ) {
    const elements = inputString.split('\n');
    const elementIndex = elements.indexOf(elementToMove);
    const targetIndex = elements.indexOf(targetElement);

    if (elementIndex === -1 || targetIndex === -1) {
      return inputString;
    }

    elements.splice(elementIndex, 1);
    elements.splice(targetIndex + 1, 0, elementToMove);

    return elements.join('\n');
  }
}
