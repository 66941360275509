import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class QdodService {
  constructor(
    private http: HttpClient,
    public toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
  ) {}

  Localtoken = localStorage.getItem('token');
  token = '';

  private reportStateButton = new BehaviorSubject([]);
  currentReportState = this.reportStateButton.asObservable();

  private instrumentListStatus = new BehaviorSubject(false);
  currentInstrumentListStatus = this.instrumentListStatus.asObservable();

  private fields_selected = new BehaviorSubject({ flatMap: [], nestedMap: [] });
  currentFields_selected = this.fields_selected.asObservable();

  private fileSelected = new BehaviorSubject([]);
  currentfileSelected = this.fileSelected.asObservable();

  private identifiersLength = new BehaviorSubject([]);
  currentIdentifiersLength = this.identifiersLength.asObservable();

  private startDateSelected = new BehaviorSubject(DateTime.now());
  currentStartDateSelected = this.startDateSelected.asObservable();

  private endDateSelected = new BehaviorSubject(DateTime.now());
  currentEndDateSelected = this.endDateSelected.asObservable();

  private totalGroups = new BehaviorSubject([]);
  currentTotalGroups = this.totalGroups.asObservable();

  private identifiersList = new BehaviorSubject([]);
  currentIdentifiersList = this.identifiersList.asObservable();

  private tokenCode = new BehaviorSubject('');
  currentTokenCode = this.tokenCode.asObservable();

  private fullUniverseFlag = new BehaviorSubject(false);
  currentFullUniverseFlag = this.fullUniverseFlag.asObservable();

  private radioSelected = new BehaviorSubject('singleDate');
  currentRadioSelected = this.radioSelected.asObservable();

  private radioCorporateDividendSelected = new BehaviorSubject(
    'announcementDate',
  );
  currentRadioCorporateDividendSelected =
    this.radioCorporateDividendSelected.asObservable();

  private filteredGQL_Data = new BehaviorSubject([]);
  currentFilteredGQL_Data = this.filteredGQL_Data.asObservable();

  changeFullUniverseFlag(flag: any) {
    this.fullUniverseFlag.next(flag);
  }

  changeFilteredGQL_Data(data: any) {
    this.filteredGQL_Data.next(data);
  }

  changeRadioCorporateDividendSelected(status: any) {
    this.radioCorporateDividendSelected.next(status);
  }

  changeRadioSelected(select: any) {
    this.radioSelected.next(select);
  }

  changeTokenValue(newVal: any) {
    this.tokenCode.next(newVal);
  }

  changeTotalGroups(group: any) {
    this.totalGroups.next(group);
  }

  changeIdentifiersList(group: any) {
    this.identifiersList.next(group);
  }

  changeStartDateSelected(dateStart: any) {
    this.startDateSelected.next(dateStart);
  }

  changeEndDateSelected(date: any) {
    this.endDateSelected.next(date);
  }

  changeFileSelected(fileName: any) {
    this.fileSelected.next(fileName);
  }

  changeIdentifierLength(length: any) {
    this.identifiersLength.next(length);
  }

  changeReportState(state: any) {
    this.reportStateButton.next(state);
  }

  changeInstrumentListState(state: any) {
    this.instrumentListStatus.next(state);
  }

  changeFields_selected(list: any) {
    this.fields_selected.next(list);
  }

  formatDate = (dateVal: DateTime) => {
    return dateVal.toFormat('yyyy-MM-dd');
  };

  ResultListApi() {
    return this.http
      .get(environment.jobsApi)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  NextJobsList(lastId: any) {
    return this.http
      .get(`${environment.jobsApi}?lastJobId=${lastId}`)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  listJobsApi() {
    return this.http
      .get(environment.listJobsApi)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  loadMoreListJobsApi(
    pageNumber: number,
    pageSize: number,
    resultOrder: number,
  ) {
    return this.http
      .get(
        `${environment.listJobsApi}?pageNumber=${pageNumber}&pageSize=${pageSize}&resultOptions=${resultOrder}`,
      )
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  TemplateNamesApi() {
    return this.http
      .get(environment.templateNamesApi)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  fixedEncodeURIComponent(str: string) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  CustomTemplateContent(templateID: any) {
    return this.http
      .get(
        `${environment.loadCustomTemplate}${this.fixedEncodeURIComponent(
          templateID,
        )}`,
      )
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  downloadFileApi(job_id: any, format: any) {
    //format can be csv_piped, csv or json
    const fileSuffix = format === 'csv_piped' ? '_piped.csv' : `.${format}`;
    const body = {
      job_id: job_id,
      file_name: `response${fileSuffix}`,
    };
    return this.http
      .post<any>(environment.downloadApi, body)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  deleteReportApi(job_id: string) {
    const options = {
      body: {
        job_ids: [job_id],
      },
    };

    return this.http
      .delete<any>(environment.deleteReportApi, options)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  SaveTemplateApi(job_id: any, filename: any) {
    const body = {
      job_id: job_id,
      file_name: filename,
    };
    return this.http
      .post<any>(environment.downloadApi, body)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  saveCustomTemplate(url: any, template: any) {
    const body = {
      url: url,
      template: template,
    };

    return this.http
      .post<any>(environment.saveCustomTemplate, body)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  exceptionsFileApi(job_id: any, exceptions: string) {
    const body = {
      job_id: job_id,
      file_name: exceptions,
    };
    return this.http
      .post<any>(environment.exceptionsApi, body)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  getGqlFieldsDesc(query: string) {
    const body = {
      operationName: 'IntrospectionQuery',
      query,
      variables: {},
    };
    return this.http
      .post<any>(environment.fieldsDescApi, body)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  gqlQuery(
    query: string,
    variables: object,
    fileName: string,
    fieldOrder: any,
  ) {
    const body = JSON.stringify({
      query,
      variables,
      fieldOrder,
      fetchPolicy: 'no-cache',
    });

    return this.http
      .post<any>(
        `${environment.submitQueryJob}?jobname=${this.fixedEncodeURIComponent(
          fileName,
        )}`,
        body,
      )
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  errorHandler(err: HttpErrorResponse) {
    if (err.status === 401) {
      this.toastr.error(
        'Failed to fetch response your session has been expired please Login again',
        'Error',
        { timeOut: 10000 },
      );
      localStorage.removeItem('token'); // clear the token so that a refresh re-logs in.
    } else if (err.status >= 500 && err.status < 600) {
      this.toastr.error('Please try again later', 'Internal Server Error', {
        timeOut: 10000,
      });
    } else {
      this.toastr.error('Something went wrong', 'Error', { timeOut: 10000 });
    }
    // return an observable with a user-facing error message
    return throwError(() => 'Something went wrong, please try again later.');
  }

  removeChildRoute() {
    const basePath = '/';

    // Remove the child routes from the URL for new report creation.
    this.router.navigate([basePath]);
  }

  removeQuerystringParam(queryStringParam: string) {
    const queryParams = { ...this.route.snapshot.queryParams };
    delete queryParams[queryStringParam];
    this.router.navigate([], {
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }
}
