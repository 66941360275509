export const bondVariableFloatRates = `
    CUSIP
    ISIN
    StartDate
    CouponFrequency
    CouponRate
    RateType
    PaymentDate
    EndDate
    ResetDays
    CouponResetDate
    CouponRateChangeDate
    `;

export const exchangeRate = `
    RateDate
    Rate
    QuoteCurrency
    BaseCurrency
`;
