export const fundAllocations: any = {
  assetAllocation: `
     AssetName
     AssetPercentage
    `,
  countryAllocation: `
     AssetName
     AssetPercentage
    `,
  marketCapitalAllocation: `
     AssetName
     AssetPercentage
    `,
  maturityAllocation: `
     AssetName
     AssetPercentage
    `,
  ratingAllocation: `
    AssetName
    AssetPercentage
    `,
  regionalAllocation: `
    AssetName
    AssetPercentage
    `,
  sectorAllocation: `
    AssetName
    AssetPercentage
    `,
};

export const fundPerformance = `
        AnnualReturnNAV
        AnnualReturnNAVCategorySize
        AnnualReturnNAVYearToDate
        Period
        SecurityCode
        SourceCode
`;
