import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-report-dialog',
  template: `
    <h4 mat-dialog-container>
      <mat-toolbar color="primary" role="toolbar" id="custom-toolbar">
        <span>Delete Report</span>
        <span class="flex-auto"></span>
        <button mat-icon-button (click)="closeDialog()">
          <mat-icon mat-list-icon>close</mat-icon>
        </button>
      </mat-toolbar>
    </h4>
    <mat-dialog-content class="pl-0 pr-0  w-full text-black">
      <div class="px-4">
        <div class="font-medium flex justify-center text-lg">
          Are you sure you want to delete the Report ?
        </div>
        <div class="grid grid-cols-3 gap-3 mt-2">
          <div class="col-start-2">
            <button
              class="btn-theme-danger text-center mt-1"
              [mat-dialog-close]="true"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  `,
  styles: [
    `
      ::ng-deep .mat-mdc-dialog-container {
        padding: 0 0 1em 0;
      }
    `,
  ],
})
export class DeleteReportDialogComponent {
  constructor(public dialogRef: MatDialogRef<DeleteReportDialogComponent>) {}

  closeDialog() {
    this.dialogRef.close();
  }
}
