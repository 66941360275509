<mat-radio-group
  [(ngModel)]="radioSecondSelected"
  (change)="onRadioChange($event)"
  [ngModelOptions]="{ standalone: true }"
  [disabled]="isRadioDisabled"
  color="primary"
>
  <mat-radio-button
    *ngFor="let obj of corporateDividendRadio"
    [value]="obj.value"
  >
    <span>
      {{ obj.key }}
    </span>
  </mat-radio-button>
</mat-radio-group>
