export interface InstrumentLists {
  isSelected: boolean;
  listId: string;
  listName: string;
  isEdit: boolean;
}

export const InstrumentListColumn = [
  {
    key: 'isSelected',
    type: 'isSelected',
    label: '',
  },
  {
    key: 'listName',
    type: 'text',
    label: 'Identifier List',
    required: true,
  },
  {
    key: 'isEdit',
    type: 'isEdit',
    label: 'Actions',
  },
];

export const InstrumentListDemoData = [
  { id: 1, InstrumentListName: 'New instrument 1' },
  { id: 2, InstrumentListName: 'New instrument 2' },
  { id: 3, InstrumentListName: 'New instrument 3' },
  { id: 4, InstrumentListName: 'New instrument 4' },
  { id: 5, InstrumentListName: 'New instrument 5' },
  { id: 6, InstrumentListName: 'New instrument 6' },
  { id: 7, InstrumentListName: 'New instrument 7' },
  { id: 8, InstrumentListName: 'New instrument 8' },
  { id: 9, InstrumentListName: 'New instrument 9' },
  { id: 10, InstrumentListName: 'New instrument 10' },
  { id: 11, InstrumentListName: 'New instrument 11' },
];

export interface Identifiers {
  identifier: string;
  isEdit: boolean;
  isSelected: boolean;
}

export const IdentifiersColumn = [
  {
    key: 'isSelected',
    type: 'isSelected',
    label: '',
  },
  {
    key: 'identifier',
    type: 'text',
    label: 'Identifiers',
    required: true,
  },
  {
    key: 'isEdit',
    type: 'isEdit',
    label: 'Actions',
  },
];
