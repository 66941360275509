export const mutualFundAllocations: any = {
  assetAllocation: `
    AssetName
    AssetPercentage
    `,
  countryAllocation: `
    AssetName
    AssetPercentage
    `,
  marketCapitalAllocation: `
    AssetName
    AssetPercentage
    `,
  maturityAllocation: `
    AssetName
    AssetPercentage
    `,
  ratingAllocation: `
    AssetName
    AssetPercentage
    `,
  regionalAllocation: `
    AssetName
    AssetPercentage
    `,
  sectorAllocation: `
    AssetName
    AssetPercentage
    `,
};

export const mutualFundManagerInfo = `
        StartDate
        ManagerName
        ManagerBiography
`;

export const mutualFundPerformance = `
        AnnualReturnNAV
        AnnualReturnNAVCategorySize
        AnnualReturnNAVYearToDate
        Period
        SecurityCode
        SourceCode
`;
