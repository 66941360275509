import {
  OnInit,
  Component,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  HostListener,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  InstrumentLists,
  InstrumentListColumn,
} from '../../common/interfaces/instrument-lists';
import { InstrumentsCrudService } from '../../common/services/instruments-crud.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-view-instrument-list',
  templateUrl: './view-instrument-list.component.html',
  styleUrls: ['./view-instrument-list.component.css'],
})
export class ViewInstrumentListComponent implements OnInit {
  [x: string]: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = InstrumentListColumn.map((col) => col.key);
  columnsSchema: any = InstrumentListColumn;
  dataSource = new MatTableDataSource<InstrumentLists>();
  paginatorArr: any[];
  @ViewChild(MatSort) sort: MatSort;
  selection = new SelectionModel<InstrumentLists>(true, []);
  valid: any = {};
  addListToggle = false;
  list: any[];
  showInputField = false;
  selectedItemId: string;
  myControls: FormControl[] = [];
  spinnerStatus = false;
  intervalId: any;
  count = 0;
  isListData = false;

  @ViewChildren('identifierRefs') identifierRefs: QueryList<ElementRef>;
  component: any;
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.triggerEditToggle();
  }
  constructor(
    public dialog: MatDialog,
    private instrumentService: InstrumentsCrudService
  ) {}

  ngOnInit(): void {
    // fetch instrument lists
    this.fetchInstrumentList();
  }

  private getIdentifierListFormControl(): FormControl {
    return new FormControl('', [Validators.required]);
  }

  updateCount() {
    this.count += 100;
    if (this.count === 500 && !this.isListData) {
      this.spinnerStatus = true;
    }
  }

  checkApiTime() {
    this.intervalId = setInterval(() => this.updateCount(), 100);
    setTimeout(() => {
      clearInterval(this.intervalId);
    }, 500);
  }

  fetchInstrumentList() {
    this.checkApiTime();
    this.instrumentService.getInstrumentsList().subscribe({
      next: (res: any) => {
        const listData = res.listofLists;
        this.isListData = true;
        this.createFormControl(listData);
        this.paginatorArr = [10, 50, 500];
        this.dataSource.data = listData;
        this.dataSource.paginator = this.paginator;
        this.selection.deselect(...this.selection.selected);
        this.resetLoader();
      },
      error: (error: any) => {
        this.spinnerStatus = false;
      },
    });
  }

  resetLoader() {
    this.count = 0;
    this.spinnerStatus = false;
    this.isListData = false;
  }

  createFormControl(data: any) {
    this.myControls = [];
    data.forEach((element: any) => {
      const formControl = this.getIdentifierListFormControl();
      formControl.patchValue(element.listName);
      this.myControls.push(formControl);
    });
  }

  ngAfterViewChecked(): void {
    this.identifierRefs.changes.subscribe(() => {
      const htmlElements: any = document.getElementById(this.selectedItemId);
      if (htmlElements) {
        htmlElements.select();
      }
    });
  }

  triggerEditToggle() {
    this.dataSource.data.forEach((el) => {
      if (el.listId === '0') {
        this.dataSource.data = this.dataSource.data.filter(
          (u: InstrumentLists) => u.listId !== '0'
        );
      } else {
        el.isEdit = false;
      }
    });
    this.addListToggle = false;

    this.fetchInstrumentList();
  }

  checkboxToggle(event: any, element: any) {
    event ? this.selection.toggle(element) : null;
    element.isSelected = event.checked;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.listItem
    }`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  handleCancel(el: any) {
    if (el.listId === '0') {
      this.dataSource.data = this.dataSource.data.filter(
        (u: InstrumentLists) => u.listId !== '0'
      );
    } else {
      el.isEdit = !el.isEdit;
    }
    this.fetchInstrumentList();
    this.addListToggle = false;
    this.showInputField = false;
  }

  handleEdit(el: any) {
    this.selectedItemId = el.listId;
    el.isEdit = !el.isEdit;
  }

  editRow(row: any, i: any) {
    if (this.myControls[i].hasError('required')) {
      this.myControls[i].markAsTouched();
    } else {
      const filteredLists = [];
      filteredLists.push(row.listName);
      const body = {
        listName: row.listName,
        identifierList: [],
        listId: row.listId,
      };

      // edit row
      if (row.listId === '0') {
        this.instrumentService.addInstrumentList(body).subscribe((res) => {
          row.listId = res.listId;
          row.isEdit = false;
        });
      } else {
        this.instrumentService
          .updateInstrumentListName(body)
          .subscribe((res) => {
            row.isEdit = false;
          });
      }
    }
    this.addListToggle = false;
  }

  onEnterClick(el: any, i: any) {
    this.editRow(el, i);
  }

  addRow() {
    this.addListToggle = true;

    const newRow: InstrumentLists = {
      listId: '0',
      listName: '',
      isEdit: true,
      isSelected: false,
    };
    const formControl = this.getIdentifierListFormControl();
    formControl.patchValue(newRow.listName);
    this.myControls.unshift(formControl);
    this.showInputField = true;
    this.selectedItemId = newRow.listId;
    this.dataSource.data = [newRow, ...this.dataSource.data];
  }

  removeRow(id: string) {
    const filteredLists: any = [];

    filteredLists.push(id);
    this.dialog
      .open(ConfirmDialogComponent)
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.instrumentService
            .deleteInstrumentLists(filteredLists)
            .subscribe(() => {
              this.fetchInstrumentList();
            });
        }
      });
  }

  removeSelectedRows() {
    const instrumentLists = this.dataSource.data.filter((u: InstrumentLists) =>
      this.selection.isSelected(u)
    );

    this.dialog
      .open(ConfirmDialogComponent)
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          const filteredLists = instrumentLists.map((list) => list.listId);

          this.instrumentService
            .deleteInstrumentLists(filteredLists)
            .subscribe(() => {
              this.fetchInstrumentList();
            });
        }
      });
  }

  inputHandler(e: any, id: number, key: string) {
    if (!this.valid[id]) {
      this.valid[id] = {};
    }
    this.valid[id][key] = e.target.validity.valid;
  }

  disableSubmit(id: number) {
    if (this.valid[id]) {
      return Object.values(this.valid[id]).some((item) => item === false);
    }
    return false;
  }
}
