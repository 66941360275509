import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ViewInstrumentListComponent } from './components/view-instrument-list/view-instrument-list.component';
import { ViewInstrumentsComponent } from './components/view-instruments/view-instruments.component';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  { path: '', component: DashboardComponent },
  {
    path: 'step/:stepNum',
    component: DashboardComponent,
  },
  { path: '**', redirectTo: '', component: DashboardComponent },
];
const InstrumentRoutes: Routes = [
  { path: '', component: DashboardComponent },
  {
    path: 'step/:stepNum',
    component: DashboardComponent,
  },
  { path: 'list', component: ViewInstrumentListComponent },
  { path: 'list/:listId', component: ViewInstrumentsComponent },
];
const isInstrument = environment.enableInstrumentsFlow;
@NgModule({
  imports: [RouterModule.forRoot(isInstrument ? InstrumentRoutes : routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
