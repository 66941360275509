import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */
  init(userToken: string): void {
    mixpanel.init(environment.mixpanelToken, { debug: true });
    mixpanel.identify(userToken);
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    mixpanel.init(environment.mixpanelToken, { debug: true });
    mixpanel.track(id, action);
  }

  changeStepperScreen(screenNo: number, screenName: string) {
    this.track('stepper-change-event', {
      screen_no: screenNo,
      screen_name: screenName,
    });
  }

  trackDatePicked(datePicked: any, type: string) {
    this.track('date-change-event', {
      selected_date: datePicked,
      type: type,
    });
  }

  onButtonClick(name: string) {
    this.track('button-click-event', {
      property_name: name,
    });
  }

  onListSelection(name: string, value: string) {
    this.track('selection-list-event', {
      property_name: name,
      property_value: value,
    });
  }

  onDownloadEvent(type: string) {
    this.track('download-event', {
      property_name: type,
    });
  }

  onInputEvent(name: string, value: any) {
    this.track('input-event', {
      property_name: name,
      property_value: value,
    });
  }
}
