<div class="header-one text-blue-active mt-4">Report Parameters</div>

<div class="grid grid-cols-2 gap-3">
  <div>
    <app-query-type
      (isCorporateDividend)="changeCorporateDividend($event)"
    ></app-query-type>
  </div>

  <div class="rounded-t-xl">
    <mat-card appearance="outlined" class="shadow-none !p-0 !rounded-[0.6rem]">
      <div class="bg-light-gray grid grid-cols-4 gap-4 p-2 rounded-t-lg">
        <div>
          <button
            type="button"
            class="btn-theme-primary float-left"
            (click)="openDialog()"
            *ngIf="isIdentifierFlow"
            [ngClass]="{
              'opacity-20 pointer-events-none':
                (isFullUniverse === true && ifCorporateDividend) ||
                !fileUploaded
            }"
          >
            Save
          </button>
        </div>

        <div class="text-blue-active col-span-2 text-center leading-10">
          {{ fileName ? fileName : 'List of Identifiers' }}
        </div>

        <div>
          <button
            type="button"
            class="btn-theme-danger float-right"
            (click)="clearIdentifiers()"
            [ngClass]="{
              'opacity-20 pointer-events-none':
                (isFullUniverse === true && ifCorporateDividend) ||
                !fileUploaded
            }"
          >
            Clear
          </button>
        </div>
      </div>

      <mat-card-content>
        <div
          class="bg-gray-form-field rounded-bl-lg rounded-br-lg !border-t-0 px-4 pb-4 min-h-card-height border clear-both"
        >
          <div class="container">
            <div *ngIf="!fileUploaded" class="body-text-three font-gray">
              Drop your xlsx or csv. View
              <a
                href="../../../assets/file_format/identifier_template .xlsx"
                class="color-theme"
                download="sample_file.xlsx"
                (click)="downloadSampleFile('sample_file.xlsx')"
                >sample_file.xlsx</a
              >
              <span
                ><a
                  href="../../../assets/file_format/identifier_template .csv"
                  class="color-theme px-2"
                  download="sample_file.csv"
                  (click)="downloadSampleFile('sample_file.csv')"
                  >sample_file.csv</a
                ></span
              >
            </div>

            <mat-checkbox
              class="checkbox"
              (change)="checkCheckBoxvalue($event)"
              color="primary"
              [ngStyle]="{
                visibility: !ifCorporateDividend ? 'hidden' : 'visible'
              }"
              >Full Universe</mat-checkbox
            >
          </div>

          <div
            class="bg-blue-custom rounded-lg"
            [ngClass]="{
              'full-universe': isFullUniverse === true && ifCorporateDividend
            }"
            [ngStyle]="{ padding: fileUploaded ? 'unset' : '1rem' }"
          >
            <div
              *ngIf="!fileUploaded"
              class="text-white text-center min-h-import-file-card border border-dashed border-ford-gray"
              [ngClass]="{
                'full-universe': isFullUniverse === true && ifCorporateDividend
              }"
              appDragDropFileUpload
              (fileDropped)="onFileDropped($event)"
            >
              <ng-container *ngIf="isIdentifierFlow">
                <div class="mb-7">
                  <mat-form-field
                    class="w-2/4 bg-gray98 my-4 mx-4 rounded-lg"
                    appearance="fill"
                  >
                    <mat-label>Choose a list</mat-label>
                    <mat-select
                      [(ngModel)]="selectedIdentifer"
                      (selectionChange)="loadIdentifierList($event.value)"
                      name="listOfIdentifiersDropdown"
                    >
                      <mat-option
                        *ngFor="let identifierList of listOfIdentifiersDropdown"
                        [value]="identifierList"
                      >
                        {{ identifierList.listName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </ng-container>
              <div
                [ngStyle]="{ 'margin-top': isIdentifierFlow ? '0' : '3em' }"
                class="text-center"
              >
                <input
                  #fileDropRef
                  id="fileDropRef"
                  multiple
                  #inputFile
                  type="file"
                  name="file-input"
                  id="file-input"
                  class="file-input__input"
                  (change)="onFileDropped($event)"
                />
                <label class="file-input__label" for="file-input">
                  <img
                    id="import-button"
                    src="../../../assets/download 01.svg"
                    alt="import_logo"
                  />
                  <span>Import File</span></label
                >
              </div>

              <div class="pt-2">
                <div class="text-center body-text-three text-white mt-4">
                  You can drag &amp; drop your files here of file Type: .XLSX or
                  .CSV
                </div>
              </div>
            </div>

            <div
              *ngIf="fileUploaded"
              [ngClass]="{
                'disable-clear': isFullUniverse === true && ifCorporateDividend
              }"
            >

            <div class="grid grid-cols-12 bg-gray-form-field font-semibold gap-12">
              <div class="col-span-4">identifier</div>
              <div class="col-span-4"></div>
              <div *ngIf="customFaceValues.length >0" class="col-span-4">customFaceValue</div>
            </div>

            <cdk-virtual-scroll-viewport
            appendOnly
            itemSize="10"
            class="min-h-file-upload-height bg-gray-form-field overflow-x-hidden"
          >
            <div
              *cdkVirtualFor="let item of finalIdentifierList"
              class="h-5 text-[14px]"
            >
              <div class="grid grid-cols-12 gap-12">
                <div class="col-span-4">{{ item.securityId }}</div>
                <div class="col-span-4"></div>
                <div *ngIf="customFaceValues.length > 0" class="col-span-4">{{ item.customfacevalue }}</div>
              </div>
            </div>
          </cdk-virtual-scroll-viewport>
          
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="pt-4">
  <div class="text-right">
    <button type="button" matStepperPrevious class="mr-4 btn-theme-secondary">
      back
    </button>
    <button type="submit" matStepperNext class="btn-theme-primary">next</button>
  </div>
</div>
