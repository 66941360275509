import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { QdodService } from 'src/app/common/services/qdod.service';
import { MixpanelService } from 'src/app/common/services/mixpanel.service';
import { FormControl } from '@angular/forms';
import { DateTime } from 'luxon';
import {
  MAT_LUXON_DATE_FORMATS,
  LuxonDateAdapter,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
} from '@angular/material-luxon-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
    { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})
export class DateRangeComponent implements OnInit {
  startDate = new FormControl(DateTime.now());
  isEndDate = false;
  endDate = new FormControl(DateTime.now());
  date = DateTime.now();
  maxDate = this.date.plus({ months: 3 });
  start: any;
  end: any;
  universeFlag: boolean;

  @Input() childField: any;
  @Input() setMax = false;
  @Input('availableDays') availableDays: string;
  @Output() messageToParent = new EventEmitter<string>();
  constructor(
    private reportService: QdodService,
    private mixpanelService: MixpanelService,
  ) {}

  ngOnInit(): void {
    if (this.availableDays) {
      this.setMaxDate();
    }
    this.sendDateToParent(
      this.startDate.value,
      this.endDate.value,
      this.childField,
    );
    this.reportService.changeStartDateSelected(this.startDate.value);
    this.reportService.changeEndDateSelected(this.endDate.value);
  }

  setMaxDate() {
    if (this.startDate.value) {
      const selectedStartDate = this.startDate.value;
      if (this.availableDays) {
        this.maxDate = selectedStartDate.plus({ days: 6 });
      } else if (this.setMax) {
        this.maxDate = selectedStartDate.plus({ months: 3 });
      }
    }
  }
  sendDateToParent(start: any, end: any, type: any) {
    this.messageToParent.emit(
      JSON.stringify({ startDate: start, endDate: end, radioState: type }),
    );
  }

  changeStartDate(event: any) {
    this.mixpanelService.trackDatePicked(
      this.reportService.formatDate(event.value),
      'start_date',
    );

    if (this.setMax) {
      this.setMaxDate();
    } else if (this.availableDays) {
      this.setMaxDate();
    }
    if (!this.isEndDate) {
      this.endDate.setValue(event.value);
      this.sendDateToParent(
        this.startDate.value,
        this.endDate.value,
        this.childField,
      );
    } else {
      this.sendDateToParent(
        this.startDate.value,
        this.endDate.value,
        this.childField,
      );
    }
  }

  changeEndDate(event: any) {
    this.sendDateToParent(
      this.startDate.value,
      this.endDate.value,
      this.childField,
    );
    this.isEndDate = true;
    this.mixpanelService.trackDatePicked(
      this.reportService.formatDate(event.value),
      'end_date',
    );
  }
}
