export const schedulesCategories: any = {
  callSchedules: `
      CUSIP
      ISIN
      SEDOL
      StartDate
      SchedulePrice
      Frequency
      OptionStyle
      EndDate
      CallDate
    `,
  putSchedules: `
      CUSIP
      ISIN
      SEDOL
      StartDate
      SchedulePrice
      Frequency
      OptionStyle
      EndDate
    `,
  sinkSchedules: `
      CUSIP
      ISIN
      SEDOL
      StartDate
      SchedulePrice
      Frequency
      EndDate
    `,
  bondRedemptions: `
      CUSIP
      ISIN
      SEDOL
      AnnouncementDate
      EffectiveDate
      RedeemType
      Price
      Amount
      AmountOutstanding
      OutstandingFactor
    `,
  amortizationSchedules: `
      CUSIP
      ISIN
      LocalCode
      SEDOL
      Amount
      AmountPercent
      RemainingCash
      RemainingCashPercent
      SunkAmount
      SunkFactor
      SunkPercent
      EndDate
      PoolFactor
      PrePaymentFactor
    `,
};

export const trancheHistory: any = {
  paydownSummary: `
  CUSIP
  ISIN
  GroupNumber
  Date
  Factor
  CouponRate
  Balance
  WAC
  WAM
  WALA
  OneMonthCPR
  ThreeMonthCPR
  SixMonthCPR
  TwelveMonthCPR
  `,
  collateralHistory: `
  CUSIP
  ISIN
  GroupNumber
  Date
  PoolFactor
  NumberOfLoans
  WAC
  WAM
  WALA
  Balance
  Total
  `,
};

export const paydownHistory = `
  CUSIP
  ISIN
  Date
  Factor
  CouponRate
  Principal
  Losses
  Interest
  Balance
  CashFlow
  CouponFrequency
  NextPaymentDate
  customFaceValue
`;
