import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TreeItemNode } from '../interfaces/tree-item';
import { CustomDataService } from './custom-data.service';
import { QdodService } from './qdod.service';
import { FetchGqlFieldsService } from './fetch-gql-fields.service';
import { CorporateActionsEvents } from '../query/corporateEdi2020Query';
import { DescriptiveDataFields } from '../query/descriptiveDataQuery';
import {
  paydownHistory,
  schedulesCategories,
  trancheHistory,
} from '../query/schedules';
import {
  bondVariableFloatRates,
  exchangeRate,
} from '../query/bondVariableFloatQuery';
import {
  mutualFundAllocations,
  mutualFundManagerInfo,
  mutualFundPerformance,
} from '../query/mutualFundData';

const Price = [
  {
    value: 'Price',
    viewValue: 'Price',
    code: '0.1',
    status: false,
    desc: 'Parent',
  },
  {
    value: 'MIC',
    code: '0.1.1',
    viewValue: 'MIC',
    status: false,
    desc: '',
  },
  {
    value: 'LocalCode',
    code: '0.1.1',
    viewValue: 'LocalCode',
    status: false,
    desc: '',
  },
  {
    value: 'ISIN',
    code: '0.1.1',
    viewValue: 'ISIN',
    status: false,
    desc: '',
  },
  {
    value: 'Currency',
    code: '0.1.1',
    viewValue: 'Currency',
    status: false,
    desc: '',
  },
  {
    value: 'PriceDate',
    code: '0.1.1',
    viewValue: 'PriceDate',
    status: false,
    desc: '',
  },
  {
    value: 'Open',
    code: '0.1.1',
    viewValue: 'Open',
    status: false,
    desc: '',
  },
  {
    value: 'High',
    code: '0.1.1',
    viewValue: 'High',
    status: false,
    desc: '',
  },
  {
    value: 'Low',
    code: '0.1.1',
    viewValue: 'Low',
    status: false,
    desc: '',
  },
  {
    value: 'Close',
    code: '0.1.1',
    viewValue: 'Close',
    status: false,
    desc: '',
  },
  {
    value: 'Mid',
    code: '0.1.1',
    viewValue: 'Mid',
    status: false,
    desc: '',
  },
  {
    value: 'Ask',
    code: '0.1.1',
    viewValue: 'Ask',
    status: false,
    desc: '',
  },
  {
    value: 'Last',
    code: '0.1.1',
    viewValue: 'Last',
    status: false,
    desc: '',
  },
  {
    value: 'Bid',
    code: '0.1.1',
    viewValue: 'Bid',
    status: false,
    desc: '',
  },
  {
    value: 'Bidsize',
    code: '0.1.1',
    viewValue: 'Bidsize',
    status: false,
    desc: '',
  },
  {
    value: 'Asksize',
    code: '0.1.1',
    viewValue: 'Asksize',
    status: false,
    desc: '',
  },
  {
    value: 'TradedVolume',
    code: '0.1.1',
    viewValue: 'TradedVolume',
    status: false,
    desc: '',
  },
  {
    value: 'SecurityID',
    code: '0.1.1',
    viewValue: 'SecurityID',
    status: false,
    desc: '',
  },
  {
    value: 'MktCloseDate',
    code: '0.1.1',
    viewValue: 'MktCloseDate',
    status: false,
    desc: '',
  },
  {
    value: 'VolFlag',
    code: '0.1.1',
    viewValue: 'VolFlag',
    status: false,
    desc: '',
  },
  {
    value: 'IssuerName',
    code: '0.1.1',
    viewValue: 'IssuerName',
    status: false,
    desc: '',
  },
  {
    value: 'SecurityType',
    code: '0.1.1',
    viewValue: 'SecurityType',
    status: false,
    desc: '',
  },
  {
    value: 'SecurityTypeDescription',
    status: false,
    code: '0.1.1',
    viewValue: 'SecurityTypeDescription',
    desc: '',
  },
  {
    value: 'SEDOL',
    code: '0.1.1',
    viewValue: 'SEDOL',
    status: false,
    desc: '',
  },
  {
    value: 'CUSIP',
    code: '0.1.1',
    viewValue: 'CUSIP',
    status: false,
    desc: '',
  },
  {
    value: 'PrimaryExchange',
    code: '0.1.1',
    viewValue: 'PrimaryExchange',
    status: false,
    desc: '',
  },
  {
    value: 'Exchange',
    code: '0.1.1',
    viewValue: 'Exchange',
    status: false,
    desc: '',
  },
  {
    value: 'TradedValue',
    code: '0.1.1',
    viewValue: 'TradedValue',
    status: false,
    desc: '',
  },
  {
    value: 'TotalTrades',
    code: '0.1.1',
    viewValue: 'TotalTrades',
    status: false,
    desc: '',
  },
  {
    value: 'CloseType',
    code: '0.1.1',
    viewValue: 'CloseType',
    status: false,
    desc: '',
  },
  {
    value: 'YieldOfMaturity',
    code: '0.1.1',
    viewValue: 'YieldOfMaturity',
    status: false,
    desc: '',
  },
  {
    value: 'Type',
    code: '0.1.1',
    viewValue: 'Type',
    status: false,
    desc: '',
  },
  {
    value: 'ProductType',
    code: '0.1.1',
    viewValue: 'ProductType',
    status: false,
    desc: '',
  },
  {
    value: 'TypeCode',
    code: '0.1.1',
    viewValue: 'TypeCode',
    status: false,
    desc: '',
  },
  {
    value: 'BondMnemonic',
    code: '0.1.1',
    viewValue: 'BondMnemonic',
    status: false,
    desc: '',
  },
  {
    value: 'BondType',
    code: '0.1.1',
    viewValue: 'BondType',
    status: false,
    desc: '',
  },
  {
    value: 'CloseDate',
    code: '0.1.1',
    viewValue: 'CloseDate',
    status: false,
    desc: '',
  },
  {
    value: 'VWAP',
    code: '0.1.1',
    viewValue: 'VWAP',
    status: false,
    desc: '',
  },
  {
    value: 'TWAP',
    code: '0.1.1',
    viewValue: 'TWAP',
    status: false,
    desc: '',
  },
  {
    value: 'AdjustedPrice',
    code: '0.1.1',
    viewValue: 'AdjustedPrice',
    status: false,
    desc: '',
  },
  {
    value: 'CumulativeAdjustmentFactor',
    code: '0.1.1',
    viewValue: 'CumulativeAdjustmentFactor',
    status: false,
    desc: '',
  },
  {
    value: 'FXConvertedClosePrice',
    code: '0.1.1',
    viewValue: 'FXConvertedClosePrice',
    status: false,
    desc: '',
  },
  {
    value: 'FXConvertedCurrency',
    code: '0.1.1',
    viewValue: 'FXConvertedCurrency',
    status: false,
    desc: '',
  },
  {
    value: 'FXConvertedRate',
    code: '0.1.1',
    viewValue: 'FXConvertedRate',
    status: false,
    desc: '',
  },
  // Common GSAC/Municipal pricing fields
  // start of missing fields
  // {
  //   value: 'PreviousClose',
  //   code: '0.1.1',
  //   viewValue: 'PreviousClose',
  //   status: false,
  //   desc: 'Previous Close price.',
  // },
  // {
  //   value: 'LastMarket',
  //   code: '0.1.1',
  //   viewValue: 'LastMarket',
  //   status: false,
  //   desc: 'Market identifier code where Last sourced',
  // },
  // {
  //   value: 'LastSize',
  //   code: '0.1.1',
  //   viewValue: 'LastSize',
  //   status: false,
  //   desc: 'Shares traded at last price',
  // },
  // {
  //   value: 'LastTimestamp',
  //   code: '0.1.1',
  //   viewValue: 'LastTimestamp',
  //   status: false,
  //   desc: 'Timestamp of last trade in EST',
  // },
  // {
  //   value: 'QuoteTimestamp',
  //   code: '0.1.1',
  //   viewValue: 'QuoteTimestamp',
  //   status: false,
  //   desc: 'Quote Timestamp',
  // },
  // {
  //   value: 'BidMarket',
  //   code: '0.1.1',
  //   viewValue: 'BidMarket',
  //   status: false,
  //   desc: 'Market identifier code where Bid sourced',
  // },
  // {
  //   value: 'AskMarket',
  //   code: '0.1.1',
  //   viewValue: 'AskMarket',
  //   status: false,
  //   desc: 'Market identifier code where Ask sourced',
  // },
  // {
  //   value: 'NumberOfTrades',
  //   code: '0.1.1',
  //   viewValue: 'NumberOfTrades',
  //   status: false,
  //   desc: 'The number of shares or contracts traded during the trading day or session',
  // },
  // {
  //   value: 'TradingStatus',
  //   code: '0.1.1',
  //   viewValue: 'TradingStatus',
  //   status: false,
  //   desc: 'Conveys the trading status of a Ticker',
  // },
  // {
  //   value: 'Change',
  //   code: '0.1.1',
  //   viewValue: 'Change',
  //   status: false,
  //   desc: 'Difference between Last and PreviousClose',
  // },
  // {
  //   value: 'ChangePercentage',
  //   code: '0.1.1',
  //   viewValue: 'ChangePercentage',
  //   status: false,
  //   desc: '% Difference between Last and PreviousClose',
  // },
  // {
  //   value: 'Feed',
  //   code: '0.1.1',
  //   viewValue: 'Feed',
  //   status: false,
  //   desc: '',
  // },
  // {
  //   value: 'IsDelayed',
  //   code: '0.1.1',
  //   viewValue: 'IsDelayed',
  //   status: false,
  //   desc: 'Price information is 15 minutes delayed when True',
  // },
  // {
  //   value: 'DelayedInterval',
  //   code: '0.1.1',
  //   viewValue: 'DelayedInterval',
  //   status: false,
  //   desc: 'Default to 15 min if IsDelayed is true',
  // },
  // {
  //   value: 'Broker',
  //   code: '0.1.1',
  //   viewValue: 'Broker',
  //   status: false,
  //   desc: '',
  // },
  // {
  //   value: 'Note',
  //   code: '0.1.1',
  //   viewValue: 'Note',
  //   status: false,
  //   desc: '',
  // },
  // {
  //   value: 'AssetClass',
  //   code: '0.1.1',
  //   viewValue: 'AssetClass',
  //   status: false,
  //   desc: '',
  // },
  // {
  //   value: 'FXConvertedPrice',
  //   code: '0.1.1',
  //   viewValue: 'FXConvertedPrice',
  //   status: false,
  //   desc: '',
  // },
  // end of missing fields
  {
    value: 'BidModifiedDuration',
    code: '0.1.1',
    viewValue: 'BidModifiedDuration',
    status: false,
    desc: 'The first derivative of the bond price, with respect to yield, based on the bid price.',
    type: 'common',
  },
  {
    value: 'BidConvexity',
    code: '0.1.1',
    viewValue: 'BidConvexity',
    status: false,
    desc: 'Measures the change of duration with the change in yield, based on the bid price.',
  },
  {
    value: 'AccruedInterest',
    code: '0.1.1',
    viewValue: 'AccruedInterest',
    status: false,
    desc: 'The interest that is owed, but not yet paid, added to the price of the bond.',
  },
  {
    value: 'BidYTW',
    code: '0.1.1',
    viewValue: 'BidYTW',
    status: false,
    desc: 'The lowest yield, under all possible scenarios, of the bid price of the instrument.',
  },
  {
    value: 'MidYTW',
    code: '0.1.1',
    viewValue: 'MidYTW',
    status: false,
    desc: 'The lowest yield, under all possible scenarios, of the mid price of the instrument.',
  },
  {
    value: 'AskYTW',
    code: '0.1.1',
    viewValue: 'AskYTW',
    status: false,
    desc: 'The lowest yield, under all possible scenarios, of the ask price of the instrument.',
  },
  {
    value: 'LiquidityScore',
    code: '0.1.1',
    viewValue: 'LiquidityScore',
    status: false,
    desc: 'An indication of the market liquidity for a security.',
  },
  // Municipal bond pricing fields
  {
    value: 'PriceToDateBid',
    code: '0.1.1',
    viewValue: 'PriceToDateBid',
    status: false,
    desc: 'The date the bid price is valued to.',
  },
  {
    value: 'PriceToBid',
    code: '0.1.1',
    viewValue: 'PriceToBid',
    status: false,
    desc: 'Pricing methodology used to calculate the bid price.',
  },
  {
    value: 'PriceToDateMid',
    code: '0.1.1',
    viewValue: 'PriceToDateMid',
    status: false,
    desc: 'The date the mid price is valued to.',
  },
  {
    value: 'PriceToMid',
    code: '0.1.1',
    viewValue: 'PriceToMid',
    status: false,
    desc: 'Pricing methodology used to calculate the mid price.',
  },
  {
    value: 'PriceToDateAsk',
    code: '0.1.1',
    viewValue: 'PriceToDateAsk',
    status: false,
    desc: 'The date the ask price is valued to.',
  },
  {
    value: 'PriceToAsk',
    code: '0.1.1',
    viewValue: 'PriceToAsk',
    status: false,
    desc: 'Pricing methodology used to calculate the ask price.',
  },
  {
    value: 'EvaluatedBidAskPriceSpread',
    code: '0.1.1',
    viewValue: 'EvaluatedBidAskPriceSpread',
    status: false,
    desc: 'The difference between the ask price and the bid price.',
  },
  {
    value: 'EvaluatedBidAskYieldSpread',
    code: '0.1.1',
    viewValue: 'EvaluatedBidAskYieldSpread',
    status: false,
    desc: 'The difference between the ask yield and the bid yield.',
  },
  {
    value: 'TradeData',
    code: '0.1.1',
    viewValue: 'TradeData',
    status: false,
    desc: 'Indicates whether any trace data was available for the instrument on the previous business day.',
  },
  {
    value: 'LastTradeDate',
    code: '0.1.1',
    viewValue: 'LastTradeDate',
    status: false,
    desc: 'The most recent date a particular CUSIP was observed in TRACE fuel.',
  },
  // Corporate bond pricing fields
  {
    value: 'BidAskPriceSpread',
    code: '0.1.1',
    viewValue: 'BidAskPriceSpread',
    status: false,
    desc: 'The difference between the ask price and the bid price.',
  },
  {
    value: 'DirtyBidPrice',
    code: '0.1.1',
    viewValue: 'DirtyBidPrice',
    status: false,
    desc: 'The price of the bond plus the interest that accrued between coupon payments, based on the bid price.',
  },
  {
    value: 'DirtyMidPrice',
    code: '0.1.1',
    viewValue: 'DirtyMidPrice',
    status: false,
    desc: 'The price of the bond plus the interest that accrued between coupon payments, based on the mid price.',
  },
  {
    value: 'DirtyAskPrice',
    code: '0.1.1',
    viewValue: 'DirtyAskPrice',
    status: false,
    desc: 'The price of the bond plus the interest that accrued between coupon payments, based on the ask price.',
  },
  {
    value: 'BidYTM',
    code: '0.1.1',
    viewValue: 'BidYTM',
    status: false,
    desc: 'The yield to maturity of the bid price of the instrument.',
  },
  {
    value: 'MidYTM',
    code: '0.1.1',
    viewValue: 'MidYTM',
    status: false,
    desc: 'The yield to maturity of the mid price of the instrument.',
  },
  {
    value: 'AskYTM',
    code: '0.1.1',
    viewValue: 'AskYTM',
    status: false,
    desc: 'The yield to maturity of the ask price of the instrument.',
  },
  {
    value: 'BidAskYieldSpread',
    code: '0.1.1',
    viewValue: 'BidAskYieldSpread',
    status: false,
    desc: 'The difference between the ask yield and bid yield.',
  },
  {
    value: 'YTWDate',
    code: '0.1.1',
    viewValue: 'YTWDate',
    status: false,
    desc: 'The date used in the yield to worst calculation.',
  },
  {
    value: 'BidSTW',
    code: '0.1.1',
    viewValue: 'BidSTW',
    status: false,
    desc: 'The spread between bid yield to worst and treasury yield to worst.',
  },
  {
    value: 'BidDiscountMargin',
    code: '0.1.1',
    viewValue: 'BidDiscountMargin',
    status: false,
    desc: 'The discount margin of a Floating Rate Note based on the bid price.',
  },
  {
    value: 'MidDiscountMargin',
    code: '0.1.1',
    viewValue: 'MidDiscountMargin',
    status: false,
    desc: 'The discount margin of a Floating Rate Note based on the mid price.',
  },
  {
    value: 'AskDiscountMargin',
    code: '0.1.1',
    viewValue: 'AskDiscountMargin',
    status: false,
    desc: 'The discount margin of a Floating Rate Note based on the ask price.',
  },
  {
    value: 'BidMacaulayDuration',
    code: '0.1.1',
    viewValue: 'BidMacaulayDuration',
    status: false,
    desc: 'Weighted average maturity based on the bid price.',
  },
  {
    value: 'MidMacaulayDuration',
    code: '0.1.1',
    viewValue: 'MidMacaulayDuration',
    status: false,
    desc: 'Weighted average maturity based on the mid price.',
  },
  {
    value: 'AskMacaulayDuration',
    code: '0.1.1',
    viewValue: 'AskMacaulayDuration',
    status: false,
    desc: 'Weighted average maturity based on the ask price.',
  },
  {
    value: 'MidModifiedDuration',
    code: '0.1.1',
    viewValue: 'MidModifiedDuration',
    status: false,
    desc: 'First derivative of the bond price with respect to yield (mid price).',
  },
  {
    value: 'AskModifiedDuration',
    code: '0.1.1',
    viewValue: 'AskModifiedDuration',
    status: false,
    desc: 'First derivative of the bond price with respect to yield (ask price).',
  },
  {
    value: 'MidConvexity',
    code: '0.1.1',
    viewValue: 'MidConvexity',
    status: false,
    desc: 'Measures duration change with yield change (mid price).',
  },
  {
    value: 'AskConvexity',
    code: '0.1.1',
    viewValue: 'AskConvexity',
    status: false,
    desc: 'Measures duration change with yield change (ask price).',
  },
  {
    value: 'BidOAS',
    code: '0.1.1',
    viewValue: 'BidOAS',
    status: false,
    desc: 'Option Adjusted Spread based on bid price.',
  },
  {
    value: 'MidOAS',
    code: '0.1.1',
    viewValue: 'MidOAS',
    status: false,
    desc: 'Option Adjusted Spread based on mid price.',
  },
  {
    value: 'AskOAS',
    code: '0.1.1',
    viewValue: 'AskOAS',
    status: false,
    desc: 'Option Adjusted Spread based on ask price.',
  },
  {
    value: 'BidEffectiveDuration',
    code: '0.1.1',
    viewValue: 'BidEffectiveDuration',
    status: false,
    desc: 'Effective duration with embedded options (bid price).',
  },
  {
    value: 'MidEffectiveDuration',
    code: '0.1.1',
    viewValue: 'MidEffectiveDuration',
    status: false,
    desc: 'Effective duration with embedded options (mid price).',
  },
  {
    value: 'AskEffectiveDuration',
    code: '0.1.1',
    viewValue: 'AskEffectiveDuration',
    status: false,
    desc: 'Effective duration with embedded options (ask price).',
  },
  {
    value: 'BidEffectiveConvexity',
    code: '0.1.1',
    viewValue: 'BidEffectiveConvexity',
    status: false,
    desc: 'Effective convexity based on bid price.',
  },
  {
    value: 'MidEffectiveConvexity',
    code: '0.1.1',
    viewValue: 'MidEffectiveConvexity',
    status: false,
    desc: 'Effective convexity based on mid price.',
  },
  {
    value: 'AskEffectiveConvexity',
    code: '0.1.1',
    viewValue: 'AskEffectiveConvexity',
    status: false,
    desc: 'Effective convexity based on ask price.',
  },
  {
    value: 'BondAgencyData',
    code: '0.1.1',
    viewValue: 'BondAgencyData',
    status: false,
    desc: 'Indicates bond agency data availability on the previous business day.',
  },
  {
    value: 'QuoteData',
    code: '0.1.1',
    viewValue: 'QuoteData',
    status: false,
    desc: 'Indicates quote data availability on the previous business day.',
  },
  {
    value: 'TraceData',
    code: '0.1.1',
    viewValue: 'TraceData',
    status: false,
    desc: 'Indicates trace data availability on the previous business day.',
  },
  {
    value: 'InterdealerBrokerData',
    code: '0.1.1',
    viewValue: 'InterdealerBrokerData',
    status: false,
    desc: 'Indicates interdealer broker data availability on the previous business day.',
  },
  {
    value: 'LiquidityASOF',
    code: '0.1.1',
    viewValue: 'LiquidityASOF',
    status: false,
    desc: 'The date of the liquidity score.',
  },
];

const CorporateActions = [
  {
    value: 'Corporate Actions/Dividends',
    viewValue: 'Corporate Actions/Dividends',
    code: '0.4',
    status: false,
    desc: 'Parent',
  },
  {
    value: 'CUSIP',
    code: '0.4.1',
    viewValue: 'CUSIP',
    status: false,
    desc: '',
  },
  {
    value: 'ISIN',
    code: '0.4.1',
    viewValue: 'ISIN',
    status: false,
    desc: '',
  },
  {
    value: 'SEDOL',
    code: '0.4.1',
    viewValue: 'SEDOL',
    status: false,
    desc: '',
  },
  {
    value: 'LocalCode',
    code: '0.4.1',
    viewValue: 'LocalCode',
    status: false,
    desc: '',
  },
  {
    value: 'EventCode',
    code: '0.4.1',
    viewValue: 'EventCode',
    status: false,
    desc: '',
  },
  {
    value: 'MandatoryVoluntaryFlag',
    code: '0.4.1',
    viewValue: 'MandatoryVoluntaryFlag',
    status: false,
    desc: '',
  },
  {
    value: 'Status',
    code: '0.4.1',
    viewValue: 'Status',
    status: false,
    desc: '',
  },
  {
    value: 'IssuerName',
    code: '0.4.1',
    viewValue: 'IssuerName',
    status: false,
    desc: '',
  },
  {
    value: 'SecurityDescription',
    code: '0.4.1',
    viewValue: 'SecurityDescription',
    status: false,
    desc: '',
  },
  {
    value: 'SecurityType',
    code: '0.4.1',
    viewValue: 'SecurityType',
    status: false,
    desc: '',
  },
  {
    value: 'ExchangeCode',
    code: '0.4.1',
    viewValue: 'ExchangeCode',
    status: false,
    desc: '',
  },
  {
    value: 'ListingCountryCode',
    code: '0.4.1',
    viewValue: 'ListingCountryCode',
    status: false,
    desc: '',
  },
  {
    value: 'ListingStatus',
    code: '0.4.1',
    viewValue: 'ListingStatus',
    status: false,
    desc: '',
  },
  {
    value: 'BloombergCompositeGlobalID',
    code: '0.4.1',
    viewValue: 'BloombergCompositeGlobalID',
    status: false,
    desc: '',
  },
  {
    value: 'BloombergCompositeTicker',
    code: '0.4.1',
    viewValue: 'BloombergCompositeTicker',
    status: false,
    desc: '',
  },
  {
    value: 'BloombergFIGI',
    code: '0.4.1',
    viewValue: 'BloombergFIGI',
    status: false,
    desc: '',
  },
  {
    value: 'BloombergExchangeLevelTicker',
    code: '0.4.1',
    viewValue: 'BloombergExchangeLevelTicker',
    status: false,
    desc: '',
  },
  {
    value: 'MarketIdentifierCode',
    code: '0.4.1',
    viewValue: 'MarketIdentifierCode',
    status: false,
    desc: '',
  },
  {
    value: 'MIC',
    code: '0.4.1',
    viewValue: 'MIC',
    status: false,
    desc: '',
  },
  {
    value: 'LotSizeMinimum',
    code: '0.4.1',
    viewValue: 'LotSizeMinimum',
    status: false,
    desc: '',
  },
  {
    value: 'ListingDate',
    code: '0.4.1',
    viewValue: 'ListingDate',
    status: false,
    desc: '',
  },
  {
    value: 'DelistingDate',
    code: '0.4.1',
    viewValue: 'DelistingDate',
    status: false,
    desc: '',
  },
  {
    value: 'SharesHeldType',
    code: '0.4.1',
    viewValue: 'SharesHeldType',
    status: false,
    desc: '',
  },
  {
    value: 'RatioOld',
    code: '0.4.1',
    viewValue: 'RatioOld',
    status: false,
    desc: '',
  },
  {
    value: 'RatioNew',
    code: '0.4.1',
    viewValue: 'RatioNew',
    status: false,
    desc: '',
  },
  {
    value: 'EffectiveDate',
    code: '0.4.1',
    viewValue: 'EffectiveDate',
    status: false,
    desc: '',
  },
  {
    value: 'AnnouncementDate',
    code: '0.4.1',
    viewValue: 'AnnouncementDate',
    status: false,
    desc: '',
  },
  {
    value: 'EventChangeDate',
    code: '0.4.1',
    viewValue: 'EventChangeDate',
    status: false,
    desc: '',
  },
  {
    value: 'PayDate',
    code: '0.4.1',
    viewValue: 'PayDate',
    status: false,
    desc: '',
  },
  {
    value: 'PaymentCurrency',
    code: '0.4.1',
    viewValue: 'PaymentCurrency',
    status: false,
    desc: '',
  },
  {
    value: 'PaymentType',
    code: '0.4.1',
    viewValue: 'PaymentType',
    status: false,
    desc: '',
  },
  {
    value: 'ActionDescription',
    code: '0.4.1',
    viewValue: 'ActionDescription',
    status: false,
    desc: '',
  },
  {
    value: 'Detail',
    code: '0.4.1',
    viewValue: 'Detail',
    status: false,
    desc: '',
  },
  {
    value: 'PeriodDate',
    code: '0.4.1',
    viewValue: 'PeriodDate',
    status: false,
    desc: '',
  },
  {
    value: 'ExDate',
    code: '0.4.1',
    viewValue: 'ExDate',
    status: false,
    desc: '',
  },
  {
    value: 'RecordDate',
    code: '0.4.1',
    viewValue: 'RecordDate',
    status: false,
    desc: '',
  },
  {
    value: 'EventActionCode',
    code: '0.4.1',
    viewValue: 'EventActionCode',
    status: false,
    desc: '',
  },
  {
    value: 'EventID',
    code: '0.4.1',
    viewValue: 'EventID',
    status: false,
    desc: '',
  },
];

const Events = [
  {
    value: 'Events',
    code: '0.4.2',
    viewValue: 'Events',
    status: false,
    desc: '',
  },
];

const IndustryClassification = [
  {
    value: 'IndustryClassification',
    code: '0.11',
    viewValue: 'Industry Classification',
    status: false,
    desc: '',
  },
];

const TIPS = [
  {
    value: 'TIPS',
    viewValue: 'TIPS',
    code: '0.7',
    status: false,
    desc: 'Parent',
  },
  {
    value: 'IndexDate',
    code: '0.7.1',
    viewValue: 'IndexDate',
    status: false,
    desc: '',
  },
  {
    value: 'CUSIP',
    code: '0.7.1',
    viewValue: 'CUSIP',
    status: false,
    desc: '',
  },
  {
    value: 'RefCPI',
    code: '0.7.1',
    viewValue: 'RefCPI',
    status: false,
    desc: '',
  },
  {
    value: 'IndexRatio',
    code: '0.7.1',
    viewValue: 'IndexRatio',
    status: false,
    desc: '',
  },
];

const MilRate = [
  {
    value: 'Mil Rate',
    viewValue: 'Mil Rate',
    code: '0.8',
    status: false,
    desc: 'Parent',
  },
  {
    value: 'CUSIP',
    code: '0.8.1',
    viewValue: 'CUSIP',
    status: false,
    desc: '',
  },
  {
    value: 'MILRate',
    code: '0.8.1',
    viewValue: 'MILRate',
    status: false,
    desc: '',
  },
  {
    value: 'MILRateDate',
    code: '0.8.1',
    viewValue: 'MILRateDate',
    status: false,
    desc: '',
  },
  {
    value: 'companyname',
    code: '0.8.1',
    viewValue: 'companyname',
    status: false,
    desc: '',
  },
  {
    value: 'Security',
    code: '0.8.1',
    viewValue: 'Security',
    status: false,
    desc: '',
  },
];

const DescriptiveData = [
  {
    value: 'Descriptive Data',
    viewValue: 'Descriptive Data',
    code: '0.10',
    status: false,
    desc: 'Parent',
  },
];

const TrancheHistory = [
  {
    value: 'Securitized - Factors',
    viewValue: 'Bond Factor And Cashflow',
    code: '0.6',
    status: false,
    desc: 'Parent',
  },
];

const PaydownHistory = [
  {
    value: 'PaydownHistory',
    viewValue: 'Paydown History',
    code: '0.6.2',
    status: false,
    desc: 'Parent',
  },
];

const Schedules = [
  {
    value: 'Schedules',
    viewValue: 'Bond Schedule And Redemption',
    code: '0.12',
    status: false,
    desc: 'Parent',
  },
];

const BondVariableFloatRates = [
  {
    value: 'bondVariableFloatRates',
    code: '0.13',
    viewValue: 'Bond Variable Float Rates',
    status: false,
    desc: '',
  },
];

const ExchangeRate = [
  {
    value: 'exchangeRate',
    code: '0.14',
    viewValue: 'Exchange Rate',
    status: false,
    desc: '',
  },
];

const MutualFundData = [
  {
    value: 'mutualFundData',
    code: '0.15',
    viewValue: 'Mutual Fund Data',
    status: false,
    desc: '',
  },
  {
    value: 'mutualFundAllocations',
    code: '0.15.2',
    viewValue: 'Mutual Fund Allocations',
    status: false,
    desc: '',
  },
  {
    value: 'mutualFundManagerInfo',
    code: '0.15.3',
    viewValue: 'Mutual Fund Manager Info',
    status: false,
    desc: '',
  },
  {
    value: 'mutualFundPerformance',
    code: '0.15.4',
    viewValue: 'Mutual Fund Performance',
    status: false,
    desc: '',
  },
];

@Injectable({
  providedIn: 'root',
})
export class TreeStructureService {
  dataChange = new BehaviorSubject<TreeItemNode[]>([]);
  treeData: any[];
  treeDataLoaded = false;
  requiredObjectsName = this.gqlService.getTreeFieldsKeys();
  get data(): TreeItemNode[] {
    return this.dataChange.value;
  }
  eventKeys = Object.keys(CorporateActionsEvents);
  TREE_DATA: any = [];
  descriptiveDataKeys = Object.keys(DescriptiveDataFields);
  trancheHistoryKeys = Object.keys(trancheHistory);
  filteredDataObject: any = {};

  constructor(
    public dataService: CustomDataService,
    private qdodService: QdodService,
    private gqlService: FetchGqlFieldsService,
  ) {
    this.initialize();
  }

  initialize() {
    this.qdodService.currentFilteredGQL_Data.subscribe((data: any) => {
      const filteredObjects = data.filter((obj: any) =>
        this.requiredObjectsName.includes(obj.name),
      );
      this.filteredDataObject = this.getFilteredData(filteredObjects);
      this.setTreeData(this.filteredDataObject);
      this.treeDataLoaded = true;
    });
  }

  getGicsSicTreeData() {
    const gicsTreeData: any = [
      {
        value: 'GICS',
        code: '0.11.1',
        viewValue: 'GICS',
        status: false,
        desc: '',
      },
    ];
    const sicTreeData: any = [
      {
        value: 'SIC',
        code: '0.11.2',
        viewValue: 'SIC',
        status: false,
        desc: '',
      },
    ];
    const boilerGicsTreeData = {
      value: '',
      code: '0.11.1.1',
      viewValue: '',
      status: false,
      desc: '',
    };
    const boilerSicsTreeData = {
      value: '',
      code: '0.11.2.1',
      viewValue: '',
      status: false,
      desc: '',
    };
    const gicsFields = [
      'GICSIndustry',
      'GICSIndustryCode',
      'GICSSubIndustry',
      'GICSSubIndustryCode',
      'GICSGroup',
      'GICSGroupCode',
      'GICSSector',
      'GICSSectorCode',
    ];
    const sicFields = [
      'SICCode',
      'SICIndustryMajorClass',
      'SICIndustryMinorClass',
      'SICIndustryGroup',
      'SICIndustrySubGroup',
    ];

    sicFields.forEach((field: any) => {
      const obj = { ...boilerSicsTreeData };
      obj.value = field;
      switch (field) {
        case 'SICCode':
          obj.viewValue = 'Code';
          break;
        case 'SICIndustryMajorClass':
          obj.viewValue = 'Industry Major Class';
          break;
        case 'SICIndustryMinorClass':
          obj.viewValue = 'Industry Minor Class';
          break;
        case 'SICIndustryGroup':
          obj.viewValue = 'Industry Group';
          break;
        case 'SICIndustrySubGroup':
          obj.viewValue = 'Industry Sub Group';
          break;
        default:
          obj.viewValue = field;
      }
      sicTreeData.push(obj);
    });
    gicsFields.forEach((field: any) => {
      const obj = { ...boilerGicsTreeData };
      obj.value = field;
      switch (field) {
        case 'GICSIndustry':
          obj.viewValue = 'Industry';
          break;
        case 'GICSIndustryCode':
          obj.viewValue = 'Industry Code';
          break;
        case 'GICSSubIndustry':
          obj.viewValue = 'Sub Industry';
          break;
        case 'GICSSubIndustryCode':
          obj.viewValue = 'Sub Industry Code';
          break;
        case 'GICSGroup':
          obj.viewValue = 'Group';
          break;
        case 'GICSGroupCode':
          obj.viewValue = 'Group Code';
          break;
        case 'GICSSector':
          obj.viewValue = 'Sector';
          break;
        case 'GICSSectorCode':
          obj.viewValue = 'Sector Code';
          break;
        default:
          obj.viewValue = field;
      }
      gicsTreeData.push(obj);
    });
    return [...sicTreeData, ...gicsTreeData];
  }

  getGicsTreeData() {
    const gicsTreeData: any = [];
    const boilerPlateTreeData = {
      value: '',
      code: '0.11.1',
      viewValue: '',
      status: false,
      desc: '',
    };
    const gicsFields = [
      'GICSIndustry',
      'GICSIndustryCode',
      'GICSSubIndustry',
      'GICSSubIndustryCode',
      'GICSGroup',
      'GICSGroupCode',
      'GICSSector',
      'GICSSectorCode',
    ];

    gicsFields.forEach((field: any) => {
      const obj = { ...boilerPlateTreeData };
      obj.value = field;
      switch (field) {
        case 'GICSIndustry':
          obj.viewValue = 'Industry';
          break;
        case 'GICSIndustryCode':
          obj.viewValue = 'Industry Code';
          break;
        case 'GICSSubIndustry':
          obj.viewValue = 'Sub Industry';
          break;
        case 'GICSSubIndustryCode':
          obj.viewValue = 'Sub Industry Code';
          break;
        case 'GICSGroup':
          obj.viewValue = 'Group';
          break;
        case 'GICSGroupCode':
          obj.viewValue = 'Group Code';
          break;
        case 'GICSSector':
          obj.viewValue = 'Sector';
          break;
        case 'GICSSectorCode':
          obj.viewValue = 'Sector Code';
          break;
        default:
          obj.viewValue = field;
      }
      gicsTreeData.push(obj);
    });
    return gicsTreeData;
  }

  getEventsTreeData() {
    const eventsTreeData: any = [];
    const boilerPlateTreeData = {
      value: '',
      code: '0.4.2.1',
      viewValue: '',
      status: false,
      desc: '',
    };

    this.eventKeys.forEach((event: any) => {
      const obj = { ...boilerPlateTreeData };
      obj.value = event;
      switch (event) {
        case 'IntlCodeChange':
          obj.viewValue = 'International Code Change';
          break;
        case 'GeneralMeeting':
          obj.viewValue = 'Company Meeting';
          break;
        case 'BuyBack':
          obj.viewValue = 'Buyback';
          break;
        case 'BBGCompositeChange':
          obj.viewValue = 'Bloomberg Composite ID Change';
          break;
        case 'BBGFigiChange':
          obj.viewValue = 'Bloomberg Exchange ID Change';
          break;
        case 'BonusRight':
          obj.viewValue = 'Bonus Rights';
          break;
        case 'CurrencyRedenom':
          obj.viewValue = 'Currency Redenomination';
          break;
        case 'IncorporationChange':
          obj.viewValue = 'Country of Incorporation Change';
          break;
        case 'DepositoryChange':
          obj.viewValue = 'Depository Receipt Change';
          break;
        case 'Entitlement':
          obj.viewValue = 'Entitlement Issue';
          break;
        case 'MutualFundConv':
          obj.viewValue = 'Mutual Fund Conversion';
          break;
        default:
          obj.viewValue = this.changeCamelCaseToSpaceSeparated(event);
      }
      eventsTreeData.push(obj);
    });
    return eventsTreeData;
  }

  getDescriptiveDataTreeData(gqlObject: any) {
    const descriptiveDataTreeData: any = [];
    const boilerPlateTreeData = {
      value: '',
      code: '0.10.1.1',
      viewValue: '',
      status: false,
      desc: '',
    };
    let initialNum = 1;
    this.descriptiveDataKeys.forEach((event: any) => {
      descriptiveDataTreeData.push({
        value: event,
        code: `0.10.${initialNum}`,
        viewValue: this.changeCamelCaseToSpaceSeparated(event).trim(),
        status: false,
        desc: '',
      });
      const result = DescriptiveDataFields[event]
        .trim()
        .split('\n')
        .map((str: any) => str.trim());
      result.forEach((element: any) => {
        const obj = {
          ...boilerPlateTreeData,
          code: `0.10.${initialNum}.1`,
        };
        obj.value = element;
        obj.viewValue = element;
        obj.desc = this.getDescriptiveDataDesc(gqlObject, event, element);
        descriptiveDataTreeData.push(obj);
      });
      initialNum++;
    });
    return descriptiveDataTreeData;
  }

  getHeadingsUISchema(
    obj: string[],
    code: string,
    gqlSchema: any,
    parent: string,
  ) {
    const keys = Object.keys(obj);
    const parentField = gqlSchema[parent];

    const objectSchema: any = {};
    const boilerPlateObj = {
      value: '',
      code,
      viewValue: '',
      status: false,
      desc: '',
      childNodes: '',
    };
    const parentNodes: any = { ...obj };

    keys.forEach((event: string) => {
      const childNodes = parentNodes[event];
      const obj = { ...boilerPlateObj };
      obj.value = event;
      obj.desc = parentField ? parentField[event] : '';
      if (childNodes) {
        obj.childNodes = childNodes
          .trim()
          .split('\n')
          .map((str: any) => str.trim());
      }
      (obj.viewValue =
        code === '0.4.2.1'
          ? event
          : this.changeCamelCaseToSpaceSeparated(event).trim()),
        (objectSchema[event] = [obj]);
    });
    return objectSchema;
  }

  getStringFieldMappings(fields: string, code: string) {
    const keys = fields
      .trim()
      .split('\n')
      .map((str) => str.trim());
    const objectSchema: any = [];
    const boilerPlateObj = {
      value: '',
      code,
      viewValue: '',
      status: false,
      desc: '',
    };
    keys.forEach((event: any) => {
      const obj = { ...boilerPlateObj };
      obj.value = event;
      obj.viewValue = this.getCustomViewValue(event);
      objectSchema.push(obj);
    });
    return objectSchema;
  }

  getCustomViewValue(key: string): string {
    let result = '';
    switch (key) {
      case 'customFaceValue:userDefinedField1':
        result = 'customFaceValue';
        break;
      default:
        result = key;
        break;
    }
    return result;
  }

  getDescriptiveDataDesc(gqlObject: any, event: any, element: any) {
    let desc = '';
    const eventObj = gqlObject[event];
    const bondsData = {
      ...gqlObject['SecuritizedBondFundamental'],
      ...gqlObject['CorporateBondFundamental'],
      ...gqlObject['MunicipalBondFundamental'],
    };
    if (event === 'FixedIncomeFundamental' && bondsData[element]) {
      desc = bondsData[element];
    } else {
      if (eventObj) {
        desc = eventObj[element];
      }
    }
    return desc;
  }

  changeCamelCaseToSpaceSeparated(text: string) {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  setTreeData(treeData: any) {
    this.TREE_DATA = this.getDescriptionedTreeData(treeData);
    this.treeData = this.TREE_DATA;

    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    const buildTreedata = this.buildFileTree(this.TREE_DATA, '0');
    // Notify the change.
    this.dataChange.next(buildTreedata);
  }

  getFilteredData(filteredObjects: any) {
    // item from gql & rql which is required
    const filteredData: any = {};
    filteredObjects.forEach((element: any) => {
      const obj = this.updateGqlObjects(element.fields);
      switch (element.name) {
        case 'PriceData':
          filteredData['Price'] = obj;
          break;
        case 'CorporateActionBase':
          filteredData['Corporate Actions/Dividends'] = obj;
          break;
        case 'TipsInformation':
          filteredData['TIPS'] = obj;
          break;
        case 'MilRate':
          filteredData['Mil Rate'] = obj;
          break;
        case 'CollateralHistory':
          filteredData['Collateral'] = obj;
          break;
        case 'PaydownSummary':
          filteredData['Summary'] = obj;
          break;
        case 'BondVariableFloatRates':
          filteredData['BondVariableFloatRates'] = obj;
          break;
        default:
          filteredData[element.name] = obj;
      }
    });

    return filteredData;
  }

  getDescriptiveDataUISchema() {
    const descriptiveDataSchema: any = {};
    const boilerPlateObj = {
      value: '',
      code: '0.10.1.1',
      viewValue: '',
      status: false,
      desc: '',
    };

    this.descriptiveDataKeys.forEach((event: any) => {
      descriptiveDataSchema[event] = [];
      const result = DescriptiveDataFields[event]
        .trim()
        .split('\n')
        .map((str: any) => str.trim());
      result.forEach((element: any) => {
        const obj = { ...boilerPlateObj };
        obj.value = element;
        obj.viewValue = element;
        descriptiveDataSchema[event].push(obj);
      });
    });
    return descriptiveDataSchema;
  }

  getDescriptionedTreeData(gqlObject: any) {
    let uiSchema: any = {};
    let result: any = [];
    uiSchema = {
      Price: [...Price],
      'Descriptive Data': [
        ...DescriptiveData,
        ...this.getDescriptiveDataTreeData(gqlObject),
      ],
      'Corporate Actions/Dividends': [...CorporateActions],
      Events: [...Events],
      ...this.getHeadingsUISchema(
        CorporateActionsEvents,
        '0.4.2.1',
        gqlObject,
        '',
      ),
      TIPS: [...TIPS],
      'Mil Rate': [...MilRate],
      TrancheHistory: [...TrancheHistory],
      ...this.getHeadingsUISchema(
        trancheHistory,
        '0.6.1',
        gqlObject,
        'BondFactorAndCashFlowHistory',
      ),
      PaydownHistory: [
        ...PaydownHistory,
        ...this.getStringFieldMappings(paydownHistory, '0.6.2.1'),
      ],
      Schedules: [...Schedules],
      ...this.getHeadingsUISchema(
        schedulesCategories,
        '0.12.1',
        gqlObject,
        'BondScheduleAndRedemption',
      ),
      BondVariableFloatRates: [
        ...BondVariableFloatRates,
        ...this.getStringFieldMappings(bondVariableFloatRates, '0.13.1'),
      ],
      IndustryClassification: [
        ...IndustryClassification,
        ...this.getGicsSicTreeData(),
      ],
      ExchangeRate: [
        ...ExchangeRate,
        ...this.getStringFieldMappings(exchangeRate, '0.14.1'),
      ],
      MutualFundData: [...MutualFundData],
      MutualFundManagerInfo: [
        ...this.getStringFieldMappings(mutualFundManagerInfo, '0.15.3.1'),
      ],
      MutualFundPerformance: [
        ...this.getStringFieldMappings(mutualFundPerformance, '0.15.4.1'),
      ],

      ...this.getHeadingsUISchema(
        mutualFundAllocations,
        '0.15.2.1',
        gqlObject,
        'MutualFundAllocations',
      ),
    };

    for (const uiKey in uiSchema) {
      uiSchema[uiKey].forEach((ui: any) => {
        if (gqlObject[uiKey]) {
          if (gqlObject[uiKey][ui.value]) {
            ui.desc = gqlObject[uiKey][ui.value];
          }
        }
      });
    }
    for (const key in uiSchema) {
      if (Object.prototype.hasOwnProperty.call(uiSchema, key)) {
        const element = uiSchema[key];
        result = [...result, ...element];
      }
    }
    return result;
  }

  updateGqlObjects(arr: []) {
    const obj: any = {};
    arr.forEach((field: any) => {
      obj[field.name] = field.description;
    });
    return obj;
  }
  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */

  buildFileTree(obj: any[], level: string): TreeItemNode[] {
    return obj
      .filter(
        (o) =>
          (<string>o.code).startsWith(level + '.') &&
          (o.code.match(/\./g) || []).length ===
            (level.match(/\./g) || []).length + 1,
      )
      .map((o) => {
        const node = new TreeItemNode();
        node.value = o.value;
        node.viewValue = o.viewValue;
        node.code = o.code;
        node.desc = o.desc;
        node.status = o.status;
        if (o.childNodes) {
          node.childNodes = o.childNodes;
        }
        const children = obj.filter((so) =>
          (<string>so.code).startsWith(level + '.'),
        );
        if (children && children.length > 0) {
          // node.children = this.buildFileTree(children, o.code,o.desc);
          node.children = this.buildFileTree(children, o.code);
        }
        return node;
      });
  }

  public filter(filterText: string) {
    let filteredTextData: any;
    let filteredDescData: any;
    let filteredTreeData: any;
    if (filterText) {
      filteredTextData = this.treeData.filter((d) => {
        return (
          d.text.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) >
          -1
        );
      });
      filteredDescData = this.treeData.filter((d) => {
        return (
          d.desc.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) >
          -1
        );
      });

      filteredTreeData = [...filteredTextData, ...filteredDescData];

      Object.assign([], filteredTreeData).forEach((ftd: any) => {
        let str = <string>ftd.code;
        while (str.lastIndexOf('.') > -1) {
          const index = str.lastIndexOf('.');
          str = str.substring(0, index);
          if (filteredTreeData.findIndex((t: any) => t.code === str) === -1) {
            const obj = this.treeData.find((d) => d.code === str);
            if (obj) {
              filteredTreeData.push(obj);
            }
          }
        }
      });
    } else {
      filteredTreeData = this.treeData;
    }

    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    // file node as children.
    const data = this.buildFileTree(filteredTreeData, '0');
    this.dataChange.next(data);
  }
}
