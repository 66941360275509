<h4 mat-dialog-container>
  <mat-toolbar color="primary" role="toolbar" id="custom-toolbar">
    <span> List Name</span>
    <span class="flex-auto"></span>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-list-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</h4>

<mat-dialog-content class="mt-4">
  <div class="px-20">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Enter List Name</mat-label>
      <input
        matInput
        placeholder="List Name"
        (keyup.enter)="onEnterClick()"
        [(ngModel)]="data.identifierListName"
      />
    </mat-form-field>
  </div>

  <div class="grid grid-cols-3 gap-3">
    <app-spinner [state]="spinnerStatus"></app-spinner>
    <div class="col-start-2 text-center mt-2">
      <button
        type="submit"
        class="btn-theme-primary"
        (click)="onEnterClick()"
        [disabled]="clicked"
      >
        Save
      </button>
    </div>
  </div>
</mat-dialog-content>
