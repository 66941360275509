import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QdodService } from 'src/app/common/services/qdod.service';
import { MixpanelService } from 'src/app/common/services/mixpanel.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: [':disabled { opacity: 0.5; }'],
})
export class DashboardComponent implements OnInit {
  constructor(
    private reportService: QdodService,
    private mixpanelService: MixpanelService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}
  stepperFlag = false;
  initiateFlag = true;
  instrumentListFlag = false;
  instrumentFlag: boolean = environment.enableInstrumentsFlow;
  userToken: number;
  fieldsPopulated = false;
  ngOnInit(): void {
    this.reportService.currentFilteredGQL_Data.subscribe((data: any) => {
      this.fieldsPopulated = data.length > 0;
    });
    this.reportService.currentReportState.subscribe((state: any) => {
      if (typeof state === 'boolean') {
        this.stepperFlag = state;
        if (this.stepperFlag) {
          this.initiateFlag = false;
        } else if (!this.stepperFlag) {
          this.initiateFlag = true;
        }
      } else {
        this.stepperFlag = false;
        this.initiateFlag = true;
      }
    });

    // Only run if the route has stepNum given.
    if (this.route.snapshot.paramMap.get('stepNum')) {
      const stepNum = parseInt(
        this.route.snapshot.paramMap.get('stepNum') ?? '1',
      );

      // Allow only valid stemNum(s).
      if (!isNaN(stepNum) && stepNum > 0) {
        // Go to the stepNum in the wizard.
        this.toggleReportpage();
      }
    }
  }

  toggleReportpage() {
    this.stepperFlag = !this.stepperFlag;
    this.reportService.changeReportState(this.stepperFlag);
    this.mixpanelService.onButtonClick('new report creation');
  }

  toggleInstrumentList() {
    this.initiateFlag = false;
    this.instrumentListFlag = !this.instrumentListFlag;
    this.reportService.changeInstrumentListState(this.instrumentListFlag);
    this.router.navigateByUrl('/list');
    this.mixpanelService.onButtonClick('view Instrument List');
  }
}
