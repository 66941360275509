import { DateTime } from 'luxon';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { QdodService } from 'src/app/common/services/qdod.service';
import { FormControl } from '@angular/forms';
import { MixpanelService } from 'src/app/common/services/mixpanel.service';
import {
  MAT_LUXON_DATE_FORMATS,
  LuxonDateAdapter,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
} from '@angular/material-luxon-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
@Component({
  selector: 'app-single-date',
  templateUrl: './single-date.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
    { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})
export class SingleDateComponent implements OnInit {
  selectedDate = new FormControl(DateTime.now());
  @Output() messageToParent = new EventEmitter<string>();
  @Input() childField: any;

  constructor(
    private reportService: QdodService,
    private mixpanelService: MixpanelService,
  ) {}

  ngOnInit(): void {
    this.sendDateToParent(
      this.selectedDate.value,
      this.selectedDate.value,
      this.childField,
    );
    this.reportService.changeStartDateSelected(this.selectedDate.value);
    this.reportService.changeEndDateSelected(this.selectedDate.value);
  }
  sendDateToParent(start: any, end: any, type: any) {
    this.messageToParent.emit(
      JSON.stringify({ startDate: start, endDate: end, radioState: type }),
    );
  }

  changeDate(event: any) {
    this.selectedDate.setValue(event.value);
    this.sendDateToParent(
      this.selectedDate.value,
      this.selectedDate.value,
      this.childField,
    );
    this.mixpanelService.trackDatePicked(
      this.reportService.formatDate(event.value),
      'start_date',
    );
    this.mixpanelService.trackDatePicked(
      this.reportService.formatDate(event.value),
      'end_date',
    );
  }
}
