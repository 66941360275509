import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MixpanelService } from 'src/app/common/services/mixpanel.service';
@Component({
  selector: 'app-stepper-screen',
  templateUrl: './stepper-screen.component.html',
  styleUrls: ['./stepper-screen.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class StepperScreenComponent implements OnInit {
  reportFlag = false;
  isLinear = false;
  screenNo: number;
  screenName: string;

  @ViewChild('stepper') private stepper: MatStepper;
  @ViewChildren(MatStep) private matSteps: QueryList<MatStep>;

  constructor(
    private mixpanelService: MixpanelService,
    private route: ActivatedRoute,
  ) {}
  ngOnInit(): void {}

  ngAfterViewInit() {
    // Only run if the route has stepNum.
    if (this.route.snapshot.paramMap.get('stepNum')) {
      const stepNum = parseInt(
        this.route.snapshot.paramMap.get('stepNum') ?? '1',
      );

      // Allow only valid stemNum(s).
      if (!isNaN(stepNum) && stepNum > 0 && stepNum <= this.matSteps.length) {
        this.screenNo = stepNum - 1; // 0 based index
        this.screenName = this.matSteps.toArray()[this.screenNo]?.label ?? '';
        this.goToStep();
      }
    }
  }

  // Go the the given step in the wizard.
  goToStep() {
    this.stepper.selectedIndex = this.screenNo;
    this.mixpanelService.changeStepperScreen(this.screenNo, this.screenName);
  }

  selectionChange(event: any) {
    this.screenNo = event.selectedIndex;
    this.screenName = event.selectedStep.label;
    this.mixpanelService.changeStepperScreen(this.screenNo, this.screenName);
  }
}
