import { Component, OnInit } from '@angular/core';
import { QdodService } from 'src/app/common/services/qdod.service';
@Component({
  selector: 'app-corporate-actions-dividends',
  templateUrl: './corporate-actions-dividends.component.html',
  styles: [
    `
      :host ::ng-deep .mdc-checkbox__background {
        width: 16px !important;
        height: 16px !important;
      }
      :host ::ng-deep .mat-mdc-checkbox label {
        padding-left: 0 !important;
      }
    `,
  ],
})
export class CorporateActionsDividendsComponent implements OnInit {
  corporateDividendRadio: any[] = [
    {
      key: 'Enable ExDate/Effective Date for Dividend & Corporate Actions Events',
      value: 'effectiveDate',
    },
    {
      key: 'Enable Declared/Announcement Date for Dividend & Corporate Actions Events',
      value: 'announcementDate',
    },
  ];
  radioSecondSelected = 'announcementDate';
  isRadioDisabled = false;
  constructor(public reportService: QdodService) {}

  ngOnInit(): void {
    this.radioSecondSelected = 'announcementDate';
    this.reportService.changeRadioCorporateDividendSelected(
      this.radioSecondSelected
    );

    this.reportService.currentFullUniverseFlag.subscribe((state: any) => {
      this.isRadioDisabled = false;
    });
  }
  onRadioChange(event: any) {
    this.radioSecondSelected = event.value;
    this.reportService.changeRadioCorporateDividendSelected(event.value);
  }

  getFullUniverseValue(status: any) {
    this.isRadioDisabled = false;
  }
}
