import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MunicipalGsacPricingService {
  constructor() {}

  commonFields = `
        MIC
        LocalCode
        ISIN
        Currency
        PriceDate
        Open
        High
        Low
        TWAP
        CumulativeAdjustmentFactor
        PreviousClose
        Close
        Mid
        Ask
        Last
        LastMarket
        LastSize
        LastTimestamp
        QuoteTimestamp
        Bid
        Bidsize
        BidMarket
        Asksize
        AskMarket
        BidModifiedDuration
        BidConvexity
        AccruedInterest        
        BidYTW
        MidYTW
        AskYTW
        LiquidityScore
        NumberOfTrades
        TradedVolume
        VWAP
        TradingStatus
        Change
        ChangePercentage
        Feed
        IsDelayed
        DelayedInterval
        SecurityID
        MktCloseDate
        VolFlag
        IssuerName
        SecurityType
        SecurityTypeDescription
        SEDOL
        CUSIP
        PrimaryExchange
        Exchange
        TradedValue
        TotalTrades
        CloseType
        YieldOfMaturity
        Type
        ProductType
        TypeCode
        BondMnemonic
        BondType
        CloseDate
        Broker
        Note
        AssetClass
        AdjustedPrice
        FXConvertedClosePrice
        FXConvertedCurrency
        FXConvertedRate
        FXConvertedPrice
  `;
  // The following fields are only available for the Municipal Pricing service
  municipalFields = `
    PriceToDateBid
    PriceToBid
    PriceToDateMid
    PriceToMid
    PriceToDateAsk
    PriceToAsk
    EvaluatedBidAskPriceSpread
    EvaluatedBidAskYieldSpread
    TradeData
    LastTradeDate
  `;
  // The following fields are only available for the GSAC Pricing service
  gsacFields = `
    BidAskPriceSpread
    DirtyBidPrice
    DirtyMidPrice
    DirtyAskPrice
    BidYTM
    MidYTM
    AskYTM
    BidAskYieldSpread
    YTWDate
    BidSTW
    BidDiscountMargin
    MidDiscountMargin
    AskDiscountMargin
    BidMacaulayDuration
    MidMacaulayDuration
    AskMacaulayDuration
    MidModifiedDuration
    AskModifiedDuration
    MidConvexity
    AskConvexity
    BidOAS
    MidOAS
    AskOAS
    BidEffectiveDuration
    MidEffectiveDuration
    AskEffectiveDuration
    BidEffectiveConvexity
    MidEffectiveConvexity
    AskEffectiveConvexity
    BondAgencyData
    QuoteData
    TraceData
    InterdealerBrokerData
    LiquidityASOF
  `;

  filterFields(priceData: string[]) {
    // Convert the multiline strings into arrays
    const commonFieldsArray = this.commonFields.trim().split(/\s+/);
    const municipalFieldsArray = this.municipalFields.trim().split(/\s+/);
    const gsacFieldsArray = this.gsacFields.trim().split(/\s+/);

    // Initialize the result arrays
    const municipalFieldsSelected: string[] = [];
    const gsacFieldsSelected: string[] = [];
    const pricingFields: string[] = [];

    // Iterate through the priceData array and categorize fields
    priceData.forEach((field) => {
      if (commonFieldsArray.includes(field)) {
        // If it's a common field, include in all arrays
        municipalFieldsSelected.push(field);
        gsacFieldsSelected.push(field);
        pricingFields.push(field);
      } else if (municipalFieldsArray.includes(field)) {
        // If it's only a municipal field, include in municipalFieldsSelected
        municipalFieldsSelected.push(field);
      } else if (gsacFieldsArray.includes(field)) {
        // If it's only a gsac field, include in gsacFieldsSelected
        gsacFieldsSelected.push(field);
      } else {
        // If it doesn't belong to any category, add to pricingFields
        pricingFields.push(field);
      }
    });

    return {
      municipalFieldsSelected,
      gsacFieldsSelected,
      pricingFields,
    };
  }
}
