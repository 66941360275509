export const CorporateActionBase = `
fragment commonFields on CorporateActionBase {
  requestedIdentifier
  CUSIP
  ISIN
  SEDOL
  LocalCode
  EventCode
  MandatoryVoluntaryFlag
  Status
  IssuerName
  SecurityDescription
  SecurityType
  ExchangeCode
  ListingCountryCode
  ListingStatus
  BloombergCompositeGlobalID
  BloombergCompositeTicker
  BloombergFIGI
  BloombergExchangeLevelTicker
  MarketIdentifierCode
  MIC
  LotSizeMinimum
  ListingDate
  DelistingDate
  SharesHeldType
  RatioOld
  RatioNew
  EffectiveDate
  AnnouncementDate
  EventChangeDate
  PayDate
  PaymentCurrency
  PaymentType
  ActionDescription
  Detail
  PeriodDate
  ExDate
  RecordDate
  EventActionCode
  EventID
}
`;

const OutTurnFields = `OldCUSIP
NewCUSIP
OldISIN
NewISIN
OldLocalCode
NewLocalCode
OldSEDOL
NewSEDOL
NewSecurityType
OldSecurityType
`;

export const CorporateActionsEvents: any = {
  IntlCodeChange: `
OldISIN
NewISIN
OldUSCode
NewUSCode
`,
  PrimaryExchangeChange: `

  MinimumPriceAmount
MaximumPriceAmount
OldExchangeCode
NewExchangeCode
`,
  IssuerNameChange: `

OldIssuerName
NewIssuerName
LegalName
NameChangeDate
${OutTurnFields}
`,
  Takeover: `

CloseDate
UnconditionalDate
CompulsoryAcquisitionDate
MinimumPriceAmount
MaximumPriceAmount
PreOfferQuantity
TargetQuantity
TargetPercent
OfferorIssID
OfferorName
HostileIndicator
PreOfferPercent
TakeoverStatus
PreOfferPercent
${OutTurnFields}

`,
  GeneralMeeting: `
MeetingNumber
        MeetingTime
        Address
        City
        CountryCode
        MeetingDate
`,
  Assimilation: `
    AssimilationDate
    ${OutTurnFields}
    `,
  BuyBack: `
        BuyBackMinPercent
        BuyBackMaxPercent
        OnOffFlag
        MinimumPriceAmount
        MaximumPriceAmount
        EndDate
        ${OutTurnFields}
    `,
  BBGCompositeChange: `
    OldBbgCompositeID
            NewBbgCompositeID
            OldBbgCompositeTicker
            NewBbgCompositeTicker
            OldCurrencyCode
            NewCurrencyCode
    `,
  BBGFigiChange: `
    OldFIGI
            NewBbgExchangeID
            OldBbgExchangeTicker
            NewBbgExchangeTicker
            OldCurrencyCode
            NewCurrencyCode
            OldExchangeCode
            NewExchangeCode
    `,
  Bonus: `
    LapsedPremium
    OldCUSIP
    NewCUSIP
    ${OutTurnFields}
    `,
  BonusRight: `
    SubExpiryTime
    OverSubscription
    StartTradeDate
    EndTradeDate
    SplitDate
    ${OutTurnFields}
    `,
  Call: `
    CallNumber
    ToFaceValue
    ToPremium
    ${OutTurnFields}
    `,
  Conversion: `
    PartFinal
    AmountConverted
    SettlementDate
    EffectiveFromDate
    ToDate
    Price
    ${OutTurnFields}
    `,
  CurrencyRedenom: `
    OldCurrencyCode
    NewCurrencyCode
    ParValueOld
    ParValueNew
    ${OutTurnFields}
    `,
  Dividend: `
    DividendTypeMarker
    Frequency
    TBAFlag
    NilDividend
    DivRescindCode
    DeclaredCurrencyCode
    DeclaredGrossAmount
    Approxflag
    DomesticWithholdingTaxRate
    Coupon
    CouponID
    DividendRate
    CurrenciesPairs
    DepositoryFees
    DepFeesCurrencyCode
    CashDividendCancelled
    StockDividendCancelled
    USDExchangeRateDivCurrency
    IsDivPostponed
    DeclarationDate
    PeriodEndDate
    ForeignExDate
    GrossDividend
    NetDividend
    ${OutTurnFields}
    `,
  DividendInvestmentFund: `
    DividendTypeMarker
            Frequency
            TBAFlag
            NilDividend
            DivRescindCode
            TaxRate
            Group2GrossDividend
            Group2NetDividend
            Equalisation
            FrankedPercent
            FrankedValue
            NonForeignDistrubutionUnFrankedPercent
            NonForeignDistrubutionUnFrankedValue
            UnFrankedForeignDistrubutionPercent
            UnFrankedForeignDistrubutionValue
            Group1GrossDividend
            DeclarationDate
            PeriodEndDate
    `,
  Franking: `
    Frequency
            TBAFlag
            NilDividend
            DivRescindCode
            DeclaredCurrencyCode
            DeclaredGrossAmount
            IsDivPostponed
            FrankedDividendAmount
            UnFrankedDividendAmount
            DeclarationDate
            PeriodEndDate
            ForeignExDate
    `,
  DividendReclassification: `
    DividendAllocableTaxYear
            DeclarationDate
            PeriodEndDate
            TaxYearEndDate
            GrossDividend
            NetDividend
    `,
  Bankruptcy: `
    EmergenceDate
    FilingDate
    ${OutTurnFields}
    `,
  SedolChange: `
    OldSEDOL
            NewSEDOL
            OldCountryCode
            NewCountryCode
            OldRegisteredCountry
            NewRegisteredCountry
            OldTradingCurrency
            NewTradingCurrency
    `,
  IncorporationChange: `
    OldCountryCode
    NewCountryCode
    ${OutTurnFields}
    `,
  LocalCodeChange: `
    OldLocalCode
            NewLocalCode
    `,
  Liquidation: `
    Liquidator
    LiquidatorAddress
    LiquidatorCity
    LiquidatorCountryCode
    MinimumPriceAmount
    MaximumPriceAmount
    ${OutTurnFields}
    `,
  ListStatusChange: `
    OldListingStatus
            NewListingStatus
            RelatedEvent
            RelatedEventDescription
    `,
  LotChange: `
    OldLotSize
    NewLotSize
    OldMinimumTradingQuantity
    NewMinimumTradingQuantity
    ${OutTurnFields}
    `,
  DepositoryChange: `
    OldDepositoryRatio
    NewDepositoryRatio
    OldUnderlyingRatio
    NewUnderlyingRatio
    OldDepositoryBank
    NewDepositoryBank
    OldDepositorySectype
    NewDepositorySectype
    ${OutTurnFields}
    `,
  Arrangement: `
    MinimumPriceAmount
    MaximumPriceAmount
    ${OutTurnFields}
    `,
  Merger: `
    MergerStatus
    MinimumPriceAmount
    MaximumPriceAmount
    AppointedDate
    ${OutTurnFields}
    `,
  CapitalReduction: `
    ParValueOld
    ParValueNew
    ${OutTurnFields}
    `,
  Consolidation: `
    ParValueOld
    ParValueNew
    PaymentCurrency
    PaymentType
    ${OutTurnFields}
    `,
  Divestment: `
    TradingSecID
    TradingISIN
    ${OutTurnFields}
    `,
  Entitlement: `
      PaymentCurrency
      PaymentType
      EntitlementIssuePrice
      StartSubscriptionDate
      EndSubscriptionDate
      ExpiryTime
      ExpiryTimezone
      OverSubscription
      ${OutTurnFields}
    `,
  MutualFundConv: `
      OldUnitTrustType
      NewUnitTrustType
      ${OutTurnFields}
    `,
  MarketSegementChange: `
    OldMarketSegment
    NewMarketSegment
    ${OutTurnFields}
    `,
  OddLotOffer: `
    BuyInCurrencyCode
    BuyInPrice
    BuyInFlag
    `,
  NewListing: ``,

  Announcement: `
    RelatedEvent
    RelatedEventDescription
    `,
  RecordDate: `
  RecordDate
  `,

  SharesOutstandingChange: `
    OldSOS
    OldOutstandingDate
    NewSOS
    NewOutstandingDate
    `,
  FinancialYearChange: `
    OldFinancialYearStartDate
    OldFinancialYearEndDate
    NewFinancialYearStartDate
    NewFinancialYearEndDate
    `,
  ReturnOfCapital: `
    PaymentCurrency
    FinancialYearEndDate
    PaymentType
    PayDate
    Cashback
    `,
  SecurityNameChange: `
    OldSecurityName
    NewSecurityName
    OldSecurityCode
    NewSecurityCode
    OldRegS144A
    NewRegS144A
    ${OutTurnFields}
    `,
  SubDivision: `
    OldParValue
    NewParValue
    OldParValueCurrencyCode
    NewParValueCurrencyCode
    ${OutTurnFields}
    `,
  Rights: `
  StartTradeDate
  EndTradeDate
  SplitDate
  IssuePrice
  SubExpiryTime
  OverSubscription
  LapsedPremium
  OldDistibutionRatio
  NewDistibutionRatio
  OldExercisedRatio
  NewExercisedRatio
  TradingSecurityID
  TradingISIN
  PoisonPillSecurityID
  PoisonPillISIN
  ${OutTurnFields}
  `,
  WarrantExercise: `
  ExDate
  RecordDate
  PayDate
  FromDate
  ToDate
  ExpirationDate
  PaymentCurrency
  StrikePrice
  PricePerShare
  RatioNew
  RatioOld
  FractionCode
  ExerciseStyle
  ${OutTurnFields}
    `,
  SecurityReclassification: `
  PaymentType
  RatioNew
  RatioOld
  ${OutTurnFields}
  `,

  DividendReinvestmentPlan: `
    DeclarationDate
    PeriodEndDate
    DripLastDate
    DripPayDate
    CrestSettlementDate
    ReInvestmentPrice
    DividendTypeMarker
    Frequency
    TBAFlag
    NilDividend
    DivRescindCode
    DeclaredCurrencyCode
    DeclaredGrossAmount
    StampDuty
    StampAsPercent
    CommissionFee
    CommissionFeePercent
    IsDivPostponed
    ${OutTurnFields}
    `,
  PropertyIncomeDistribution: `
    DeclaredGrossAmount
    IsDivPostponed
    `,
  PurchaseOffer: `
    MinimumPriceAmount
    MaximumPriceAmount
    NegotiatedPrice
    SealedBid
    MinPercent
    MaxPercent
    `,
  PreferentialOffer: `
    MinimumPriceAmount
    MaximumPriceAmount
    OffereeIssuerID
    OffereeName
    ${OutTurnFields}
    `,
  ParValueRedenomination: `
    ParValueOld
    ParValueNew
    ${OutTurnFields}
    `,
  Redemption: `
    PayDate
    RedemptionPrice
    RedemptionPremium
    PartFinal
    AmountRedeemed
    RedemptionPercent
    Poolfactor
    PriceAsPercent
    TenderOfferor
    `,
  SecuritySwap: `
  IssuePrice
  ${OutTurnFields}
    `,
  Distribution: `
    MinimumPriceAmount
    MaximumPriceAmount
    EventID
    EventActionCode
    ExDate
    PayDate
    PeriodDate
    RecordDate
    ${OutTurnFields}
    `,
  Demerger: `
    MinimumPriceAmount
    MaximumPriceAmount
    EventID
    EventActionCode
    ExDate
    PayDate
    PeriodDate
    RecordDate
    ${OutTurnFields}
    `,
  FinancialTransactionTax: `
    StartDate
    EndDate
    EventID
    EventActionCode
    ExDate
    PayDate
    PeriodDate
    RecordDate
    `,
  ClassAction: `
    StartDate
        EndDate
        FilingDate
        LeadPlaintiffDeadlineDate
        ProofFilingDeadlineDate
        ClaimsFilingDeadlineDate
        ObjectionDeadlineDate
        ExclusionDeadlineDate
        CourtId
        CaseNumber
        Judge
        CaseName
        Allegations
        EligibleSecurities
        InvestorClass
        OnBehalfOf
        LeadPlaintiff
        CaseStatus
    `,
  CertificateExchange: `
      StartDate
      EndDate
      PaymentType
      RatioOld
      RatioNew
      ${OutTurnFields}
    `,
};
