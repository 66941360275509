import { DateTime } from 'luxon';
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { QdodService } from 'src/app/common/services/qdod.service';
import { CorporateActionsDividendsComponent } from '../corporate-actions-dividends/corporate-actions-dividends.component';

@Component({
  selector: 'app-query-type',
  templateUrl: './query-type.component.html',
  styleUrls: ['./query-type.component.css'],
})
export class QueryTypeComponent implements OnInit {
  @Output() isCorporateDividend = new EventEmitter<boolean>();
  @ViewChild(CorporateActionsDividendsComponent)
  child: CorporateActionsDividendsComponent;

  queryTypeChoices: any[] = [
    { key: 'Single Date', value: 'singleDate' },
    { key: 'Date Range', value: 'dateRange' },
    { key: 'Corporate Action / Dividends', value: 'corporateDividends' },
  ];
  radioSelected = 'singleDate';
  receivedFullUniverseState: boolean;
  dateObject: any;

  constructor(
    private reportService: QdodService,
    private cdr: ChangeDetectorRef,
  ) {}
  passStatus() {
    this.isCorporateDividend.emit(
      this.radioSelected === 'corporateDividends' ? true : false,
    );
  }
  parentFunction(event: any) {
    this.dateObject = JSON.parse(event);
    this.reportService.changeStartDateSelected(
      DateTime.fromISO(this.dateObject.startDate, { zone: 'utc' }),
    );
    this.reportService.changeEndDateSelected(
      DateTime.fromISO(this.dateObject.endDate, { zone: 'utc' }),
    );
  }

  onRadioChange(event: any) {
    this.radioSelected = event.value;
    this.reportService.changeRadioSelected(event.value);
    this.passStatus();
    if (
      this.radioSelected === 'singleDate' ||
      this.radioSelected === 'dateRange'
    ) {
      this.reportService.changeFullUniverseFlag(false);
    } else {
      this.reportService.changeFullUniverseFlag(this.receivedFullUniverseState);
    }
  }

  UpdateFullUniverseState(status: any) {
    this.receivedFullUniverseState = status;
    this.reportService.changeFullUniverseFlag(status);
    this.child.getFullUniverseValue(status);
  }

  ngOnInit(): void {
    this.reportService.changeRadioSelected(this.radioSelected);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
}
