<div *ngIf="!stepperFlag && !instrumentListFlag" class="px-8 pb-4">
  <div class="pt-20">
    <div class="flex flex-row-reverse">
      <div>
        <button [disabled]="!fieldsPopulated" type="button" class="btn-theme-primary" (click)="toggleReportpage()">
          Create New Report
        </button>
      </div>
      <div *ngIf="instrumentFlag" class="text-center mr-2">
        <button class="btn-theme-primary" (click)="toggleInstrumentList()">
          Manage Identifier list
        </button>
      </div>
    </div>
  </div>
</div>

<app-reports-table *ngIf="initiateFlag"></app-reports-table>
<app-stepper-screen *ngIf="stepperFlag"></app-stepper-screen>