import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReportsTableComponent } from './components/reports-table/reports-table.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { DragDropFileUploadDirective } from './drag-drop-file-upload.directive';
import { StepperScreenComponent } from './components/stepper-screen/stepper-screen.component';
import { FileImportComponent } from './components/file-import/file-import.component';
import { PreviewReportComponent } from './components/preview-report/preview-report.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { SaveTemplateComponent } from './components/save-template/save-template.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { CdkTreeModule } from '@angular/cdk/tree';
import { TreeStructureComponent } from './components/tree-structure/tree-structure.component';
import { QueryTypeComponent } from './components/query-type/query-type.component';
import { SingleDateComponent } from './components/single-date/single-date.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ViewInstrumentListComponent } from './components/view-instrument-list/view-instrument-list.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ViewInstrumentsComponent } from './components/view-instruments/view-instruments.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ConfirmRunReportDialogComponent } from './components/confirm-run-report-dialog/confirm-run-report-dialog.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { CorporateActionsDividendsComponent } from './components/corporate-actions-dividends/corporate-actions-dividends.component';
import { SaveIdentifierListComponent } from './components/save-identifier-list/save-identifier-list.component';
import { DeleteReportDialogComponent } from './components/delete-report-dialog/delete-report-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AuthInterceptorService } from './common/services/auth-interceptor.service';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ReportsTableComponent,
    DragDropFileUploadDirective,
    StepperScreenComponent,
    FileImportComponent,
    PreviewReportComponent,
    SaveTemplateComponent,
    TreeStructureComponent,
    ViewInstrumentListComponent,
    ViewInstrumentsComponent,
    ConfirmDialogComponent,
    ConfirmRunReportDialogComponent,
    QueryTypeComponent,
    SingleDateComponent,
    DateRangeComponent,
    CorporateActionsDividendsComponent,
    SaveIdentifierListComponent,
    SpinnerComponent,
    DeleteReportDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatCardModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    MatTableModule,
    MatSelectModule,
    MatInputModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    CdkStepperModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatDialogModule,
    MatToolbarModule,
    CdkTreeModule,
    MatTreeModule,
    MatSlideToggleModule,
    ScrollingModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    DragDropModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
