export class TreeItemFlatNode {
  value: string;
  viewValue: string;
  level: number;
  expandable: boolean;
  code: string;
  desc: string;
  status: boolean;
  childNodes?: string[];
}
