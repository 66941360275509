import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-save-identifier-list',
  templateUrl: './save-identifier-list.component.html',
  styleUrls: ['./save-identifier-list.component.css'],
})
export class SaveIdentifierListComponent {
  constructor(
    public dialogRef: MatDialogRef<SaveIdentifierListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  spinnerStatus = false;
  clicked = false;
  onEnterClick() {
    this.clicked = true;
    this.dialogRef.close(this.data.identifierListName);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
