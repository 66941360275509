import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  QueryList,
  HostListener,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  Identifiers,
  IdentifiersColumn,
} from '../../common/interfaces/instrument-lists';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { InstrumentsCrudService } from '../../common/services/instruments-crud.service';
import { MatDialog } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

import { FormControl, Validators } from '@angular/forms';
import { MixpanelService } from 'src/app/common/services/mixpanel.service';

@Component({
  selector: 'app-view-instruments',
  templateUrl: './view-instruments.component.html',
  styleUrls: ['./view-instruments.component.css'],
})
export class ViewInstrumentsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = IdentifiersColumn.map((col) => col.key);
  instrumentId: string;
  dataSource = new MatTableDataSource<Identifiers>();
  columnsSchema: any = IdentifiersColumn;
  isLoading = true;
  valid: any = {};
  selection = new SelectionModel<Identifiers>(true, []);
  isRightFile: boolean;
  spinnerState = false;
  fileName: string;
  keys: any;
  data: any;
  fileUploaded: any = false;
  dataSheet = new Subject();
  items: any;
  addIdentifierToggle = false;
  myControls: FormControl[] = [];
  identifier = new FormControl('', [Validators.required]);
  listId: any;
  showInputField = false;
  selectedItemId: string;

  @ViewChildren('identifierRefs') identifierRefs: QueryList<ElementRef>;
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.triggerEditToggle();
  }
  paginatorArr: any = [];
  constructor(
    private instrumentService: InstrumentsCrudService,
    public dialog: MatDialog,
    public toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('listId')) {
      this.listId = this.route.snapshot.paramMap.get('listId');
      this.fetchInstrumensList();
    } else {
      this.router.navigate(['']);
    }
  }

  ngAfterViewChecked(): void {
    this.identifierRefs.changes.subscribe(() => {
      const htmlElements: any = document.getElementById(this.selectedItemId);
      if (htmlElements) {
        htmlElements.select();
      }
    });
  }

  fetchInstrumensList() {
    this.spinnerState = true;

    this.instrumentService.getInstrumentsListDetails(this.listId).subscribe({
      next: (res: any) => {
        if (res.identifierList) {
          const listData = res.identifierList;
          this.createFormControl(listData);
          this.paginatorArr = [50, 100, 500];
          this.dataSource.data = this.filterResponseData(res.identifierList);
          this.dataSource.paginator = this.paginator;
          this.selection.deselect(...this.selection.selected);
        }
        this.spinnerState = false;
      },
      error: (err) => {
        this.spinnerState = false;
      },
    });
  }

  createFormControl(data: any) {
    this.myControls = [];
    data.forEach((element: any, i: any) => {
      const formControl = this.getIdentifierFormControl();
      formControl.patchValue(element);
      this.myControls.push(formControl);
    });
  }

  checkboxToggle(event: any, element: any) {
    event ? this.selection.toggle(element) : null;
    element.isSelected = event.checked;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.listItem
    }`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  private getIdentifierFormControl(): FormControl {
    return new FormControl('', [Validators.required]);
  }

  filterDatasource(arr: any) {
    const filteredLists = arr.map((obj: any) => obj.identifier);
    return filteredLists;
  }

  filterResponseData(arr: any) {
    const filteredLists = arr.map((el: any) => {
      return { identifier: el };
    });
    return filteredLists;
  }

  triggerEditToggle() {
    this.dataSource.data.forEach((el: any) => {
      if (el.firstRow) {
        this.dataSource.data = this.dataSource.data.filter(
          (u: any) => !u.firstRow
        );
      } else {
        el.isEdit = false;
      }
    });
    this.addIdentifierToggle = false;
    this.fetchInstrumensList();
  }

  editRow(row: any, i: any) {
    // edit row
    if (this.myControls[i].hasError('required')) {
      this.myControls[i].markAsTouched();
    } else {
      if (row.firstRow) {
        row.firstRow = false;
      }
      const identifierList = this.filterDatasource(this.dataSource.data);
      this.updateInstrumentList(identifierList);
      row.isEdit = false;
      this.addIdentifierToggle = false;
    }
  }

  onEnterClick(el: any, i: any) {
    this.editRow(el, i);
  }
  handleEdit(el: Identifiers, i: any) {
    this.selectedItemId = el.identifier + i;
    el.isEdit = !el.isEdit;
  }
  handleCancel(el: any) {
    if (el.firstRow) {
      this.dataSource.data = this.dataSource.data.filter(
        (u: any) => !u.firstRow
      );
    } else {
      el.isEdit = !el.isEdit;
    }
    this.fetchInstrumensList();
    this.addIdentifierToggle = false;
  }

  addRow() {
    const newRow: any = {
      identifier: '',
      isEdit: true,
      isSelected: false,
      firstRow: true,
    };
    const formControl = this.getIdentifierFormControl();
    formControl.patchValue(newRow.identifier);
    this.myControls.unshift(formControl);
    this.dataSource.data = [newRow, ...this.dataSource.data];
    this.addIdentifierToggle = true;
    this.showInputField = true;
    this.selectedItemId = '0';
  }

  removeRow(index: any) {
    // Remove row
    this.dialog
      .open(ConfirmDialogComponent)
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.spinnerState = true;
          this.dataSource.data.splice(index, 1);
          const body = {
            listId: this.listId,
            identifierList: this.filterDatasource(this.dataSource.data),
          };
          this.instrumentService.updateInstrumentList(body).subscribe(() => {
            this.dataSource.data = [...this.dataSource.data];
            this.spinnerState = false;
          });
        }
      });
  }

  removeSelectedRows() {
    const identifierList = this.filterDatasource(
      this.dataSource.data.filter((u: any) => !this.selection.isSelected(u))
    );
    this.dialog
      .open(ConfirmDialogComponent)
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.updateInstrumentList(identifierList);
        }
      });
  }

  updateInstrumentList(listItems: any) {
    this.spinnerState = true;
    const body = {
      listId: this.listId,
      identifierList: listItems,
    };
    this.instrumentService.updateInstrumentList(body).subscribe(() => {
      this.fetchInstrumensList();
      this.selection.deselect(...this.selection.selected);
      this.spinnerState = false;
    });
  }

  disableSubmit(id: number) {
    if (this.valid[id]) {
      return Object.values(this.valid[id]).some((item) => item === false);
    }
    return false;
  }

  onFileDropped(Uploadedfile: any) {
    const files = Uploadedfile?.target?.files
      ? Uploadedfile?.target?.files
      : Uploadedfile;
    this.isRightFile = !!files[0].name.match(/(.xls|.xlsx|.csv)/);

    this.fileName = files[0].name;
    if (this.isRightFile) {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {
          type: 'binary',
          raw: true,
        });
        const wsname: string = wb.SheetNames[0];
        // wb.SheetNames[0] contains name of file
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        // ws contains data of file except headings
        this.data = XLSX.utils.sheet_to_json(ws, { raw: false });

        this.items = this.data
          .map((element: any) => element.identifier)
          .filter((identifier: any) => identifier);
        this.updateInstrumentList(this.items);
        const length = this.items.length;
        if (length === 0) {
          this.fileUploaded = false;
          this.toastr.error('The uploaded File is Empty');
        } else if (length !== 0) {
          this.fileUploaded = true;
          this.keys = Object.keys(this.data[0]);
          if (this.keys.includes('identifier') && length >= 1) {
            this.dataSource.data = this.data;
          } else if (!this.keys.includes('identifier')) {
            this.toastr.error(
              'The uploaded file should contain a list of Identifiers'
            );
          }
        }
      };
      reader.readAsBinaryString(files[0]);
      reader.onloadend = (e) => {
        if (this.data.length === 0) {
          this.dataSheet.next(null);
          this.keys = null;
        } else if (this.data.length !== 0) {
          this.keys = Object.keys(this.data[0]);
          this.dataSheet.next(this.data);
        }
      };
    } else {
      this.toastr.error('Only Excel or CSV files allowed');
      this.dataSheet.next(null);
      this.keys = null;
    }
    Uploadedfile.target.value = '';
  }

  downloadSampleFile(type: string) {
    this.mixpanelService.onDownloadEvent(type);
  }
}
