import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { QdodService } from './common/services/qdod.service';
import { MixpanelService } from './common/services/mixpanel.service';
import { environment } from '../environments/environment';
import { FetchGqlFieldsService } from './common/services/fetch-gql-fields.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private QdodService: QdodService,
    private mixpanelService: MixpanelService,
    private gqlService: FetchGqlFieldsService,
  ) {}

  token = '';
  DESC_DATA_QUERY = this.gqlService.DESC_DATA_QUERY;

  updateGqlObjects(arr: []) {
    const obj: any = {};

    arr.forEach((field: any) => {
      obj[field.name] = field.description;
    });
    return obj;
  }

  ngOnInit(): void {
    const token = getToken();
    if (token) {
      localStorage.setItem('token', token);
      this.QdodService.changeTokenValue(token);
    }

    this.route.queryParams.subscribe((params: any) => {
      if (params.token) {
        this.token = params.token;
        localStorage.setItem('token', this.token);
      }

      if (this.token) {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            token: null,
          },
          queryParamsHandling: 'merge',
        });
      }
      this.QdodService.changeTokenValue(localStorage.getItem('token'));
    });
    if (token) {
      this.mixpanelService.init(this.getUserId(token));
    }
    this.QdodService.getGqlFieldsDesc(this.DESC_DATA_QUERY).subscribe(
      (res: any) => {
        this.QdodService.changeFilteredGQL_Data(res.data.__schema.types);
      },
    );
  }

  getUserId(token: string) {
    return JSON.parse(window.atob(token.split('.')[1])).user_id;
  }
}

function getToken() {
  // attempt to retrieve token from hash
  const parsedHash = new URLSearchParams(window.location.hash.substring(1)); // substr the '#' out.
  let token = parsedHash.get('token');
  if (!token) {
    // attempt from query string:
    token = new URLSearchParams(window.location.search).get('token');
  }
  if (!token) {
    // attempt from local storage:
    token = localStorage.getItem('token');
  }
  if (!token) {
    // Auth doesn't like the trailing slash anymore.
    const href = removeTrailingSlash(window.location.href);
    // if no token, redirect to the login url. Pass the application url as redirectUri.
    window.location.href = `${environment.authRedirectUrl}?redirectUri=${href}`;
  }
  // remove the hash from the URL after the token is retrieved so that it is not
  // stored in browser history.
  // window.history.replaceState(null, '', ' ');
  return token;
}
function removeTrailingSlash(href: any) {
  if (href[href.length - 1] === '/') {
    return href.substring(0, href.length - 1);
  }
  return href;
}
