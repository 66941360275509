import { Injectable } from '@angular/core';
import { CorporateActionsEvents } from '../query/corporateEdi2020Query';
import { DescriptiveDataFields } from '../query/descriptiveDataQuery';
import { schedulesCategories } from '../query/schedules';
@Injectable({
  providedIn: 'root',
})
export class FetchGqlFieldsService {
  constructor() {}
  eventKeys = Object.keys(CorporateActionsEvents);
  DescriptiveDataKeys = Object.keys(DescriptiveDataFields);
  SchedulesDataKeys = Object.keys(schedulesCategories);
  DESC_DATA_QUERY = `
  
query IntrospectionQuery {
  __schema {
    queryType {
      name
    }
    mutationType {
      name
    }
    subscriptionType {
      name
    }
    types {
      ...FullType
    }
  }
}

    fragment FullType on __Type {
      kind
      name
      description
      
      fields(includeDeprecated: true) {
        name
        description
        type {
          ...TypeRef
        }
      }
    }

    fragment TypeRef on __Type {
      kind
      name
      ofType {
        kind
        name
        ofType {
          kind
          name
          ofType {
            kind
            name
            ofType {
              kind
              name
              ofType {
                kind
                name
                ofType {
                  kind
                  name
                  ofType {
                    kind
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  
  `;

  getTreeFieldsKeys() {
    let treeKeys = [];
    treeKeys = [
      'DebtDesciptionData',
      'PriceData',
      'dividendData',
      'EquityDescriptiveData',
      'TipsInformation',
      'MilRate',
      'PaydownHistory',
      'CollateralHistory',
      'PaydownSummary',
      'CorporateActionBase',
      'EquityFundamental',
      'CorporateBondFundamental',
      'SecuritizedBondFundamental',
      'MunicipalBondFundamental',
      'FuturesFundamental',
      'MutualFundFundamental',
      'OptionFundamental',
      'InternationalFundFundamental',
      ...this.eventKeys,
      'GICS',
      'SIC',
      ...this.SchedulesDataKeys,
      'BondVariableFloatRates',
      'ExchangeRate',
    ];
    return treeKeys;
  }
}
