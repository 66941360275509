<div class="!mt-4 px-8">
  <a routerLink="/list" class="btn-theme-primary" id="create-new-button">
    Back</a
  >
  <div class="grid grid-cols-2 pb-4 !mt-4">
    <div>
      <mat-form-field appearance="outline" class="w-full !text-sm">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" #input />
      </mat-form-field>
    </div>

    <div>
      <article class="text-right">
        <div class="action-btns">
          <button
            [disabled]="addIdentifierToggle"
            class="btn-theme-primary mr-2"
            (click)="addRow()"
          >
            Add Identifier
          </button>
          <button
            [disabled]="
              dataSource.data.length === 0 ||
              this.selection.selected.length === 0
            "
            class="btn-theme-success"
            (click)="removeSelectedRows()"
          >
            Remove Identifiers
          </button>
        </div>
      </article>
    </div>
  </div>

  <div>
    <div class="panel panel-primary">
      <div
        *ngIf="dataSource.data.length === 0"
        class="header-three panel-heading"
      >
        Identifiers
      </div>
      <div class="panel-body">
        <div>
          <ng-container *ngIf="dataSource.data.length > 0">
            <table
              mat-table
              [dataSource]="dataSource"
              class="!bg-white !w-full"
              aria-describedby="identifiersList"
            >
              <ng-container
                [matColumnDef]="col.key"
                *ngFor="let col of columnsSchema"
              >
                <div [ngSwitch]="col.type">
                  <ng-container *ngSwitchCase="'isSelected'">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="!bg-light-gray !text-blue-active w-3/12 !p-3"
                    >
                      <mat-checkbox
                        (change)="$event ? toggleAllRows() : null"
                        color="primary"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="
                          selection.hasValue() && !isAllSelected()
                        "
                        [aria-label]="checkboxLabel()"
                      >
                      </mat-checkbox>
                    </th>
                  </ng-container>
                  <span *ngSwitchDefault>
                    <th
                      mat-header-cell
                      class="header-three !bg-light-gray !text-blue-active w-3/12 !p-3"
                      [ngClass]="{
                        '!text-right !pr-14': col.type === 'isEdit'
                      }"
                      *matHeaderCellDef
                    >
                      {{ col.label }}
                    </th>
                  </span>
                </div>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  class="w-3/12 !p-3"
                >
                  <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
                    <ng-container *ngSwitchCase="'isSelected'">
                      <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="checkboxToggle($event, element)"
                        color="primary"
                        [checked]="selection.isSelected(element)"
                        [aria-label]="checkboxLabel(element)"
                      >
                      </mat-checkbox>
                    </ng-container>
                    <div class="text-right" *ngSwitchCase="'isEdit'">
                      <button mat-button (click)="handleEdit(element, i)">
                        Edit
                      </button>
                      <button
                        mat-button
                        class="button-remove"
                        (click)="removeRow(i)"
                      >
                        Delete
                      </button>
                    </div>

                    <span *ngSwitchDefault>
                      {{ element[col.key] }}
                    </span>
                  </div>
                  <div [ngSwitch]="col.type" *ngIf="element.isEdit">
                    <div *ngSwitchCase="'isSelected'"></div>
                    <div class="text-right" *ngSwitchCase="'isEdit'">
                      <button mat-button (click)="handleCancel(element)">
                        cancel
                      </button>
                      <button
                        mat-button
                        (click)="editRow(element, i)"
                        [disabled]="disableSubmit(element.id)"
                      >
                        Done
                      </button>
                    </div>

                    <mat-form-field
                      class="!w-full edit-field !text-sm"
                      *ngSwitchDefault
                    >
                      <input
                        #identifierRefs
                        [id]="element.identifier + i"
                        matInput
                        (keyup.enter)="onEnterClick(element, i)"
                        [required]="col.required"
                        [pattern]="col.pattern"
                        [type]="col.type"
                        [formControl]="myControls[i]"
                        [(ngModel)]="element[col.key]"
                      />
                      <mat-error *ngIf="myControls[i].invalid"
                        >Identifier name is required</mat-error
                      >
                    </mat-form-field>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </ng-container>
          <div appDragDropFileUpload (fileDropped)="onFileDropped($event)">
            <table
              *ngIf="dataSource.data.length === 0"
              class="!bg-white"
              aria-hidden="true"
            >
              <tbody>
                <tr
                  [ngClass]="{ 'p-4': dataSource.data.length === 0 }"
                  class="text-center"
                >
                  <td
                    [ngClass]="{ 'p-4 w-3/12': dataSource.data.length === 0 }"
                  >
                    <div class="border p-8">
                      <div class="font-medium text-center items-center p-7">
                        You can drag & drop your files here of file Type: .XLSX
                        or .CSV
                        <div>
                          View
                          <a
                            href="../../../assets/file_format/identifier_template .xlsx"
                            class="color-theme"
                            download="sample_file.xlsx"
                            (click)="downloadSampleFile('sample_file.xlsx')"
                            >sample_file.xlsx</a
                          >
                          <span
                            ><a
                              href="../../../assets/file_format/identifier_template .csv"
                              class="color-theme px-2"
                              download="sample_file.csv"
                              (click)="downloadSampleFile('sample_file.csv')"
                              >sample_file.csv</a
                            ></span
                          >
                        </div>
                      </div>
                      <div>
                        <input
                          #fileDropRef
                          id="fileDropRef"
                          multiple
                          #inputFile
                          type="file"
                          name="file-input"
                          id="file-input"
                          class="file-input__input text-white"
                          (change)="onFileDropped($event)"
                        />
                        <label class="file-input__label" for="file-input">
                          <img
                            id="import-button"
                            src="../../../assets/download 01.svg"
                            alt="import_logo"
                          />
                          <span class="text-white">Import File</span></label
                        >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <mat-paginator
            [pageSizeOptions]="paginatorArr"
            aria-label="Select page of Instrument List"
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
