import {
  schedulesCategories,
  trancheHistory,
} from 'src/app/common/query/schedules';
import { CorporateActionsEvents } from 'src/app/common/query/corporateEdi2020Query';
import { fundAllocations } from 'src/app/common/query/mutualFundData';
interface queryBuilderParameters {
  selectedFields: any;
}
const PREAMBLE = ` query GetCustomTemplateQuery($identifiers: [String!],$onDates: [String!],$betweenDates:BetweenDatesInput,$latestBy:String, $options: TemplateOptions){
  universalData(identifiers: $identifiers,
                betweenDates: $betweenDates,
                latestBy: $latestBy,
                onDates: $onDates,
                options: $options) {`;
const CLOSE = `
                }
}`;
const descriptiveFields = [
  'SecuritizedBondFundamental',
  'MunicipalBondFundamental',
  'CorporateBondFundamental',
  'FuturesFundamental',
  'MutualFundFundamental',
  'EquityFundamental',
  'OptionFundamental',
  'InternationalFundFundamental',
  'ETFFundFundamental',
];

export class QueryBuilder {
  constructor(private params: queryBuilderParameters) {}

  getFields(fieldName: string, variables: string = ''): string {
    return this.params.selectedFields[fieldName]?.length > 0
      ? `${fieldName} ${variables} {requestedIdentifier ${this.params.selectedFields[
          fieldName
        ]
          .toString()
          .replace(/,/g, ' ')} }`
      : '';
  }

  getDescriptiveDataFields(heading: string, query: any): any {
    if (!query.includes('fundamentalDescription')) {
      return this.params.selectedFields[heading]?.length > 0
        ? `fundamentalDescription {
        ... on  ${heading} {
          __typename
        requestedIdentifier ${this.params.selectedFields[heading]
          .toString()
          .replace(/,/g, ' ')} }`
        : '';
    } else {
      return this.params.selectedFields[heading]?.length > 0
        ? `
        ... on  ${heading} {
          __typename
        requestedIdentifier ${this.params.selectedFields[heading]
          .toString()
          .replace(/,/g, ' ')} }`
        : '';
    }
  }
  checkDescriptive(descriptiveDataFields: any) {
    return descriptiveDataFields.some(
      (field: any) => this.params.selectedFields[field]?.length > 0,
    );
  }

  getCorporateActionsFields(): string {
    // include selected corporateActions and its events
    const corporateActions =
      this.params.selectedFields.corporateActions?.length > 0
        ? `
        __typename
        requestedIdentifier
        ${this.params.selectedFields.corporateActions
          ?.toString()
          .replace(/,/g, ' ')}
    `
        : '';

    const Events = Object.keys(CorporateActionsEvents)
      .filter((event) => this.params.selectedFields[event]?.length > 0)
      .map(
        (event: string) =>
          ` ... on ${event} {
              __typename
              requestedIdentifier
             ${CorporateActionsEvents[event]}
             ${this.params.selectedFields.corporateActions
               ?.toString()
               .replace(/,/g, ' ')}
           }
           `,
      )
      .join('');

    if (!corporateActions && !Events) {
      return '';
    }

    if (Events && corporateActions) {
      return `
          corporateActions {
            ${Events}
          }
        `;
    }

    return `
        corporateActions {
          ${Events || corporateActions}
        }
      `;
  }

  getGicSicFields(): string {
    const gicsFields = this.params.selectedFields['gics'];
    const sicFields = this.params.selectedFields['sic'];

    if (gicsFields?.length > 0 || sicFields?.length > 0) {
      const gicsSection =
        gicsFields.length > 0
          ? `
        gics {
          requestedIdentifier
          ${gicsFields.join(' ')}
        }
      `
          : '';

      const sicSection =
        sicFields.length > 0
          ? `
        sic {
          requestedIdentifier
          ${sicFields.join(' ')}
        }
      `
          : '';

      return `
        industryClassification {
          ${sicSection}
          ${gicsSection}
        }
      `;
    } else {
      return '';
    }
  }

  getCustomFieldsSelection(
    fields: any,
    key: string,
    customKey: string,
    variables: string = '',
  ): string {
    let objInstances = Object.keys(fields)
      .filter((event) => this.params.selectedFields[event]?.length > 0)
      .map((event: string, index: number) => {
        if (index === 0) {
          return `${key} {
            ${event} {
                requestedIdentifier
               ${fields[event]}
             }
          `;
        } else {
          return `${event} {
            requestedIdentifier
            ${fields[event]}
          }`;
        }
      })
      .join('');

    if (customKey && this.params.selectedFields[customKey]?.length > 0) {
      if (!objInstances.includes(key)) {
        objInstances += `${key} { `;
      }
      objInstances += this.getFields(customKey, variables);
    }

    if (!objInstances) {
      return '';
    }

    return `${objInstances} }`;
  }

  getPricingDataFields(): string {
    const { priceData, municipalPricing, gsacPricing } =
      this.params.selectedFields;

    if (
      !priceData?.length &&
      !municipalPricing?.length &&
      !gsacPricing?.length
    ) {
      return '';
    }

    const priceFields = `
        priceData {
          requestedIdentifier
          ${priceData?.join(' ') || ''}      
      `;

    if (!municipalPricing && !gsacPricing) {
      return priceFields + '}';
    }

    const municipalFieldsSection = this.fieldsBuilder(
      municipalPricing,
      'MunicipalPricing',
    );
    const gsacFieldsSection = this.fieldsBuilder(
      gsacPricing,
      'CorporatePricing',
    );

    const extendedPricing = `
      ${municipalFieldsSection}
      ${gsacFieldsSection}
  `;

    return municipalFieldsSection || gsacFieldsSection
      ? `${priceFields} ${extendedPricing} }`
      : `${priceFields}}`;
  }

  fieldsBuilder(fields: string[] | undefined, typeName: string) {
    return fields?.length
      ? `
        ... on ${typeName} {          
          ${fields.join(' ')}
        }
      `
      : '';
  }

  getMutualFundDataFields(): string {
    const fundAllocationQuery = Object.keys(fundAllocations)
      .filter((event) => this.params.selectedFields[event]?.length > 0)
      .map(
        (event: string) =>
          `  ${event} {
            requestedIdentifier
           ${fundAllocations[event]}
         }
         `,
      )
      .join('');

    const formattedFundAllocations = fundAllocationQuery
      ? `
    fundAllocations {
     ${fundAllocationQuery}
      }
   `
      : '';

    const performance = this.getFields('fundPerformance');

    if (!formattedFundAllocations && !performance) {
      return '';
    }

    return `
      etfMutualFundData {
        ${formattedFundAllocations}
        ${performance}
      }
    `;
  }

  build() {
    let query = PREAMBLE;
    const isCustomFaceValueSelected =
      this.params.selectedFields.paydownHistory?.includes(
        'customFaceValue: userDefinedField(as: "customFaceValue")',
      );
    query += this.getPricingDataFields();
    query += this.getCorporateActionsFields();
    query += this.getCustomFieldsSelection(
      trancheHistory,
      'bondFactorAndCashFlowHistory',
      'paydownHistory',
      isCustomFaceValueSelected
        ? '(customFaceValueField: "customFaceValue")'
        : '',
    );
    query += this.getFields('tipsInformation');
    query += this.getFields('milData');
    query += this.getGicSicFields();
    query += this.getCustomFieldsSelection(
      schedulesCategories,
      'bondScheduleAndRedemption',
      '',
    );
    query += this.getFields('bondVariableFloatRates');
    query += this.getFields('exchangeRate');
    query += this.getMutualFundDataFields();
    query += this.getDescriptiveDataFields('SecuritizedBondFundamental', query);
    query += this.getDescriptiveDataFields('MunicipalBondFundamental', query);
    query += this.getDescriptiveDataFields('CorporateBondFundamental', query);
    query += this.getDescriptiveDataFields('FuturesFundamental', query);
    query += this.getDescriptiveDataFields('MutualFundFundamental', query);
    query += this.getDescriptiveDataFields('EquityFundamental', query);
    query += this.getDescriptiveDataFields('OptionFundamental', query);
    query += this.getDescriptiveDataFields(
      'InternationalFundFundamental',
      query,
    );
    query += this.getMutualFundDataFields();
    if (this.checkDescriptive(descriptiveFields)) {
      query += `}
    }
  }`;
    } else {
      query += CLOSE;
    }
    query += '';

    return query;
  }
}
