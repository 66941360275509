<div>
  <mat-card appearance="outlined" class="!p-8 !rounded-md !border-none">
    <div class="header-one text-blue-active">Preview your Report</div>
    <hr />

    <div class="grid grid-cols-2 gap-4 mt-2">
      <div>
        <mat-card appearance="outlined" class="p-4 shadow-none h-preview-card-height overflow-y-auto border">
          <div class="body-text-two blue-highlight">REPORT NAME:</div>
          <div class="header-two text-blue-active">{{ value }}</div>
          <div class="boy-text-two mt-4 blue-highlight">Selected Fields:</div>
          <div class="header-two text-blue-active break-all tracking-1">
            {{
            viewFieldselected.length > 0
            ? viewFieldselected.toString()
            : 'No fields selected'
            }}
          </div>
        </mat-card>
      </div>
      <div>
        <mat-card appearance="outlined" class="shadow-none h-preview-card-height overflow-y-auto border !p-8">
          <div class="body-text-two blue-highlight">Parameter Settings:</div>
          <ng-container *ngIf="!fullUniverseSelected">
            <div class="header-two text-blue-active">
              <span class="body-text-two font-gray mr-4">Uploaded File:</span>
              {{ fileName }}
            </div>
          </ng-container>
          <ng-container *ngIf="fullUniverseSelected">
            <div class="header-two text-blue-active">
              <span class="body-text-two font-gray mr-4">Uploaded File:</span>
              <span>Not Applicable</span>
            </div>
          </ng-container>
          <ng-container *ngIf="!fullUniverseSelected">
            <div class="header-two text-blue-active">
              <span class="body-text-two font-gray mr-4">Number of Identifiers:</span>{{ identifiersLength }}
            </div>
          </ng-container>
          <ng-container *ngIf="fullUniverseSelected">
            <div class="header-two text-blue-active">
              <span class="body-text-two font-gray mr-4">Number of Identifiers:</span><span>When Full Universe is
                selected no imported lists are included
                in the report</span>
            </div>
          </ng-container>
          <div class="header-two text-blue-active">
            <span class="body-text-two font-gray mr-4">Price Between Date Range:</span><span>{{ startDateSelected ?
              startDateSelected : endDateSelected }} -
              {{ endDateSelected }}</span>
          </div>
        </mat-card>
      </div>
    </div>
    <div class="grid grid-cols-2">
      <div>
        <mat-form-field appearance="fill" class="mt-4 w-full">
          <mat-label>Input Report Name</mat-label>
          <input matInput type="text" [(ngModel)]="value" class="bg-gray-form-field" />
          <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value = ''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="mt-4 text-right">
        <button type="button" matStepperPrevious class="mr-4 btn-theme-secondary">
          back
        </button>
        <button matStepperNext class="btn-theme-primary" (click)="toggleReportPage()" type="submit" [disabled]="flag">
          Save and Submit Report
        </button>
      </div>
    </div>
  </mat-card>
</div>