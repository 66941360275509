import { DateTime } from 'luxon';
import { Component, OnInit } from '@angular/core';
import { QdodService } from 'src/app/common/services/qdod.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmRunReportDialogComponent } from '../confirm-run-report-dialog/confirm-run-report-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MixpanelService } from 'src/app/common/services/mixpanel.service';
import { CorporateActionsEvents } from 'src/app/common/query/corporateEdi2020Query';
import { QueryBuilder } from './query-builder';
@Component({
  selector: 'app-preview-report',
  templateUrl: './preview-report.component.html',
  styleUrls: ['./preview-report.component.css'],
})
export class PreviewReportComponent implements OnInit {
  radioStatus: any;
  value = 'Sample Report Name';
  fieldsSelected: any = [];
  viewFieldselected: any = [];
  fileName = '';
  identifiersLength: number;
  startDateSelected: any;
  endDateSelected: any;
  disableSubmitFlag = true;
  allCustomValues: any;
  dateOption: any;
  flag = false;
  fullUniverseSelected = false;
  resBody: any = {
    betweenDates: null,
    latestBy: '1',
    onDates: null,
  };
  identifierList: any;
  corporateDividendSelected: any;
  eventObjKeys = Object.keys(CorporateActionsEvents);
  eventObj: typeof CorporateActionsEvents = CorporateActionsEvents;
  constructor(
    public dialog: MatDialog,
    private reportService: QdodService,
    public toastr: ToastrService,
    private mixpanelService: MixpanelService,
  ) {}

  previewReportFormatDate = (date: DateTime) => {
    let filterDate;
    if (date) {
      filterDate = date.toFormat('MM/dd/yyyy');
    }
    return filterDate;
  };

  ngOnInit(): void {
    this.flag = false;

    this.reportService.currentIdentifiersList.subscribe((list: any) => {
      this.identifierList = list;
    });

    this.reportService.currentFields_selected.subscribe(
      (selected_fields: any) => {
        this.fieldsSelected = selected_fields.nestedMap.map((field: any) => {
          return { parent: field.parent, value: field.value };
        });
        this.viewFieldselected = selected_fields.flatMap.map((field: any) => {
          return field.viewValue;
        });
      },
    );
    this.reportService.currentfileSelected.subscribe((fileName: any) => {
      this.fileName = fileName;
    });

    this.reportService.currentIdentifiersLength.subscribe((length: any) => {
      this.identifiersLength = Number(length);
      if (this.identifiersLength > 0 && !this.fullUniverseSelected) {
        this.disableSubmitFlag = false;
      }
    });

    this.reportService.currentStartDateSelected.subscribe((start: any) => {
      this.startDateSelected = this.previewReportFormatDate(start);
    });

    this.reportService.currentEndDateSelected.subscribe((end: any) => {
      this.endDateSelected = end
        ? this.previewReportFormatDate(end)
        : this.previewReportFormatDate(DateTime.now());
    });

    this.reportService.currentTotalGroups.subscribe((currentGroup: any) => {
      const gicsReplacements: any = {
        Industry: 'GICSIndustry',
        'Industry Code': 'GICSIndustryCode',
        'Sub Industry': 'GICSSubIndustry',
        'Sub Industry Code': 'GICSSubIndustryCode',
        Group: 'GICSGroup',
        'Group Code': 'GICSGroupCode',
        Sector: 'GICSSector',
        'Sector Code': 'GICSSectorCode',
      };
      const sicReplacements: any = {
        Code: 'SICCode',
        'Industry Major Class': 'SICIndustryMajorClass',
        'Industry Minor Class': 'SICIndustryMinorClass',
        'Industry Group': 'SICIndustryGroup',
        'Industry Sub Group': 'SICIndustrySubGroup',
      };

      this.allCustomValues = { ...currentGroup };
      this.allCustomValues.gics = this.replaceValues(
        this.allCustomValues.gics,
        gicsReplacements,
      );
      this.allCustomValues.sic = this.replaceValues(
        this.allCustomValues.sic,
        sicReplacements,
      );

      this.allCustomValues.paydownHistory =
        this.allCustomValues?.paydownHistory?.map((value: string) =>
          value === 'customFaceValue'
            ? 'customFaceValue: userDefinedField(as: "customFaceValue")'
            : value,
        );
    });

    this.reportService.currentFullUniverseFlag.subscribe((flag: any) => {
      this.fullUniverseSelected = flag;
    });

    this.reportService.currentRadioCorporateDividendSelected.subscribe(
      (state: any) => {
        this.corporateDividendSelected = state;
      },
    );

    this.reportService.currentRadioSelected.subscribe((radio: any) => {
      this.radioStatus = radio;
    });
  }

  replaceValues(
    data: string[],
    replacements: { [key: string]: string },
  ): string[] {
    return data?.map((value: string) => replacements[value] || value) || [];
  }

  getDateOptions() {
    if (this.radioStatus !== 'corporateDividends') {
      return null;
    }

    if (this.fullUniverseSelected) {
      return {
        FullUniverse: true,
        DividendsByExDate: this.corporateDividendSelected === 'effectiveDate',
        CorporateActionsByEffectiveDate:
          this.corporateDividendSelected === 'effectiveDate',
      };
    }

    if (this.corporateDividendSelected === 'effectiveDate') {
      return { DividendsByExDate: true, CorporateActionsByEffectiveDate: true };
    }

    if (this.corporateDividendSelected === 'announcementDate') {
      return {
        DividendsByExDate: false,
        CorporateActionsByEffectiveDate: false,
      };
    }

    return null;
  }

  setParameters() {
    this.startDateSelected = this.startDateSelected
      ? this.startDateSelected
      : this.endDateSelected;

    const luxonStartDate = DateTime.fromFormat(
      this.startDateSelected,
      'MM/dd/yyyy',
    );
    const luxonEndDate = DateTime.fromFormat(
      this.endDateSelected,
      'MM/dd/yyyy',
    );

    let isafter = luxonEndDate.startOf('day') > luxonStartDate.startOf('day');
    const issame = luxonEndDate.hasSame(luxonStartDate, 'day');

    this.dateOption = this.getDateOptions();
    isafter = issame ? issame : isafter;
    if (!isafter) {
      this.toastr.error('End date should be after start date');
      return;
    }
    const body: any = {
      identifierList: this.fullUniverseSelected ? [] : this.identifierList,
      onDates: null,
      betweenDates: {
        startDate: luxonStartDate.toFormat('yyyy-MM-dd'),
        endDate: luxonEndDate.toFormat('yyyy-MM-dd'),
      },
      latestBy: null,
      options: this.dateOption,
      customValues: this.allCustomValues,
    };

    this.flag = true;

    const queryBuilder = new QueryBuilder({
      selectedFields: this.allCustomValues,
    });
    const query = queryBuilder.build();

    this.getGqlResponse(body, query, this.fieldsSelected);
  }

  toggleReportPage() {
    const reportNameLength = this.value.trim().length;
    if (
      this.identifiersLength > 0 &&
      this.fieldsSelected.length !== 0 &&
      reportNameLength !== 0
    ) {
      this.confirmReportSubmission();
    } else if (
      this.identifiersLength === 0 &&
      this.fullUniverseSelected &&
      reportNameLength !== 0
    ) {
      this.confirmReportSubmission();
    } else if (this.fieldsSelected.length === 0) {
      this.toastr.error('No fields selected. Please select them');
    } else if (reportNameLength === 0) {
      this.toastr.error('Please fill the report name');
      this.value = this.value.trim();
    } else if (this.identifiersLength === 0) {
      this.toastr.error('Identifiers not imported');
    } else {
      this.toastr.error('Unsuccessful Submission');
    }
  }

  confirmReportSubmission() {
    this.dialog
      .open(ConfirmRunReportDialogComponent, {
        width: 'auto',
        height: 'auto',
        maxWidth: '800px',
      })
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.mixpanelService.onButtonClick('submit report confirmed');
          this.setParameters();
        } else {
          this.mixpanelService.onButtonClick('submit report cancelled');
        }
      });
  }

  getGqlResponse(body: any, query: string, selectedFieldsOrder: string[]) {
    const resObj: any = this.allCustomValues;
    const variables = {
      identifiers: body.identifierList,
      onDates: body?.onDates,
      betweenDates: body?.betweenDates ? body?.betweenDates : null,
      latestBy: body?.latestBy,
      options: {
        ...body?.options,
        ...(resObj?.priceData?.length > 0 && {
          ApplicableDateRange: ['priceData'],
          IdentifierResolutionMode: 'OnlyUSExchanges',
        }),
      },
    };

    this.reportService
      .gqlQuery(query, variables, this.value, selectedFieldsOrder)
      .subscribe((res: any) => {
        this.toastr.success('Successful Submission');
        this.reportService.removeChildRoute();
        this.reportService.changeReportState(false);
        this.reportService.changeIdentifierLength('');
        this.reportService.changeFileSelected(' ');
        this.reportService.changeStartDateSelected(DateTime.now());
        this.reportService.changeEndDateSelected(DateTime.now());
      });
  }
}
