<p class="body-text-two blue-highlight mt-4">Select Date:</p>
<mat-form-field appearance="outline" class="w-full">
  <input
    matInput
    [matDatepicker]="picker"
    placeholder="MM/DD/YYYY"
    [formControl]="selectedDate"
    (dateChange)="changeDate($event)"
  />
  <mat-datepicker-toggle
    matSuffix
    [for]="picker"
    class="mr-2"
  ></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
