import { Component, OnInit } from '@angular/core';
import { QdodService } from 'src/app/common/services/qdod.service';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { SaveTemplateComponent } from '../save-template/save-template.component';
import { MixpanelService } from 'src/app/common/services/mixpanel.service';
import { DeleteReportDialogComponent } from '../delete-report-dialog/delete-report-dialog.component';
import { DateTime } from 'luxon';
import { forkJoin } from 'rxjs';

export interface ReportElement {
  JobId: string;
  LastModified: string;
  generated_report: string;
  JobStatus: string;
  report_name: string;
  exceptions: string;
  actions: string;
}
interface Extensions {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-reports-table',
  templateUrl: './reports-table.component.html',
  styleUrls: ['./reports-table.component.css'],
})
export class ReportsTableComponent implements OnInit {
  displayedColumns: string[] = [
    'generated_report',
    'report_name',
    'exceptions',
    'actions',
    'deleteJob',
    'saveTemplate',
  ];
  dataSource: any = [];
  spinnerStatus = false;
  loadMoreFlag: boolean = this.dataSource.length >= 20;
  extensions: Extensions[] = [
    { value: 'csv', viewValue: 'csv' },
    { value: 'json', viewValue: 'json' },
    { value: 'xls', viewValue: 'xls' },
    { value: 'xml', viewValue: 'xml' },
  ];
  selectedFormat: any = 'csv';
  interval: any;
  intervalId: any;
  job_id: string;
  flag = false;
  lastJobId: any;
  count = 0;
  pageCount = 1;
  pageSize = 20;
  resultOrder = 1; // 1 for descending, 2 for ascending
  styleObject(status: string): object {
    if (status === 'completed') {
      return { color: '#42ab7b', backgroundColor: '#e6f5ef' };
    } else if (status === 'failed') {
      return { color: '#d64c29', backgroundColor: '#fbebe7' };
    } else if (status === 'in progress' || status === 'processing') {
      return { color: '#2385c1', backgroundColor: '#e7f2f8' };
    }
    return {};
  }

  openDeleteDialog(job_id: string): void {
    const dialogRef = this.dialog.open(DeleteReportDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      result && this.deleteReport(job_id);
    });
  }

  downloadResponseFile(element: any) {
    this.mixpanelService.onDownloadEvent('response file');
    let format: any = null;
    if (element.report_template_name === 'N/A') {
      this.toastr.error(`The file is Not Available`);
    } else if (element.report_template_name.includes('_piped')) {
      format = 'csv_piped';
    } else if (element.report_template_name.includes('.csv')) {
      format = 'csv';
    } else {
      format = 'json';
    }

    if (element[`${format}_report_key`]) {
      this.reportService
        .downloadFileApi(element.job_id, format)
        .subscribe((download_obj: any) => {
          if (download_obj.url) {
            window.open(download_obj.url);
          } else {
            this.toastr.error(`The ${format} file cannot be downloaded`);
          }
        });
    }
  }

  downloadExceptionsFile(element: any) {
    this.mixpanelService.onDownloadEvent('exceptions file');
    if (!element) {
      this.toastr.error('The file is Not Available');
    } else {
      this.reportService
        .exceptionsFileApi(element.job_id, element.exception_report_key)
        .subscribe((download_obj: any) => {
          if (download_obj.url) {
            window.open(download_obj.url);
          } else {
            this.toastr.error('The file cannot be downloaded');
          }
        });
    }
  }
  fileFormatSelection(event: any, element: any) {
    const format = event.value;
    this.mixpanelService.onListSelection('file-format-change', format);
    this.job_id = element.job_id;
    if (event.value === 'csv') {
      element.report_template_name = element.csv_report_key
        ? `${element.template_name}.${event.value}`
        : 'N/A';
    } else if (event.value == 'csv_piped') {
      element.report_template_name = element.csv_piped_report_key
        ? `${element.template_name}_piped.csv`
        : 'N/A';
    } else {
      element.report_template_name = element.json_report_key
        ? `${element.template_name}.${event.value}`
        : 'N/A';
    }
    this.selectedFormat = event.value;
  }

  deleteReport(job_id: string) {
    this.reportService.deleteReportApi(job_id).subscribe((res) => {
      if (res.data.status == 'Success') {
        this.toastr.success(res.data.message);
        this.handleBothJobsInit();
      } else if (res.data.status === 'Failed') {
        this.toastr.error(res.data.message);
      } else {
        this.toastr.error(res);
      }
    });
  }

  constructor(
    private reportService: QdodService,
    private mixpanelService: MixpanelService,
    public toastr: ToastrService,
    public dialog: MatDialog,
  ) {}

  getTime(timestamp: string) {
    return DateTime.fromISO(timestamp).toFormat('MM-dd-yyyy h:mm a');
  }

  getTimeFromMilliseconds(timestamp: number) {
    return DateTime.fromMillis(timestamp).toFormat('MM-dd-yyyy h:mm a');
  }

  getLastJobIndex() {
    const length = this.dataSource.length;
    this.lastJobId = this.dataSource[length - 1].job_id;
    return this.lastJobId;
  }
  openDialog(element: any) {
    this.mixpanelService.onButtonClick('save as template');
    this.dialog.open(SaveTemplateComponent, {
      panelClass: 'makeItMiddle',
      autoFocus: false,
      data: {
        dataElement: element,
      },
    });
  }

  loadNextJobs() {
    this.pageCount += 1;
    this.mixpanelService.onButtonClick('load-more-reports');
    forkJoin({
      nextJobsResponse: this.reportService.NextJobsList(this.getLastJobIndex()),
      moreListJobsResponse: this.reportService.loadMoreListJobsApi(
        this.pageCount,
        this.pageSize,
        this.resultOrder,
      ),
    }).subscribe({
      next: ({
        nextJobsResponse,
        moreListJobsResponse,
      }: {
        nextJobsResponse: any;
        moreListJobsResponse: any;
      }) => {
        const mergedJobs = this.mergeBothJobs(
          nextJobsResponse,
          moreListJobsResponse,
        );
        if (mergedJobs !== undefined) {
          mergedJobs.data.forEach((obj: any) => {
            obj.report_template_name = obj.csv_report_key
              ? `${obj.template_name}.csv`
              : 'N/A';
          });
          this.dataSource = [...this.dataSource, ...mergedJobs.data];
        }
      },
      error: (error) => {
        console.error('Error while loading next jobs:', error);
      },
    });
  }

  JobsPollingApi() {
    // Call both ResultListApi and any other API if needed and wait for both to complete
    forkJoin({
      resultListResponse: this.reportService.ResultListApi(),
      anotherApiResponse: this.reportService.listJobsApi(),
    }).subscribe({
      next: (responses: {
        resultListResponse: any;
        anotherApiResponse: any;
      }) => {
        let newObjArr: any = [];
        let flag = false;

        if (responses.resultListResponse !== undefined) {
          const mergedResponse = this.mergeBothJobs(
            responses.resultListResponse,
            responses.anotherApiResponse,
          );
          mergedResponse.data.forEach((obj: any) => {
            flag = false;
            obj.report_template_name = obj.csv_report_key
              ? `${obj.template_name}.csv`
              : 'N/A';

            this.dataSource.forEach((resObj: any, index: any) => {
              if (resObj.job_id === obj.job_id) {
                if (
                  resObj.job_status.toLocaleLowerCase() === 'in progress' &&
                  ['completed', 'failed'].includes(obj.job_status)
                ) {
                  this.dataSource[index] = obj;
                }
                if (
                  resObj.job_status.toLocaleLowerCase() === 'processing' &&
                  ['completed', 'failed'].includes(obj.job_status)
                ) {
                  //need to remove this in future once the naming is consistent on listjobs side
                  this.dataSource[index] = obj;
                }
                flag = true;
              }
            });

            if (!flag) newObjArr.push(obj);
          });

          this.dataSource = [...this.dataSource];
        }

        if (newObjArr.length > 0) {
          this.dataSource = [...newObjArr, ...this.dataSource];
          if (this.dataSource.length > 20) {
            this.dataSource = this.dataSource.slice(0, 20);
          }
          newObjArr = [];
        }
      },
      error: (error) => {
        console.error('Error polling job lists:', error);
      },
    });
  }

  updateCount() {
    this.count += 100;
    if (this.count === 500 && this.dataSource?.data?.length === 0) {
      this.spinnerStatus = true;
    }
  }

  checkApiTime() {
    this.intervalId = setInterval(() => this.updateCount(), 100);
    setTimeout(() => {
      clearInterval(this.intervalId);
    }, 500);
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();

    // Execute both getUpdatedJobsList and loadListJobs and wait for both to complete before updating dataSource
    this.handleBothJobsInit();

    this.interval = setInterval(() => {
      this.JobsPollingApi();
    }, 30000);
  }

  handleBothJobsInit() {
    this.checkApiTime();
    forkJoin({
      updatedJobs: this.reportService.ResultListApi(),
      listJobs: this.reportService.listJobsApi(),
    }).subscribe({
      next: ({
        updatedJobs,
        listJobs,
      }: {
        updatedJobs: any;
        listJobs: any;
      }) => {
        const mergedJobs = this.mergeBothJobs(updatedJobs, listJobs);
        if (mergedJobs) {
          this.dataSource = mergedJobs.data;
          this.loadMoreFlag = this.dataSource.length >= 20;

          this.dataSource.forEach((obj: any) => {
            obj.report_template_name = obj.csv_report_key
              ? `${obj.template_name}.csv`
              : 'N/A';
          });
          this.spinnerStatus = false;
        }
      },
      error: (error) => {
        console.error('Error loading job lists:', error);
      },
    });
  }

  mergeBothJobs(updatedJobs: any, listJobs: any) {
    const mergedJobs = updatedJobs.data.map((job: any) => {
      const matchingJob = listJobs.data.find(
        (listJob: any) => listJob.JobID === job.job_id,
      );

      if (matchingJob) {
        return {
          ...job,
          job_status: matchingJob.JobStatus, // Update job_status from listJobs
          template_name: decodeURIComponent(matchingJob.Name), // Decode Name from listJobs to template_name
          request_time: matchingJob.CreatedAt, // Convert CreatedAt to locale date string
        };
      }
      return job; // If no match is found, keep the original job data
    });

    return { data: mergedJobs };
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.pageCount = 1;
  }
}
