import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirm-run-report-dialog',
  templateUrl: './confirm-run-report-dialog.component.html',
})
export class ConfirmRunReportDialogComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
