import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import * as XLSX from 'xlsx';
import { Subject } from 'rxjs';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { QdodService } from 'src/app/common/services/qdod.service';
import { ToastrService } from 'ngx-toastr';
import { MixpanelService } from 'src/app/common/services/mixpanel.service';
import { MatDialog } from '@angular/material/dialog';
import { QueryTypeComponent } from '../query-type/query-type.component';
import { SaveIdentifierListComponent } from '../save-identifier-list/save-identifier-list.component';
import { InstrumentsCrudService } from '../../common/services/instruments-crud.service';

import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-file-import',
  templateUrl: './file-import.component.html',
  styleUrls: ['./file-import.component.css'],
})
export class FileImportComponent implements OnInit {
  @ViewChild(QueryTypeComponent) queryType: QueryTypeComponent;
  ifCorporateDividend = false;
  isFullUniverse: boolean;
  dataSource: any;
  isRightFile = false;
  isEndDate = false;
  data: any;
  fileName = '';
  items: any;
  keys: any;
  startDate = new FormControl(new Date());
  endDate = new FormControl(new Date());
  dataSheet = new Subject();
  fileUploaded: any = false;
  displayedColumns = ['identifier'];
  radioFlag: any;
  selectedIdentifer: string;
  identifierListName: string;
  listOfIdentifiersDropdown: any = [];
  isIdentifierFlow: boolean = environment.enableInstrumentsFlow;
  finalIdentifierList: any = [];
  customFaceValues: any = [];
  constructor(
    private reportService: QdodService,
    public toastr: ToastrService,
    private instrumentService: InstrumentsCrudService,
    public fb: FormBuilder,
    private mixpanelService: MixpanelService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.fileUploaded = false;
    this.isFullUniverse = false;
    if (environment.enableInstrumentsFlow) {
      this.fetchInstrumentLists();
    }
    this.reportService.changeFullUniverseFlag(this.isFullUniverse);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  fetchInstrumentLists() {
    this.instrumentService.getInstrumentsList().subscribe((res: any) => {
      this.listOfIdentifiersDropdown = res.listofLists;
    });
  }

  openDialog() {
    this.mixpanelService.onButtonClick('save as identifier list');
    const dialogRef = this.dialog.open(SaveIdentifierListComponent, {
      panelClass: 'makeItMiddle',
      width: '500px',
      autoFocus: false,
      data: {
        identifierListName: this.identifierListName,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      const body = {
        listName: result,
        identifierList: this.finalIdentifierList.map(
          (el: any) => el.securityId,
        ),
      };
      if (result) {
        this.instrumentService.addInstrumentList(body).subscribe((res: any) => {
          this.fileName = result;
        });

        this.identifierListName = result;
      }
    });
  }

  loadIdentifierList(res: any) {
    const identifierId = res.listId;
    const listname = res.listName;
    this.instrumentService
      .getInstrumentsListDetails(identifierId)
      .subscribe((res: any) => {
        this.items = res.identifierList;
        this.finalIdentifierList = this.items.map((securityId: any) => ({
          securityId,
        }));
        this.updateObservables(listname, res.identifierList);
      });
  }
  checkCheckBoxvalue(event: any) {
    this.queryType.UpdateFullUniverseState(event.checked);
    this.isFullUniverse = event.checked;
    this.reportService.changeFullUniverseFlag(event.checked);
  }
  changeCorporateDividend(event: any) {
    this.ifCorporateDividend = event;
  }
  clearIdentifiers() {
    this.selectedIdentifer = '';
    this.items = [];
    this.fileUploaded = false;
    this.fileName = '';
    this.reportService.changeFileSelected(' ');
    this.reportService.changeIdentifierLength('');
    this.customFaceValues = [];
    environment.enableInstrumentsFlow && this.fetchInstrumentLists();
    this.identifierListName = '';
  }

  downloadSampleFile(type: string) {
    this.mixpanelService.onDownloadEvent(type);
  }

  updateObservables(fileName: string, items: any) {
    this.fileName = fileName;
    this.fileUploaded = true;
    this.reportService.changeFileSelected(fileName);
    this.reportService.changeIdentifierLength(items.length);
    this.reportService.changeIdentifiersList(items);
  }

  onFileDropped(Uploadedfile: any) {
    const files = Uploadedfile?.target?.files
      ? Uploadedfile?.target?.files
      : Uploadedfile;
    this.isRightFile = !!files[0].name.match(/(.xls|.xlsx|.csv)/);
    this.fileName = files[0].name;
    if (this.isRightFile) {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {
          type: 'binary',
          raw: true,
        });
        const wsname: string = wb.SheetNames[0];
        // wb.SheetNames[0] contains name of file
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        // ws contains data of file except headings
        this.data = XLSX.utils.sheet_to_json(ws, { raw: false });
        this.setXLSData(this.data);
      };
      reader.readAsBinaryString(files[0]);
      reader.onloadend = (e) => {
        if (this.data.length === 0) {
          this.dataSheet.next(null);
          this.keys = null;
        } else if (this.data.length !== 0) {
          this.keys = Object.keys(this.data[0]);
          this.dataSheet.next(this.data);
        }
      };
    } else {
      this.toastr.error('Only Excel or CSV files allowed');
      this.reportService.changeFileSelected('No File Imported');
      this.dataSheet.next(null);
      this.keys = null;
    }
    Uploadedfile.target.value = '';
  }

  setXLSData(data: any) {
    const lowerCasedKeysArray = data.map((el: any) => {
      return Object.keys(el).reduce((newObject: any, key) => {
        newObject[key.toLowerCase()] = el[key];
        return newObject;
      }, {});
    });
    let isValidCustomFaceValues: boolean = false;
    this.finalIdentifierList =
      this.getFormattedIdentifierList(lowerCasedKeysArray);

    this.customFaceValues = lowerCasedKeysArray
      .map((el: any) => (el.customfacevalue ? el : null))
      .filter((el: any) => el);

    if (this.customFaceValues.length > 0) {
      isValidCustomFaceValues = this.customFaceValues.every(
        (obj: any) => !obj.customfacevalue.includes(','),
      );
    }

    this.items = this.getIdentifierList(lowerCasedKeysArray);

    const length = this.items.length;

    if (length === 0) {
      this.fileUploaded = false;
      this.toastr.error('The uploaded File is Empty');
    } else if (this.customFaceValues.length > 0 && !isValidCustomFaceValues) {
      this.toastr.error('Invalid Custom Face Value');
      this.fileUploaded = false;
    } else {
      this.keys = Object.keys(lowerCasedKeysArray[0]);
      if (this.keys.includes('identifier') && length >= 1) {
        this.updateObservables(this.fileName, this.items);
      } else if (!this.keys.includes('identifier')) {
        this.reportService.changeFileSelected('No file Imported');
        this.toastr.error(
          'The uploaded file should contain a list of Identifiers',
        );
      }
    }
  }

  getIdentifierList(data: any) {
    return data
      .map((element: any) => {
        if (
          element.identifier &&
          (element.customfacevalue || element.customfacevalue === 0)
        ) {
          return `${element.identifier}|{\"customFaceValue\":\"${element.customfacevalue}\"}`;
        } else {
          return element.identifier;
        }
      })
      .filter((identifier: any) => identifier);
  }

  getFormattedIdentifierList(data: any) {
    return data
      .map((item: any) => {
        if (item.customfacevalue && item.identifier) {
          return {
            securityId: item.identifier,
            customfacevalue: parseFloat(item.customfacevalue),
          };
        } else if (item.identifier && !item.customfacevalue) {
          return { securityId: item.identifier };
        }
        return null;
      })
      .filter((item: any) => item !== null);
  }
}
