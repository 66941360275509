import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FixedIncomeService } from './../../common/services/fixed-income.service';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { TreeStructureService } from '../../common/services/tree-structure.service';
import { TreeItemNode } from '../../common/interfaces/tree-item';
import { TreeItemFlatNode } from '../../common/interfaces/tree-item-flat';
import { QdodService } from 'src/app/common/services/qdod.service';
import { ToastrService } from 'ngx-toastr';
import { CustomDataService } from 'src/app/common/services/custom-data.service';
import { MixpanelService } from 'src/app/common/services/mixpanel.service';
import {
  CorporateActionsEvents,
  CorporateActionBase,
} from 'src/app/common/query/corporateEdi2020Query';
import { DescriptiveDataFields } from 'src/app/common/query/descriptiveDataQuery';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  schedulesCategories,
  trancheHistory,
} from 'src/app/common/query/schedules';
import { MunicipalGsacPricingService } from 'src/app/common/services/municipal-gsac-pricing.service';
import { mutualFundAllocations } from 'src/app/common/query/mutualFundData';
@Component({
  selector: 'app-tree-structure',
  templateUrl: './tree-structure.component.html',
  styleUrls: ['./tree-structure.component.css'],
  providers: [TreeStructureService],
})
export class TreeStructureComponent implements OnInit, OnDestroy {
  selected: any = 'PricingEquities';
  imgSrc = '../../../assets/default-search.svg';
  qpTemplateID: string = '';
  templateNames: any = [];
  newCustomObj: any = {
    priceData: [],
    dividends: [],
    corporateActions: [],
    milData: [],
  };
  finalArray: any = [];
  predefinedTemplates = this.customDataService.PredefinedGroups;

  finalParentFieldObject: any = {};
  descriptiveDataKeys = Object.keys(DescriptiveDataFields);

  parentNodes: any = [];
  parentCodeValues: any = {
    priceData: '0.1.1',
    corporateActions: '0.4.1',
    'Corporate Actions/Dividends - Events': '0.4.2.1',
    TrancheHistory: '0.6.1',
    paydownHistory: '0.6.2.1',
    tipsInformation: '0.7.1',
    milData: '0.8.1',
    EquityFundamental: '0.10.1.1',
    FixedIncome: '0.10.2.1',
    FuturesFundamental: '0.10.3.1',
    MutualFundFundamental: '0.10.4.1',
    OptionFundamental: '0.10.5.1',
    InternationalFundFundamental: '0.10.6.1',
    gics: '0.11.1.1',
    sic: '0.11.2.1',
    Schedules: '0.12.1',
    bondVariableFloatRates: '0.13.1',
    exchangeRate: '0.14.1',
    mutualFundAllocations: '0.15.2.1',
    mutualFundManagerInfo: '0.15.3.1',
    mutualFundPerformance: '0.15.4.1',
  };
  parentCodeViewValues: any = {
    priceData: 'Price',
    corporateActions: 'Corporate Actions/Dividends',
    'Corporate Actions/Dividends - Events':
      'Corporate Actions/Dividends - Events',
    TrancheHistory: 'Bond Factor And Cashflow',
    paydownHistory: 'Paydown History',
    tipsInformation: 'TIPS',
    milData: 'MIL Rate',
    EquityFundamental: 'Equity Fundamental',
    FixedIncome: 'Fixed Income',
    FuturesFundamental: 'Futures Fundamental',
    MutualFundFundamental: 'Mutual Fund Fundamental',
    OptionFundamental: 'Option Fundamental',
    InternationalFundFundamental: 'International Fund Fundamental',
    gics: 'Industry Classification - GICS',
    sic: 'Industry Classification - SIC',
    Schedules: 'Bond Schedules & Redemptions',
    bondVariableFloatRates: 'Bond Variable Float Rates',
    exchangeRate: 'Exchange Currency Rates',
    mutualFundAllocations: 'Mutual Fund Data - Mutual Fund Allocations',
    mutualFundManagerInfo: 'Mutual Fund Data - Mutual Fund Manager Info',
    mutualFundPerformance: 'Mutual Fund Data - Mutual Fund Performance',
  };
  templateListDisabled = true;
  fieldOrder: any = [];
  filteredSelectedData: any = [];
  fieldsPopulated = false;
  templateNamesApiFetched: boolean = false;

  ngOnInit() {
    // check query params if template name is present
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams['templateID']) {
      this.qpTemplateID = queryParams['templateID'];
    }

    this.reportService.currentFilteredGQL_Data.subscribe((data: any) => {
      this.fieldsPopulated = data.length > 0;
      if (this.fieldsPopulated && this.templateNamesApiFetched) {
        this.setCustomTemplateParam();
        this.templateListDisabled = false;
      }
    });

    this.reportService.TemplateNamesApi().subscribe((info: any) => {
      this.templateNames = [];
      if (info?.data?.length > 0) {
        this.templateNames = info.data;
      }
      this.templateNamesApiFetched = true;
      if (this.fieldsPopulated) {
        this.setCustomTemplateParam();
        this.templateListDisabled = false;
      }
    });

    this.generateFinalTable();
  }

  setCustomTemplateParam() {
    // check if this.qpTemplateName is present in the templateNames array
    if (this.qpTemplateID.length > 0) {
      const templateID = this.templateNames.find(
        (template: any) => template.QueryID === this.qpTemplateID,
      ).QueryID;
      if (templateID) {
        this.setTemplateFields(templateID);
        this.selected = templateID;
      } else {
        // Remove the queryParams['templateID'] from the url
        this.reportService.removeQuerystringParam(this.qpTemplateID);
        this.toastr.warning(
          'The selected Saved Query has a misformed name. Please go back and select a valid Query or select by data fields.',
        );
        this.setTemplateFields(this.predefinedTemplates[0]);
      }
    } else {
      this.setTemplateFields(this.predefinedTemplates[0]);
    }
  }

  generateFinalTable() {
    const fixedIncomeFields = [
      'SecuritizedBondFundamental',
      'MunicipalBondFundamental',
      'CorporateBondFundamental',
    ];

    fixedIncomeFields.forEach((fundamentalType) => {
      const SecuritizedBondFundamental =
        this.fixedIncomeService.SecuritizedBondFundamental.trim()
          .split('\n')
          .map((str: any) => str.trim());

      const MunicipalBondFundamental =
        this.fixedIncomeService.MunicipalBondFundamental.trim()
          .split('\n')
          .map((str: any) => str.trim());

      const CorporateBondFundamental =
        this.fixedIncomeService.CorporateBondFundamental.trim()
          .split('\n')
          .map((str: any) => str.trim());

      switch (fundamentalType) {
        case 'SecuritizedBondFundamental':
          SecuritizedBondFundamental.forEach((item) => {
            this.addToFinalTable(item, fundamentalType);
          });
          break;
        case 'MunicipalBondFundamental':
          MunicipalBondFundamental.forEach((item) => {
            this.addToFinalTable(item, fundamentalType);
          });
          break;
        case 'CorporateBondFundamental':
          CorporateBondFundamental.forEach((item) => {
            this.addToFinalTable(item, fundamentalType);
          });
          break;
        default:
          break;
      }
    });
  }

  addToFinalTable(key: any, value: any) {
    if (this.finalParentFieldObject[key]) {
      this.finalParentFieldObject[key].push(value);
    } else {
      this.finalParentFieldObject[key] = [value];
    }
  }

  setCustomKeysTemplateFields(obj: string[], template: any) {
    const selectedEventsObj: any = {};
    const keys = Object.keys(obj);

    keys.forEach((event: any) => {
      if (template[event] && template[event].length > 0) {
        selectedEventsObj[event] = [event];
      }
    });

    return selectedEventsObj;
  }

  setCorporateActionsBaseValues(template: any) {
    const arr = (Object as any)
      .values(template)
      .find((arr: any) => arr.includes('__typename'));
    if (arr) {
      return arr.filter(
        (value: any) =>
          CorporateActionBase.includes(value) && value != 'requestedIdentifier',
      );
    } else {
      return [];
    }
  }

  setCustomTemplateFields(data: any) {
    let resArr: any = {};
    const template = data.content;
    resArr = {
      priceData: template.priceData ? [...template.priceData] : [],
      corporateActions: template.corporateActions
        ? [...this.setCorporateActionsBaseValues(template)]
        : [],
      ...this.setCustomKeysTemplateFields(CorporateActionsEvents, template),
      ...this.setCustomKeysTemplateFields(trancheHistory, template),
      tipsInformation: template.tipsInformation
        ? [...template.tipsInformation]
        : [],
      milData: template.milData ? [...template.milData] : [],
      EquityFundamental: template.EquityFundamental
        ? [...template.EquityFundamental]
        : [],
      FixedIncome:
        template.CorporateBondFundamental ||
        template.MunicipalBondFundamental ||
        template.SecuritizedBondFundamental
          ? this.getUniqueFixedIncomeFields([
              ...(template?.CorporateBondFundamental ?? []),
              ...(template?.MunicipalBondFundamental ?? []),
              ...(template?.SecuritizedBondFundamental ?? []),
            ])
          : [],
      FuturesFundamental: template.FuturesFundamental
        ? [...template.FuturesFundamental]
        : [],
      MutualFundFundamental: template.MutualFundFundamental
        ? [...template.MutualFundFundamental]
        : [],
      OptionFundamental: template.OptionFundamental
        ? [...template.OptionFundamental]
        : [],
      InternationalFundFundamental: template.InternationalFundFundamental
        ? [...template.InternationalFundFundamental]
        : [],
      gics: template.gics ? [...template.gics] : [],
      sic: template.sic ? [...template.sic] : [],
      exchangeRate: template.exchangeRate ? [...template.exchangeRate] : [],
      ...this.setCustomKeysTemplateFields(schedulesCategories, template),
      ...this.setCustomKeysTemplateFields(mutualFundAllocations, template),
      mutualFundManagerInfo: template.mutualFundManagerInfo
        ? [template.mutualFundManagerInfo]
        : [],
      mutualFundPerformance: template.mutualFundPerformance
        ? [template.mutualFundPerformance]
        : [],
    };

    return resArr;
  }

  getUniqueFixedIncomeFields(combinedFixedIncome: any) {
    const uniqueStrings: any = [];
    for (const string of combinedFixedIncome) {
      if (!uniqueStrings.includes(string)) {
        uniqueStrings.push(string);
      }
    }
    return uniqueStrings;
  }

  onFocusInChangeImg() {
    this.imgSrc = '../../../assets/focus-search.svg';
  }
  onFocusOutChangeImg() {
    this.imgSrc = '../../../assets/default-search.svg';
  }

  toggleReportpage() {
    this.mixpanelService.onButtonClick('Cancel');
    this.reportService.removeChildRoute();
    this.reportService.changeReportState(false);
  }

  flatNodeMap = new Map<TreeItemFlatNode, TreeItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TreeItemNode, TreeItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TreeItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';
  searchString = '';
  searched_value = '';

  treeControl: FlatTreeControl<TreeItemFlatNode>;

  treeFlattener: MatTreeFlattener<TreeItemNode, TreeItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TreeItemNode, TreeItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TreeItemFlatNode>(
    true /* multiple */,
  );

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private treeStructureService: TreeStructureService,
    private reportService: QdodService,
    private mixpanelService: MixpanelService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    public customDataService: CustomDataService,
    public fixedIncomeService: FixedIncomeService,
    public municipalGsacPricingService: MunicipalGsacPricingService,
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<TreeItemFlatNode>(
      this.getLevel,
      this.isExpandable,
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener,
    );

    treeStructureService.dataChange.subscribe((data) => {
      if (this.treeStructureService.treeDataLoaded) {
        this.dataSource.data = data;
        const treeData = this.treeControl.dataNodes;
        const eventsObj = this.getCustomKeysNodes(
          CorporateActionsEvents,
          treeData,
          '0.4.2.1',
        );
        const schedulesObj = this.getCustomKeysNodes(
          schedulesCategories,
          treeData,
          '0.12.1',
        );
        const trancheObj = this.getCustomKeysNodes(
          trancheHistory,
          treeData,
          '0.6.1',
        );
        const mutualFundAllocationsData = this.getCustomKeysNodes(
          mutualFundAllocations,
          treeData,
          '0.15.2.1',
        );
        this.parentNodes = {
          priceData: treeData.filter((t: any) => t.code === '0.1.1'),
          corporateActions: treeData.filter((t: any) => t.code === '0.4.1'),
          ...eventsObj,
          ...trancheObj,
          paydownHistory: treeData.filter((t: any) => t.code === '0.6.2.1'),
          tipsInformation: treeData.filter((t: any) => t.code === '0.7.1'),
          milData: treeData.filter((t: any) => t.code === '0.8.1'),
          EquityFundamental: treeData.filter((t: any) => t.code === '0.10.1.1'),
          FixedIncome: treeData.filter((t: any) => t.code === '0.10.2.1'),
          FuturesFundamental: treeData.filter(
            (t: any) => t.code === '0.10.3.1',
          ),
          MutualFundFundamental: treeData.filter(
            (t: any) => t.code === '0.10.4.1',
          ),
          OptionFundamental: treeData.filter((t: any) => t.code === '0.10.5.1'),
          InternationalFundFundamental: treeData.filter(
            (t: any) => t.code === '0.10.6.1',
          ),
          gics: treeData.filter((t: any) => t.code === '0.11.1.1'),
          sic: treeData.filter((t: any) => t.code === '0.11.2.1'),
          ...schedulesObj,
          bondVariableFloatRates: treeData.filter(
            (t: any) => t.code === '0.13.1',
          ),
          exchangeRate: treeData.filter((t: any) => t.code === '0.14.1'),
          ...mutualFundAllocationsData,
          mutualFundManagerInfo: treeData.filter(
            (t: any) => t.code === '0.15.3.1',
          ),
          mutualFundPerformance: treeData.filter(
            (t: any) => t.code === '0.15.4.1',
          ),
        };
      }
    });
  }

  fieldFilter(event: any) {
    this.searched_value = event.target.value;
    this.mixpanelService.onInputEvent('field_filter', this.searched_value);
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  setTemplateFields(template: any) {
    if (template.key !== 'pre-defined') {
      this.reportService
        .CustomTemplateContent(template)
        .subscribe((info: any) => {
          const response = info.body;
          response.content = this.setCustomTemplateFields(response);

          this.setCustomValues(response);
        });
    } else if (template.key === 'pre-defined') {
      this.setCustomValues(template);
    }
  }

  selectionEvent(template: any) {
    if (template.key != 'pre-defined') {
      this.mixpanelService.onListSelection('load-template', template);
    } else if (template.key == 'pre-defined') {
      const selected_template = template.name;
      this.mixpanelService.onListSelection('load-template', selected_template);
    }
  }

  getCustomKeysNodes(obj: string[], nodes: any, code: string) {
    const customObj: any = {};
    const keys = Object.keys(obj);

    keys.forEach((event: any) => {
      customObj[event] = nodes.filter(
        (t: any) => t.value === event && t.code === code,
      );
      if (customObj[event].length > 1) {
        customObj[event] = [customObj[event].pop()];
      }
    });
    return customObj;
  }

  setCustomValues(templateDetails: any) {
    const fieldsToBeSelected = templateDetails.content;
    const fieldsOrder = templateDetails.fieldOrder;
    const allEventsKeys = [
      ...Object.keys(CorporateActionsEvents),
      ...Object.keys(trancheHistory),
      ...Object.keys(schedulesCategories),
      ...Object.keys(mutualFundAllocations),
    ];
    this.deselectAllFields(this.parentNodes);
    this.fieldOrder = [];
    this.filteredSelectedData = [];
    if (fieldsOrder && fieldsOrder.length > 0) {
      fieldsOrder.forEach((el: any) => {
        const parent = this.parentNodes[el.parent]
          ? this.parentNodes[el.parent]
          : this.parentNodes[el.value];
        const parentSameNode = parent.find(
          (elem: any) => elem.value === el.parent,
        );
        if (parentSameNode) {
          this.setChildNode(parent, parentSameNode.value);
        } else if (!parent && allEventsKeys.includes(el.value)) {
          this.setChildNode(this.parentNodes[el.value], el.value);
        } else {
          this.setChildNode(parent, el.value);
        }
      });
    } else {
      for (const objkey in this.parentNodes) {
        this.setFields(this.parentNodes[objkey], fieldsToBeSelected[objkey]);
      }
    }
  }

  deselectAllFields(parentNodes: any) {
    const dataArr = this.treeControl.dataNodes;
    dataArr.forEach((node: any) => {
      node.status = false;
      this.checklistSelection.deselect(node);
      const parentNode: any = this.getParentNode(node);
      this.checklistSelection.deselect(node);
      this.checklistSelection.deselect(parentNode);
    });
    for (const objkey in parentNodes) {
      this.checkAllParentsSelection(parentNodes[objkey]);
    }
    this.handleCustomData(this.checklistSelection.selected);
  }

  setChildNode(treeNode: any, fieldName: any) {
    treeNode.forEach((node: any) => {
      if (node.value === fieldName) {
        node.status = true;
        if (!this.checklistSelection.isSelected(node)) {
          this.todoLeafItemSelectionToggle(node);
        }
      }
    });
    this.checkAllParentsSelection(treeNode);
  }

  setFields(treeNode: any, customValues: any) {
    treeNode.map((el: any) => {
      el.status = false;
      const parentNode: any = this.getParentNode(el);
      this.checklistSelection.deselect(el);
      this.checklistSelection.deselect(parentNode);
      if (customValues) {
        customValues.map((customValue: any) => {
          if (el.value === customValue) {
            el.status = true;
            this.todoLeafItemSelectionToggle(el);
          }
        });
      }
    });
    this.checkAllParentsSelection(treeNode);
  }
  getLevel = (node: TreeItemFlatNode) => node.level;

  isExpandable = (node: TreeItemFlatNode) => node.expandable;

  getChildren = (node: TreeItemNode): TreeItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TreeItemFlatNode) => _nodeData.expandable;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TreeItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.value === node.value
        ? existingNode
        : new TreeItemFlatNode();
    flatNode.value = node.value;
    flatNode.viewValue = node.viewValue;
    flatNode.level = level;
    flatNode.code = node.code;
    flatNode.desc = node.desc;
    flatNode.status = node.status;
    if (node.childNodes) {
      flatNode.childNodes = node.childNodes;
    }
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);

    return flatNode;
  };

  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: TreeItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);

    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TreeItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child),
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TreeItemFlatNode, completed: boolean): void {
    node.status = !node.status;
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    if (completed) {
      descendants.forEach((t: any) => {
        t.status = true;
        this.filterSelection(t);
      });
      this.checklistSelection.select(...descendants);
    } else {
      descendants.forEach((t: any) => {
        t.status = false;
        this.filterSelection(t);
      });
      this.checklistSelection.deselect(...descendants);
    }

    // Force update for the parent
    descendants.forEach((child: any) => {
      return this.checklistSelection.isSelected(child);
    });
    this.checkAllParentsSelection(node);

    this.handleCustomData(this.checklistSelection.selected);
  }

  todoLeafItemSelectionToggle(node: TreeItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checklistSelection.isSelected(node)
      ? (node.status = true)
      : (node.status = false);
    this.checkAllParentsSelection(node);
    this.filterSelection(node);
    this.handleCustomData(this.checklistSelection.selected);
  }

  checkAllParentsSelection(node: TreeItemFlatNode): void {
    let parent: TreeItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  checkRootNodeSelection(node: TreeItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });

    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  getParentNode(node: TreeItemFlatNode): TreeItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  filterLeafNode(node: TreeItemNode): boolean {
    if (!this.searchString) {
      return false;
    }
    if (
      node.desc &&
      node.desc.toLowerCase().indexOf(this.searchString?.toLowerCase()) !== -1
    ) {
      return (
        node.desc.toLowerCase().indexOf(this.searchString?.toLowerCase()) === -1
      );
    }
    return (
      node.viewValue.toLowerCase().indexOf(this.searchString?.toLowerCase()) ===
      -1
    );
  }

  filterParentNode(node: TreeItemFlatNode): boolean {
    if (
      node.desc &&
      node.desc.toLowerCase().indexOf(this.searchString?.toLowerCase()) !== -1
    ) {
      return false;
    }

    if (
      !this.searchString ||
      node.viewValue.toLowerCase().indexOf(this.searchString?.toLowerCase()) !==
        -1
    ) {
      return false;
    }
    const descendants = this.treeControl.getDescendants(node);

    if (
      descendants.some(
        (descendantNode) =>
          descendantNode.desc &&
          descendantNode.desc
            .toLowerCase()
            .indexOf(this.searchString?.toLowerCase()) !== -1,
      )
    ) {
      this.treeControl.expand(node);
      return false;
    }
    if (
      descendants.some(
        (descendantNode) =>
          descendantNode.viewValue
            .toLowerCase()
            .indexOf(this.searchString?.toLowerCase()) !== -1,
      )
    ) {
      this.treeControl.expand(node);
      return false;
    }

    return true;
  }

  setCustomKeysObj(obj: any, selectedList: any, code: string) {
    const keys = Object.keys(obj);
    const customObj: any = {};
    keys.forEach((event: any) => {
      customObj[event] = selectedList
        .filter((t: any) => t.value === event && t.code === code)
        .map(({ value }: any) => value);
      if (customObj[event].length > 1) {
        customObj[event] = [event];
      }
    });
    return customObj;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.fieldOrder = this.reorderFieldOrder(
      [...this.filteredSelectedData],
      [...this.fieldOrder],
    );
    const selectedFields = {
      flatMap: this.filteredSelectedData,
      nestedMap: this.fieldOrder,
    };
    this.reportService.changeFields_selected(selectedFields);
  }

  reorderFieldOrder(filteredSelectedData: any, fieldOrder: any) {
    let result = filteredSelectedData.flatMap((selectedData: any) => {
      if (
        fieldOrder.some((field: any) => field.parent === selectedData.value)
      ) {
        return fieldOrder.filter(
          (field: any) => field.parent === selectedData.value,
        );
      } else {
        return selectedData;
      }
    });
    // Remove duplicates
    result = result.filter(
      (v: any, i: any, a: any) =>
        a.findIndex(
          (t: any) => t.parent === v.parent && t.value === v.value,
        ) === i,
    );
    return result;
  }

  getNestedChildValues(item: TreeItemFlatNode) {
    return item.childNodes?.map((el) => ({
      parent: item.value,
      value: el,
      viewValue: el,
    }));
  }

  isNodePresent(node: any, parent: any) {
    return this.fieldOrder.some(
      (item: any) => item.parent === parent && item.value === node.value,
    );
  }

  filterSelection(node: any) {
    const parent = Object.keys(this.parentCodeValues).find(
      (key) => this.parentCodeValues[key] === node.code,
    );
    if (!parent) return;

    const alreadyExist = this.fieldOrder.some(
      (el: any) => el.parent === node.value,
    );

    if (node.status && !this.isNodePresent(node, parent) && !alreadyExist) {
      this.pushNodeToFieldOrder(node, parent);
      this.filteredSelectedData.push({
        parent,
        value: node.value,
        viewValue: node.viewValue,
      });
    } else if (!node.status) {
      this.removeNodeFromFieldOrder(node, parent);
    }
    const selectedFields = {
      flatMap: this.filteredSelectedData,
      nestedMap: this.fieldOrder,
    };
    this.reportService.changeFields_selected(selectedFields);
  }

  pushNodeToFieldOrder(node: any, parent: any) {
    if (node?.childNodes) {
      const childValues = this.getNestedChildValues(node);
      if (childValues) {
        this.fieldOrder = [...this.fieldOrder, ...childValues];
      }
    } else {
      this.fieldOrder.push({
        parent,
        value: node.value,
        viewValue: node.viewValue,
      });
    }
  }

  removeNodeFromFieldOrder(node: any, parent: any) {
    if (node?.childNodes) {
      this.fieldOrder = this.fieldOrder.filter(
        (item: any) => item.parent !== node.value,
      );
      this.filteredSelectedData = this.filteredSelectedData.filter(
        (item: any) => item.value !== node.value,
      );
    } else {
      this.fieldOrder = this.fieldOrder.filter((fieldOrderItem: any) => {
        return !(
          fieldOrderItem.parent === parent &&
          fieldOrderItem.value === node.value
        );
      });
      this.filteredSelectedData = this.filteredSelectedData.filter(
        (fieldOrderItem: any) => {
          return !(
            fieldOrderItem.parent === parent &&
            fieldOrderItem.value === node.value
          );
        },
      );
    }
  }

  handleCustomData(selectedList: any) {
    const priceData = selectedList
      .filter((t: any) => t.code === '0.1.1')
      .map(({ value }: any) => value);
    const filteredPricingFields =
      this.municipalGsacPricingService.filterFields(priceData);

    const newCustomObj = {
      priceData: filteredPricingFields.pricingFields,
      municipalPricing: filteredPricingFields.municipalFieldsSelected,
      gsacPricing: filteredPricingFields.gsacFieldsSelected,
      corporateActions: selectedList
        .filter((t: any) => t.code === '0.4.1')
        .map(({ value }: any) => value),
      ...this.setCustomKeysObj(CorporateActionsEvents, selectedList, '0.4.2.1'),
      ...this.setCustomKeysObj(trancheHistory, selectedList, '0.6.1'),
      paydownHistory: selectedList
        .filter((t: any) => t.code === '0.6.2.1')
        .map(({ value }: any) => value),
      tipsInformation: selectedList
        .filter((t: any) => t.code === '0.7.1')
        .map(({ value }: any) => value),
      milData: selectedList
        .filter((t: any) => t.code === '0.8.1')
        .map(({ value }: any) => value),
      //cashflow schedule template rearranged
      EquityFundamental: selectedList
        .filter((t: any) => t.code === '0.10.1.1')
        .map(({ value }: any) => value),
      FixedIncome: selectedList
        .filter((t: any) => t.code === '0.10.2.1')
        .map(({ value }: any) => value),
      FuturesFundamental: selectedList
        .filter((t: any) => t.code === '0.10.3.1')
        .map(({ value }: any) => value),
      MutualFundFundamental: selectedList
        .filter((t: any) => t.code === '0.10.4.1')
        .map(({ value }: any) => value),
      OptionFundamental: selectedList
        .filter((t: any) => t.code === '0.10.5.1')
        .map(({ value }: any) => value),
      InternationalFundFundamental: selectedList
        .filter((t: any) => t.code === '0.10.6.1')
        .map(({ value }: any) => value),
      gics: selectedList
        .filter((t: any) => t.code === '0.11.1.1')
        .map(({ value }: any) => value),
      sic: selectedList
        .filter((t: any) => t.code === '0.11.2.1')
        .map(({ value }: any) => value),
      ...this.setCustomKeysObj(schedulesCategories, selectedList, '0.12.1'),
      bondVariableFloatRates: selectedList
        .filter((t: any) => t.code === '0.13.1')
        .map(({ value }: any) => value),
      exchangeRate: selectedList
        .filter((t: any) => t.code === '0.14.1')
        .map(({ value }: any) => value),
      ...this.setCustomKeysObj(mutualFundAllocations, selectedList, '0.15.2.1'),
      mutualFundManagerInfo: selectedList
        .filter((t: any) => t.code === '0.15.3.1')
        .map(({ value }: any) => value),
      mutualFundPerformance: selectedList
        .filter((t: any) => t.code === '0.15.4.1')
        .map(({ value }: any) => value),
    };

    const updatedObject = this.setValueToObservable(newCustomObj);
    this.reportService.changeTotalGroups(updatedObject);
  }

  setValueToObservable(obj: any) {
    const updatedCustomObj = { ...obj };

    // Remove the FixedIncome property
    delete updatedCustomObj.FixedIncome;

    if (obj.FixedIncome) {
      // Update the values of the SecuritizedBondFundamental, MunicipalBondFundamental, and CorporateBondFundamental properties
      updatedCustomObj.SecuritizedBondFundamental = obj.FixedIncome.filter(
        (item: string) =>
          this.finalParentFieldObject[item] &&
          this.finalParentFieldObject[item].includes(
            'SecuritizedBondFundamental',
          ),
      );
      updatedCustomObj.MunicipalBondFundamental = obj.FixedIncome.filter(
        (item: string) =>
          this.finalParentFieldObject[item] &&
          this.finalParentFieldObject[item].includes(
            'MunicipalBondFundamental',
          ),
      );
      updatedCustomObj.CorporateBondFundamental = obj.FixedIncome.filter(
        (item: string) =>
          this.finalParentFieldObject[item] &&
          this.finalParentFieldObject[item].includes(
            'CorporateBondFundamental',
          ),
      );
    }

    return updatedCustomObj;
  }

  ngOnDestroy(): void {
    this.templateListDisabled = true;
  }
}
